import React, { useState } from "react";

import "../styles/Tslogin.css";
import LoginButton from "../components/LoginBtn";
import { useNavigate } from "react-router-dom";
import Metamask from "../assets/svg/Metamask.svg";
import Phantom from "../assets/svg/phantom_logo.svg";
import Backpack from "../assets/svg/backpack.svg";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
} from "@headlessui/react";
import { useAppSelector, useAppDispatch } from "../state/hooks";
import { injected, metaMask } from "wagmi/connectors";
import { toggleLoginOrSignupDialog } from "../state/dialog";
import { useConnect, useAccount } from "wagmi";
import { signInWithWallet } from "../api/signIn/route";
import { signUpWithWallet } from "../api/signUp/route";
import { checkUserRecord } from "../api/users/routes";
import { walletType } from "../api/types";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { IoMdClose } from "react-icons/io";
import { createThirdwebClient } from "thirdweb";
import { createWallet, injectedProvider } from "thirdweb/wallets";

const Tslogin: React.FC = () => {
  const { connect } = useConnect();
  const account = useAccount();
  const queryClient = useQueryClient();
  const client = createThirdwebClient({
    clientId: "755026d5ac44074afd5557d8cd2f8d83",
  });
  const wallet = createWallet("app.backpack");

  const isOpen = useAppSelector(
    (state) => state.dialogState.loginOrSignupDialog
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const checkMetamaskIsInstalled = () => {
    return typeof window.ethereum !== "undefined";
  };

  const connectMetamask = async () => {
  
    if (!checkMetamaskIsInstalled()) {
      alert("Please install MetaMask");
      return;
    }

    try {
      //refresh current route
       
      // Ensure `request` is defined
      dispatch(toggleLoginOrSignupDialog(false));
      connect(
        { connector: metaMask() },
        {
          onSuccess: async (data) => {
            try {
              if (data?.accounts[0]) {
                const userRecord = await checkUserRecord({
                  accountAddress: data?.accounts[0],
                  walletType: walletType.Metamask,
                });
                if (userRecord.statusCode == 201 && !userRecord.data) {
                  const res = await signUpWithWallet({
                    accountAddress: data?.accounts[0],
                    walletType: walletType.Metamask,
                  });

                  localStorage.setItem("token", res.data.token);
                  //navigate("/Dashboard");
                  dispatch(toggleLoginOrSignupDialog(false));
                  queryClient.invalidateQueries({
                    queryKey: ["getAllProject"],
                  });
                  queryClient.invalidateQueries({
                     queryKey: ["fetchUserDetails",data?.accounts[0]],
                  });
                }
                if (userRecord.statusCode == 201 && userRecord.data) {
                  const res = await signInWithWallet({
                    accountAddress: data?.accounts[0],
                    walletType: walletType.Metamask,
                  });
                 
                  localStorage.setItem("token", res.data.token);
                  //navigate("/Dashboard");
                  
                  dispatch(toggleLoginOrSignupDialog(false));
                  queryClient.invalidateQueries({
                    queryKey: ["getAllProject"],
                  });
                  queryClient.invalidateQueries({
                     queryKey: ["fetchUserDetails",data?.accounts[0]],
                  });
                }
              }
            } catch (error) {
              console.error(error);
            }
          },
        }
      );
    } catch (e) {
      console.error("Failed to connect wallet", e);
    }
  };

  // async function ConnectBackPack() {
  //   try {
  //     if (injectedProvider("app.backpack")) {
  //       connect(
  //         { connector: injected() },
  //         {
  //           onSuccess: async (data) => {
  //             try {
  //               if (data?.accounts[0]) {
  //                 const userRecord = await checkUserRecord({
  //                   accountAddress: data?.accounts[0],
  //                   walletType: walletType.BackPack,
  //                 });
  //                 if (userRecord.statusCode == 201 && !userRecord.data) {
  //                   const res = await signUpWithWallet({
  //                     accountAddress: data?.accounts[0],
  //                     walletType: walletType.BackPack,
  //                   });

  //                   localStorage.setItem("token", res.data.token);
  //                   navigate("/Dashboard");
  //                   dispatch(toggleLoginOrSignupDialog(false));
  //                   queryClient.invalidateQueries({
  //                     queryKey: ["getAllProject"],
  //                   });
  //                 }
  //                 if (userRecord.statusCode == 201 && userRecord.data) {
  //                   const res = await signInWithWallet({
  //                     accountAddress: data?.accounts[0],
  //                     walletType: walletType.BackPack,
  //                   });
  //                   localStorage.setItem("token", res.data.token);
  //                   navigate("/Dashboard");
  //                   dispatch(toggleLoginOrSignupDialog(false));
  //                   queryClient.invalidateQueries({
  //                     queryKey: ["getAllProject"],
  //                   });
  //                 }
  //               }
  //             } catch (error) {
  //               console.error(error);
  //             }
  //           },
  //         }
  //       );
  //     } else {
  //       alert("Backpack wallet not detected");
  //       console.log(
  //         "Backpack wallet not detected. Redirecting to Backpack website..."
  //       );
  //       window.open("https://backpack.app/", "_blank");
  //     }
  //   } catch (error) {
  //     console.error("Failed to connect to Backpack wallet:", error);
  //   }
  // }

  // const getPhantomProvider = (): any | undefined => {
  //   if ("phantom" in window) {
  //     console.log("Phantom wallet detected");
  //     const anyWindow: any = window;
  //     const provider = anyWindow.phantom;

  //     if (provider) {
  //       console.log(provider);
  //       navigate("/Dashboard"); // Move navigation here if provider is available
  //       return provider;
  //     }
  //   } else {
  //     alert("Please install Phantom wallet");
  //     window.open("https://phantom.app/", "_blank");
  //   }
  // };

  async function ConnectBackPack() {
    try {
      if (injectedProvider("app.backpack")) {
        console.log("Backpack wallet detected, attempting to connect...");
        const connection = await wallet.connect({ client });
        console.log("Connected to Backpack wallet successfully!");
        //setPubAddress(connection.address);
        sessionStorage.setItem("pubAddress", connection.address);
       
        //alert(`Connected account: ${connection.address}`);
        //setConnectedWallet("backpack");
        const userRecord = await checkUserRecord({
          accountAddress: connection.address,
          walletType: walletType.BackPack,
        });

        if (userRecord.statusCode == 201 && !userRecord.data) {
          const res = await signUpWithWallet({
            accountAddress: connection.address,
            walletType: walletType.BackPack,
          });
         
          localStorage.setItem("token", res.data.token);
          //navigate("/Dashboard");
          dispatch(toggleLoginOrSignupDialog(false));
        }
        if (userRecord.statusCode == 201 && userRecord.data) {
          const res = await signInWithWallet({
            accountAddress: connection.address,
            walletType: walletType.BackPack,
          });
          localStorage.setItem("token", res.data.token);
          //navigate("/Dashboard");
          dispatch(toggleLoginOrSignupDialog(false));
        }
      } else {
        alert("Backpack wallet not detected");
        console.log(
          "Backpack wallet not detected. Redirecting to Backpack website..."
        );
        window.open("https://backpack.app/", "_blank");
        // console.log(
        //   "Backpack wallet not detected. Displaying WalletConnect QR modal..."
        // );
        // await wallet.connect({
        //   client,
        //   walletConnect: { showQrModal: true },
        // });
        // console.log("Connected to Backpack wallet using WalletConnect!");
      }
    } catch (error) {
      console.error("Failed to connect to Backpack wallet:", error);
    }
  }
  const getPhantomProvider = (): any | undefined => {
    if ("phantom" in window) {
      console.log("Phantom wallet detected");
      const anyWindow: any = window;
      const provider = anyWindow.phantom?.solana; // Updated to access Phantom's Solana provider
      if (provider?.isPhantom) {
        return provider;
      }
    } else {
      alert("Please install Phantom wallet");
      window.open("https://phantom.app/", "_blank");
    }
  };

  const connectPhantomWallet = async () => {
    const provider = getPhantomProvider();
    if (!provider) {
      return;
    }
    try {
      // Use Phantom-specific connection method
      dispatch(toggleLoginOrSignupDialog(false));
      connect(
        { connector: injected({
          target:"phantom"
        }) },
        {
          onSuccess: async (data) => {
            try {
              
              if (data?.accounts[0]) {
                const userRecord = await checkUserRecord({
                  accountAddress: data?.accounts[0],
                  walletType: walletType.Phantom,
                });

                if (userRecord.statusCode == 201 && !userRecord.data) {
                  const res = await signUpWithWallet({
                    accountAddress: data?.accounts[0],
                    walletType: walletType.Phantom,
                  });
                 
                  localStorage.setItem("token", res.data.token);
                  navigate("/");
                  dispatch(toggleLoginOrSignupDialog(false));
                }
                if (userRecord.statusCode == 201 && userRecord.data) {
                  const res = await signInWithWallet({
                    accountAddress: data?.accounts[0],
                    walletType: walletType.Phantom,
                  });
                  localStorage.setItem("token", res.data.token);
                  navigate("/");
                  dispatch(toggleLoginOrSignupDialog(false));
                }
              }
            } catch (error) {
              console.error(error);
            }
          },
        }
      ); // Display connected address
    } catch (error) {
      console.error("Failed to connect to Phantom wallet", error);
      alert("Failed to connect to Phantom wallet. Please try again.");
    }
  };

  function close() {
    dispatch(toggleLoginOrSignupDialog(false));
  }

  return (
    <>
      <Dialog
        open={isOpen}
        as="div"
        className="relative w-full z-max focus:outline-none"
        onClose={close}
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <DialogBackdrop className="fixed inset-0 w-full bg-black/50" />

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4">
            <DialogPanel
              transition
              className="px-8 rounded-2xl bg-[#222222] border border-white/40 w-[557px] h-[414px] p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
            >
              <DialogTitle
                as="div"
                className="font-medium flex flex-col justify-between text-white text-wrap text-center text-[20px] my-4"
              >
                <div className="flex justify-end w-full">
                  <IoMdClose onClick={close} size={24} />
                </div>
                <p className="text-[23px] mt-4 font-medium text-white">
                  Connect wallet to continue
                </p>
              </DialogTitle>
              <div className="flex flex-col border rounded-lg border-white/40">
                <LoginButton
                  showBoost={false}
                  title="Phantom"
                  logo={Phantom}
                  onClick={connectPhantomWallet}
                  boost={40}
                  recommend
                />
                <div className="h-px my-1 bg-white/40" />

                <LoginButton
                  title="Backpack"
                  showBoost={false}
                  boost={30}
                  logo={Backpack}
                  onClick={ConnectBackPack}
                />
                <div className="h-px my-1 bg-white/40" />
                <LoginButton
                  title="MetaMask"
                  logo={Metamask}
                  onClick={() => {
                    connectMetamask();
                  }}
                />
              </div>
              {/* <div className="mt-8">
                <div className="flex items-center justify-center gap-1">
                  <p className="text-sm font-medium text-white">
                    By continuing, you agree to{" "}
                  </p>
                  <a className="text-sm font-medium text-primary-default">
                    Privacy Policy.
                  </a>
                </div>
              </div> */}
              <div className="flex items-center justify-center mt-5 ">
                <p className="text-sm font-medium text-[#AAA]">Powered by </p>
                <img
                  src="/assets/ts_logo.svg"
                  alt="Townesquare Logo"
                  className="h-[17px] w-[120px]"
                />
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Tslogin;
