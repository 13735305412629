import React, { useState } from "react";
import { PiDotsThreeOutlineFill, PiCopyLight } from "react-icons/pi";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import {
  toggleActiveOnBoarding,
  toggleArchiveOnBoarding,
} from "../api/project/route";
import { useQueryClient } from "@tanstack/react-query";
import { useAppDispatch } from "../state/hooks";
import {
  updateIsEditingOnboardingApp,
  updateOnboardingId,
} from "../state/onboarding/onboarding";
import { OnBoardingType } from "../state/types";
import { capitalize } from "lodash";
import { getCreatedDate, SessionKeys } from "../utils/helper";
import { handleFetchOnboardingAppDetails } from "../state/action";

const ArchiveCard = ({
  name,
  isActive,
  title,
  sourceAsset,
  onBoardingLink,
  onBoardingId,
  projectId,
  isArchived,
  onboardingType,
  destAsset,
  dest_chain,
  source_chain,
  assets,
  createdTime,
  isProjectOwner,
}: {
  name: string;
  isActive: boolean;
  title: string | null;
  sourceAsset: string | null;
  onBoardingLink: string | null;
  source_chain: string | null;
  dest_chain: string | null;
  onBoardingId: number;
  projectId: number;
  isArchived: boolean;
  onboardingType: string;
  destAsset: string | null;
  assets: {
    source_asset_icon: string;
    dest_asset_icon: string;
    source_asset_symbol: string;
    dest_asset_symbol: string;
  } | null;
  createdTime: string;
  isProjectOwner?: boolean;
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const [linkIsCopied, setLinkIsCopied] = useState(false);
  const mutation = useMutation({
    mutationFn: async () => {
      return await toggleActiveOnBoarding(String(onBoardingId));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getAllOnboardingFromProjectId", projectId],
      });
      queryClient.invalidateQueries({
        queryKey: ["fetchSingleOnboardingApp", onBoardingId],
      });
    },
  });

  const archive = useMutation({
    mutationFn: async () => {
      return await toggleArchiveOnBoarding(String(onBoardingId));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getAllOnboardingFromProjectId", projectId],
      });
      queryClient.invalidateQueries({
        queryKey: ["fetchSingleOnboardingApp", onBoardingId],
      });
    },
  });

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${onBoardingLink}`);
    setLinkIsCopied(true);
    setTimeout(() => {
      setLinkIsCopied(false);
    }, 2000);
  };

  const handleOnEdit = () => {
    dispatch(updateOnboardingId(onBoardingId));
    dispatch(
      handleFetchOnboardingAppDetails({
        onBoardingId: onBoardingId,
      })
    );
    dispatch(updateIsEditingOnboardingApp(true));
    sessionStorage.setItem(
      SessionKeys.isEditingOnboardingApp,
      JSON.stringify(true)
    );
    sessionStorage.setItem(SessionKeys.onBoardingAppId, String(onBoardingId));
    navigate("/CreateOnboardingApp");
  };

  return (
    <div className="py-[32px] px-[24px] rounded-2xl border border-white/40 bg-dark-gray-2 w-[290px] h-[412px] ">
      <div className="flex items-center justify-between w-full">
        <p className="text-[15px] font-medium">
          {capitalize(onboardingType)} app
        </p>
        <div className="flex items-center justify-between gap-4 ">
          {isActive ? (
            <button className="text-sm flex justify-center text-white font-semibold items-center border-2 bg-[#2AB576]/20 border-[#2AB576] h-[28px] w-[70px] outline-none rounded-[38px]">
              Active
            </button>
          ) : (
            <button className="text-sm flex justify-center text-white items-center border font-semibold bg-white/10 border-gray-light-1 h-[28px] w-[75px] outline-none rounded-[38px]">
              Unactive
            </button>
          )}
          {/* <button className="h-[32px] w-[32px] border flex justify-center items-center border-gray-light-3 outline-none">
            <PiDotsThreeOutlineFill color="#FFF" />
          </button> */}
        </div>
      </div>
      <div className="flex flex-col mt-3">
        <h2 className="text-[23px] font-medium text-white">{name}</h2>
        <p className="text-[13px] text-[#AAA] ">
          Created on {getCreatedDate(createdTime)}
        </p>
      </div>
      {onboardingType == OnBoardingType.STAKE && (
        <div className="flex flex-col gap-2 my-[32px]">
          <div className="flex items-center gap-2">
            <span className="text-gray-light-1 text-[15px] font-medium">
              Send
            </span>
            <span className="text-[15px] font-medium flex items-center gap-1 text-white uppercase">
              <img
                className="w-[16px] h-[16px] rounded-full"
                src={assets?.source_asset_icon}
              />{" "}
              {assets?.source_asset_symbol}
              <span className="text-[#CCCCCC] capitalize">
                ({source_chain ? source_chain : ""})
              </span>
            </span>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-gray-light-1 text-[15px] font-medium">
              Stake
            </span>
            <span className="text-[15px] flex items-center gap-1 font-medium text-white">
              <img
                className="w-[16px] h-[16px] rounded-full"
                src={assets?.dest_asset_icon}
              />
              {assets?.dest_asset_symbol}
              <span className="text-[#CCCCCC]">
                (
                {dest_chain
                  ? dest_chain.charAt(0).toUpperCase() + dest_chain.slice(1)
                  : ""}
                )
              </span>
            </span>
          </div>
          <div className="flex items-center">
            <span className="text-gray-light-1 text-[15px] font-medium">
              Reference APR
            </span>
            <span className="ml-2 text-white text-[15px] font-medium">10%</span>
          </div>
        </div>
      )}
      {onboardingType == OnBoardingType.SWAP && (
        <div className="flex flex-col gap-2 my-[32px]">
          <div className="flex items-center gap-2">
            <span className="text-gray-light-1 text-[15px] font-medium">
              From
            </span>
            <span className="text-[15px] font-medium flex items-center gap-1 text-white uppercase">
              <img
                className="w-[16px] h-[16px] rounded-full"
                src={assets?.source_asset_icon}
              />{" "}
              {assets?.source_asset_symbol}
              <span className="text-[#CCCCCC] capitalize">
                ({source_chain ? source_chain : ""})
              </span>
            </span>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-gray-light-1 text-[15px] font-medium">
              To
            </span>
            <span className="text-[15px] flex items-center gap-1 font-medium text-white">
              <img
                className="w-[16px] h-[16px] rounded-full"
                src={assets?.dest_asset_icon}
              />
              {assets?.dest_asset_symbol}
              <span className="text-[#CCCCCC]">
                (
                {dest_chain
                  ? dest_chain.charAt(0).toUpperCase() + dest_chain.slice(1)
                  : ""}
                )
              </span>
            </span>
          </div>
        </div>
      )}

      {/* Onboarding link */}
      <div className="relative flex flex-col gap-2">
        <p className="text-[15px] text-white">Onboarding link</p>
        <div className="flex gap-x-2 bg-gray-dark-3 justify-between items-center px-4 h-[44px] w-[242px] border border-gray-light-1 rounded-[40px]">
          <p className="text-[13px] whitespace-nowrap overflow-hidden text-ellipsis text-white">
            {onBoardingLink}
          </p>
          <button
            onClick={() => {
              handleCopyLink();
            }}
            className="outline-none "
          >
            <PiCopyLight size={20} color="#B882FF" />
          </button>
        </div>
        {linkIsCopied && (
          <div className="bg-gray-dark-3 absolute right-0 bottom-10 flex justify-center items-center rounded w-[66px] h-[32px] border border-gray-light-3">
            <p className="text-[13px]">Copied</p>
          </div>
        )}
      </div>

      <div className="flex justify-center mt-[16px]">
        <button
          onClick={() => {
            archive.mutate();
          }}
          className="w-[150px] h-[40px] bg-primary-default hover:bg-primary-hover active:bg-primary-active rounded-[32px] font-medium text-[15px] text-white"
        >
          Unarchive app
        </button>
      </div>
    </div>
  );
};

export default ArchiveCard;
