import React from "react";
import { useAppSelector } from "../state/hooks";
import {
  getValidObjects,
  isBridgeResponse,
  isBridgeSourceAsset,
  isSwapResponse,
  isSwapSourceAsset,
} from "../utils/helper";
import MonadLogo from "../assets/svg/Monad.svg";
import { HiArrowSmallRight } from "react-icons/hi2";

const AbstractionFlowEdit = () => {
  const abstractionFlow = useAppSelector(
    (state) => state.onBoardingState.abstractionFlow
  );

  const {
    onBoardingRecord,
    onboardingId,
    isEditingOnboardingApp,
    onBoardingAppDetails,
  } = useAppSelector((state) => ({
    onBoardingRecord: state.onBoardingState.onBoardingRecord,
    onboardingId: state.onBoardingState.onBoardingId,
    isEditingOnboardingApp: state.onBoardingState.isEditingOnboardingApp,
    onBoardingAppDetails: state.appState.onBoardingAppDetails,
  }));

  if (!abstractionFlow) {
    console.log("Data is still loading or not available.");
    return (
      <div className="flex items-center justify-center flex-grow">
        <div className="flex flex-col items-center justify-center">
          <img
            className="rounded-full animate-spin animate-ease-in"
            src="/assets/loading.svg"
          />
          <p className="text-[14px]">Loading data...</p>
        </div>
      </div>
    );
  }

  const { swap, stake, bridge } =
    abstractionFlow?.data.transaction_layout.layout || {};
  if (!swap && !stake && !bridge) {
    console.warn(
      "Swap, stake, and bridge data are all missing from API response."
    );
    return <p>Data not loaded properly.</p>;
  }

  const transactionAbstraction: {
    action: string;
    abstractionFlow: {
      from: {
        name: string;
        asset: string;
        blockchain: string;
        assetLogo: string;
      };
      to: {
        asset: string;
        name: string;
        blockchain: string;
        assetLogo: string;
      };
    }[];
  }[] = [];

  const validAbstraction = getValidObjects(
    abstractionFlow?.data.transaction_layout.layout
  );

  validAbstraction.forEach(([key, value]) => {
    transactionAbstraction.push({
      action: key,
      abstractionFlow: value,
    });
  });



  


  const lastIndex = transactionAbstraction.length - 1;

  const getLogo = (logoUrl: string | null) => (logoUrl ? logoUrl : MonadLogo);

  const capitalizeFirstLetter = (string?: string) => {
    if (!string) {
      return "";
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div>
      <div className="flex justify-center mt-10">
        <div className="w-[700px] h-fit border bg-gray-light-3/20 border-[#666666] rounded-xl p-6 flex flex-col gap-4">
          <div className="flex justify-between">
            <p className="text-xl font-semibold text-white">Abstraction Flow</p>
            {/* <button className="text-base font-semibold outline-none text-primary-light">
            Edit
          </button> */}
          </div>

          {/* User Sends */}

          <div className="flex mt-[24px] items-center">
            <div className="flex gap-x-2.5 w-[130px] items-center">
              <p className="w-[32px] h-[32px] flex justify-center text-base items-center rounded-full bg-[#404040]">
                1
              </p>
              <p className="text-base text-gray-light-1">User Send</p>
            </div>

            <div className="flex">
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-1">
                  <img
                    src={getLogo(
                      transactionAbstraction[0].abstractionFlow[0].from?.assetLogo
                    )}
                    alt="Asset Logo"
                    className="w-[24px] h-[24px] rounded-full"
                  />
                  <p className="text-base text-white font-base">
                    {transactionAbstraction[0]?.abstractionFlow[0]?.from?.name}
                    <span className="text-[#818181]">
                      (
                      {capitalizeFirstLetter(
                        transactionAbstraction[0]?.abstractionFlow[0]?.from
                          ?.blockchain
                      )}
                      )
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {transactionAbstraction.map((txn, index) => (
            <div className="">
              {txn.action == "swap" && (
                <div className="flex flex-col gap-4">
                  <div className="flex items-center">
                    <div className="flex gap-x-2.5 w-[140px] mr-4 items-center">
                      <p className="w-[32px] h-[32px] flex justify-center text-base items-center rounded-full flex-shrink-0 bg-[#404040]">
                        {index + 2}
                      </p>
                      <p className="flex text-base item center text-gray-light-1">
                        Swap
                      </p>
                    </div>
                    <div className="flex flex-col w-full gap-4">
                      {txn.abstractionFlow.map((_txn) => (
                        <div className="flex items-center justify-between flex-grow ">
                          <div className="flex items-center gap-1">
                            <img
                              src={getLogo(_txn?.from?.assetLogo)}
                              alt="From Logo"
                              className="w-[24px] h-[24px] rounded-full"
                            />
                            <p className="text-base text-white font-base">
                              {_txn?.from?.name || ""}{" "}
                              <span className="text-[#818181]">
                                (
                                {capitalizeFirstLetter(
                                  _txn?.from?.blockchain || ""
                                )}
                                )
                              </span>
                            </p>
                          </div>
                          <HiArrowSmallRight className="text-[#AEAFBC]" />
                          <div className="flex items-center gap-1">
                            <img
                              src={getLogo(_txn?.to?.assetLogo)}
                              alt="To Logo"
                              className="w-[24px] h-[24px] rounded-full"
                            />
                            <p className="text-base text-white font-base">
                              {_txn?.to?.name || ""}{" "}
                              <span className="text-[#818181]">
                                (
                                {capitalizeFirstLetter(
                                  _txn?.to?.blockchain || ""
                                )}
                                )
                              </span>
                            </p>
                          </div>
                          {/* <HiArrowSmallRight className="text-[#AEAFBC]" />
                    <div className="">
                      <Listbox
                        value={selectedSwapProtocol}
                        onChange={setSelectedSwapProtocol}
                      >
                        <ListboxButton className=" px-1 rounded-[40px] flex items-center justify-center gap-2 h-[32px] bg-white text-black w-[140px] font-semibold">
                          {selectedSwapProtocol?.name}
                          <FaCaretDown />
                        </ListboxButton>
                        <ListboxOptions
                          className="w-[var(--button-width)] bg-black text-white px-2 py-2 rounded-md border border-gray-dark-1"
                          anchor="selection"
                        >
                          {txn.abstractionFlow.protocols.map((protocol:Protocol, index:any) => (
                            <ListboxOption
                              key={index}
                              value={protocol}
                              className="data-[focus]:bg-primary-default"
                            >
                              {protocol.name}
                            </ListboxOption>
                          ))}
                        </ListboxOptions>
                      </Listbox>
                    </div> */}
                        </div>
                      ))}
                    </div>
                  </div>
                  {index == lastIndex && (
                    <div className="flex ">
                      {/* <div className="flex gap-x-2.5 w-[140px] items-center">
                        <p className="w-[32px] h-[32px] flex justify-center text-base items-center rounded-full flex-shrink-0 bg-[#404040]">
                          {index + 3}
                        </p>
                        <p className="text-base text-gray-light-1">User get</p>
                      </div> */}
                      <div className="flex gap-x-2.5 w-[130px] items-center">
                        <p className="w-[32px] h-[32px] flex justify-center text-base items-center rounded-full flex-shrink-0 bg-[#404040]">
                          {index + 3}
                        </p>
                        <p className="text-base text-gray-light-1">User get</p>
                      </div>
                      <div className="flex items-center gap-1">
                        <img
                          src={getLogo(
                            txn?.abstractionFlow[txn.abstractionFlow.length - 1]
                              ?.to.assetLogo
                          )}
                          alt="Destination Asset Logo"
                          className="w-[24px] h-[24px] rounded-full"
                        />
                        <p className="text-base font-semibold text-white">
                          {
                            txn?.abstractionFlow[txn.abstractionFlow.length - 1]
                              ?.to?.name
                          }{" "}
                          <span className="text-[#818181]">
                            (
                            {capitalizeFirstLetter(
                              txn?.abstractionFlow[
                                txn.abstractionFlow.length - 1
                              ]?.to?.blockchain
                            )}
                            )
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {txn.action == "bridge" && (
                <div className="flex flex-col gap-4">
                  <div className="flex items-center">
                    <div className="flex gap-x-2.5 w-[140px] mr-4 items-center">
                      <p className="w-[32px] h-[32px] flex justify-center text-base items-center rounded-full flex-shrink-0 bg-[#404040]">
                        {index + 2}
                      </p>
                      <p className="flex text-base item center text-gray-light-1">
                        Bridge
                      </p>
                    </div>
                    <div className="flex flex-col w-full gap-4">
                      {txn.abstractionFlow.map((_txn) => (
                        <div className="flex items-center justify-between flex-grow ">
                          <div className="flex items-center gap-1">
                            <img
                              src={getLogo(_txn?.from?.assetLogo)}
                              alt="From Logo"
                              className="w-[24px] h-[24px] rounded-full"
                            />
                            <p className="text-base text-white font-base">
                              {_txn?.from?.name || ""}{" "}
                              <span className="text-[#818181]">
                                (
                                {capitalizeFirstLetter(
                                  _txn?.from?.blockchain || ""
                                )}
                                )
                              </span>
                            </p>
                          </div>
                          <HiArrowSmallRight className="text-[#AEAFBC]" />
                          <div className="flex items-center gap-1">
                            <img
                              src={getLogo(_txn?.to?.assetLogo)}
                              alt="To Logo"
                              className="w-[24px] h-[24px] rounded-full"
                            />
                            <p className="text-base text-white font-base">
                              {_txn?.to?.name || ""}{" "}
                              <span className="text-[#818181]">
                                (
                                {capitalizeFirstLetter(
                                  _txn?.to?.blockchain || ""
                                )}
                                )
                              </span>
                            </p>
                          </div>
                          {/* <HiArrowSmallRight className="text-[#AEAFBC]" />
                    <div className="">
                      <Listbox
                        value={selectedSwapProtocol}
                        onChange={setSelectedSwapProtocol}
                      >
                        <ListboxButton className=" px-1 rounded-[40px] flex items-center justify-center gap-2 h-[32px] bg-white text-black w-[140px] font-semibold">
                          {selectedSwapProtocol?.name}
                          <FaCaretDown />
                        </ListboxButton>
                        <ListboxOptions
                          className="w-[var(--button-width)] bg-black text-white px-2 py-2 rounded-md border border-gray-dark-1"
                          anchor="selection"
                        >
                          {txn.abstractionFlow.protocols.map((protocol:Protocol, index:any) => (
                            <ListboxOption
                              key={index}
                              value={protocol}
                              className="data-[focus]:bg-primary-default"
                            >
                              {protocol.name}
                            </ListboxOption>
                          ))}
                        </ListboxOptions>
                      </Listbox>
                    </div> */}
                        </div>
                      ))}
                    </div>
                  </div>
                  {index == lastIndex && (
                    <div className="flex ">
                      {/* <div className="flex gap-x-2.5 w-[140px] items-center">
                        <p className="w-[32px] h-[32px] flex justify-center text-base items-center rounded-full flex-shrink-0 bg-[#404040]">
                          {index + 3}
                        </p>
                        <p className="text-base text-gray-light-1">User get</p>
                      </div> */}
                      <div className="flex gap-x-2.5 w-[130px] items-center">
                        <p className="w-[32px] h-[32px] flex justify-center text-base items-center rounded-full flex-shrink-0 bg-[#404040]">
                          {index + 3}
                        </p>
                        <p className="text-base text-gray-light-1">User get</p>
                      </div>
                      <div className="flex items-center gap-1">
                        <img
                          src={getLogo(
                            txn?.abstractionFlow[txn.abstractionFlow.length - 1]
                              ?.to.assetLogo
                          )}
                          alt="Destination Asset Logo"
                          className="w-[24px] h-[24px] rounded-full"
                        />
                        <p className="text-base font-semibold text-white">
                          {
                            txn?.abstractionFlow[txn.abstractionFlow.length - 1]
                              ?.to?.name
                          }{" "}
                          <span className="text-[#818181]">
                            (
                            {capitalizeFirstLetter(
                              txn?.abstractionFlow[
                                txn.abstractionFlow.length - 1
                              ]?.to?.blockchain
                            )}
                            )
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {txn.action == "stake" && (
                <div className="flex flex-col gap-4">
                  <div className="flex items-center">
                    <div className="flex gap-x-2.5 w-[140px] items-center mr-4">
                      <p className="w-[32px] h-[32px] flex justify-center text-base items-center rounded-full flex-shrink-0 bg-[#404040]">
                        {index + 2}
                      </p>
                      <p className="flex text-base item center text-gray-light-1">
                        Stake
                      </p>
                    </div>
                    <div className="flex flex-col w-full gap-4">
                      {txn.abstractionFlow.map((_txn) => (
                        <div className="flex items-center justify-between flex-grow ">
                          <div className="flex items-center gap-1">
                            <img
                              src={getLogo(_txn?.from?.assetLogo)}
                              alt="From Logo"
                              className="w-[24px] h-[24px] rounded-full"
                            />
                            <p className="text-base text-white font-base">
                              {_txn?.from?.name || ""}{" "}
                              <span className="text-[#818181]">
                                (
                                {capitalizeFirstLetter(
                                  _txn?.from?.blockchain || ""
                                )}
                                )
                              </span>
                            </p>
                          </div>
                          <HiArrowSmallRight className="text-[#AEAFBC]" />
                          <div className="flex items-center gap-1">
                            <img
                              src={getLogo(_txn?.to?.assetLogo)}
                              alt="To Logo"
                              className="w-[24px] h-[24px] rounded-full"
                            />
                            <p className="text-base text-white font-base">
                              {_txn?.to?.name || ""}{" "}
                              <span className="text-[#818181]">
                                (
                                {capitalizeFirstLetter(
                                  _txn?.to?.blockchain || ""
                                )}
                                )
                              </span>
                            </p>
                          </div>
                          {/* <HiArrowSmallRight className="text-[#AEAFBC]" />
                    <div className="">
                      <Listbox
                        value={selectedSwapProtocol}
                        onChange={setSelectedSwapProtocol}
                      >
                        <ListboxButton className=" px-1 rounded-[40px] flex items-center justify-center gap-2 h-[32px] bg-white text-black w-[140px] font-semibold">
                          {selectedSwapProtocol?.name}
                          <FaCaretDown />
                        </ListboxButton>
                        <ListboxOptions
                          className="w-[var(--button-width)] bg-black text-white px-2 py-2 rounded-md border border-gray-dark-1"
                          anchor="selection"
                        >
                          {txn.abstractionFlow.protocols.map((protocol:Protocol, index:any) => (
                            <ListboxOption
                              key={index}
                              value={protocol}
                              className="data-[focus]:bg-primary-default"
                            >
                              {protocol.name}
                            </ListboxOption>
                          ))}
                        </ListboxOptions>
                      </Listbox>
                    </div> */}
                        </div>
                      ))}
                    </div>
                  </div>
                  {index == lastIndex && (
                    <div className="flex ">
                      {/* <div className="flex gap-x-2.5 w-[140px] items-center">
                        <p className="w-[32px] h-[32px] flex justify-center text-base items-center rounded-full flex-shrink-0 bg-[#404040]">
                          {index + 3}
                        </p>
                        <p className="text-base text-gray-light-1">User get</p>
                      </div> */}
                      <div className="flex gap-x-2.5 w-[130px] items-center">
                        <p className="w-[32px] h-[32px] flex justify-center text-base items-center rounded-full flex-shrink-0 bg-[#404040]">
                          {index + 3}
                        </p>
                        <p className="text-base text-gray-light-1">User get</p>
                      </div>
                      <div className="flex items-center gap-1">
                        <img
                          src={getLogo(
                            txn?.abstractionFlow[txn.abstractionFlow.length - 1]
                              ?.to.assetLogo
                          )}
                          alt="Destination Asset Logo"
                          className="w-[24px] h-[24px] rounded-full"
                        />
                        <p className="text-base font-semibold text-white">
                          {
                            txn?.abstractionFlow[txn.abstractionFlow.length - 1]
                              ?.to?.name
                          }{" "}
                          <span className="text-[#818181]">
                            (
                            {capitalizeFirstLetter(
                              txn?.abstractionFlow[
                                txn.abstractionFlow.length - 1
                              ]?.to?.blockchain
                            )}
                            )
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {txn.action == "lend" && (
                <div className="flex flex-col gap-4">
                  <div className="flex items-center">
                    <div className="flex gap-x-2.5 w-[130px] items-center">
                      <p className="w-[32px] h-[32px] flex justify-center text-base items-center rounded-full flex-shrink-0 bg-[#404040]">
                        {index + 2}
                      </p>
                      <p className="flex w-[100px] text-base item center text-gray-light-1">
                        {index == lastIndex && "User "} Lend
                      </p>
                    </div>
                    <div className="flex flex-col w-full gap-4">
                      {txn.abstractionFlow.map((_txn) => (
                        <div className="flex items-center justify-between flex-grow ">
                          <div className="flex items-center gap-1">
                            <img
                              src={getLogo(_txn?.from?.assetLogo)}
                              alt="From Logo"
                              className="w-[24px] rounded-full h-[24px]"
                            />
                            <p className="text-base text-white font-base">
                              {_txn?.from?.name || ""}{" "}
                              <span className="text-[#818181]">
                                (
                                {capitalizeFirstLetter(
                                  _txn?.from?.blockchain || ""
                                )}
                                )
                              </span>
                            </p>
                          </div>
                          {_txn.to && (
                            <>
                              <HiArrowSmallRight className="text-[#AEAFBC]" />
                              <div className="flex items-center gap-1">
                                <img
                                  src={getLogo(_txn?.to?.assetLogo)}
                                  alt="To Logo"
                                  className="w-[24px] h-[24px] rounded-full"
                                />
                                <p className="text-base text-white font-base">
                                  {_txn?.to?.name || ""}{" "}
                                  <span className="text-[#818181]">
                                    (
                                    {capitalizeFirstLetter(
                                      _txn?.to?.blockchain || ""
                                    )}
                                    )
                                  </span>
                                </p>
                              </div>
                            </>
                          )}
                          {/* <HiArrowSmallRight className="text-[#AEAFBC]" />
                    <div className="">
                      <Listbox
                        value={selectedSwapProtocol}
                        onChange={setSelectedSwapProtocol}
                      >
                        <ListboxButton className=" px-1 rounded-[40px] flex items-center justify-center gap-2 h-[32px] bg-white text-black w-[140px] font-semibold">
                          {selectedSwapProtocol?.name}
                          <FaCaretDown />
                        </ListboxButton>
                        <ListboxOptions
                          className="w-[var(--button-width)] bg-black text-white px-2 py-2 rounded-md border border-gray-dark-1"
                          anchor="selection"
                        >
                          {txn.abstractionFlow.protocols.map((protocol:Protocol, index:any) => (
                            <ListboxOption
                              key={index}
                              value={protocol}
                              className="data-[focus]:bg-primary-default"
                            >
                              {protocol.name}
                            </ListboxOption>
                          ))}
                        </ListboxOptions>
                      </Listbox>
                    </div> */}
                        </div>
                      ))}
                    </div>
                  </div>
                  {index == lastIndex && txn?.abstractionFlow[txn.abstractionFlow.length - 1].to &&  (
                    <div className="flex ">
                      {/* <div className="flex gap-x-2.5 w-[140px] items-center">
                        <p className="w-[32px] h-[32px] flex justify-center text-base items-center rounded-full flex-shrink-0 bg-[#404040]">
                          {index + 3}
                        </p>
                        <p className="text-base text-gray-light-1">User get</p>
                      </div> */}
                      <div className="flex gap-x-2.5 w-[130px] items-center">
                        <p className="w-[32px] h-[32px] flex justify-center text-base items-center rounded-full flex-shrink-0 bg-[#404040]">
                          {index + 3}
                        </p>
                        <p className="text-base text-gray-light-1">User get</p>
                      </div>
                      <div className="flex items-center gap-1">
                        <img
                          src={getLogo(
                            txn?.abstractionFlow[txn.abstractionFlow.length - 1]
                              ?.to?.assetLogo
                          )}
                          alt="Destination Asset Logo"
                          className="w-[24px] h-[24px] rounded-full"
                        />
                        <p className="text-base font-semibold text-white">
                          {
                            txn?.abstractionFlow[txn.abstractionFlow.length - 1]
                              ?.to?.name
                          }{" "}
                          <span className="text-[#818181]">
                            (
                            {capitalizeFirstLetter(
                              txn?.abstractionFlow[
                                txn.abstractionFlow.length - 1
                              ]?.to?.blockchain
                            )}
                            )
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AbstractionFlowEdit;
