import axios from "axios";
import { BACKEND_URL } from "../env";
import { checkUserResponse, UserDetailsResponse, walletType } from "../types";

export const getAllProjects = async () => {
  try {
    return await axios.get("");
  } catch (error) {
    console.error(error, "signUp");
  }
};

// export const getProjectByProjectId = async ({
//   projectId,
// }: {
//   projectId: string;
// }) => {
//   try {
//     return await axios.get("", {
//       params: {
//         projectId,
//       },
//     });
//   } catch (error) {
//     console.error(error, "signUp");
//   }
// };

export const checkUserRecord = async ({
  accountAddress,
  walletType,
}: {
  accountAddress: string;
  walletType: walletType;
}): Promise<checkUserResponse> => {
  try {
    const response = await axios.post(
      
      `${BACKEND_URL}api/clients/check-record`,
      {
        auth_provider: walletType,
        provider_id: accountAddress,
      }
    );
    return {
      statusCode: response.data.statusCode,
      data: response.data.data,
    };
  } catch (error) {
    console.error(error, "check-record");
    return {
      statusCode: 400,
      data: {
        id: "",
        company_id: "",
        email: "",
        auth_provider: "",
        provider_id: "",
        created_at: "",
        updated_at: "",
      },
    };
  }
};

export const getUserDetails = async ():Promise<UserDetailsResponse | null> => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${BACKEND_URL}api/clients/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data
  } catch (error) {
    console.error(error);
    return null
  }
};
 