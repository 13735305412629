import React from "react";
import { Route, Routes, } from "react-router-dom";
import "./App.css";
import Tslogin from "./pages/Tsconnect";
import LidoConnect from "./pages/CreateOnboardingApp/CustomizedStake";
import OnboardLogin from "./pages/Onboard_Login/LoginModal";


import ExploreDApp from "./pages/Explore/Exploredapp";
import ExploreView from "./pages/Explore/ExploreView";
import ModularFeatures from "./pages/Modular/ModularFeatures";
import ModularView from "./pages/Modular/ModularView";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import { config } from "./config/wagmi";
import AbstractionFlow from "./pages/CreateOnboardingApp/AbstractionFlow";
import Dashboard from "./pages/Dashboard";
import CreateOnboardingApp from "./pages/CreateOnboardingApp";
import Archive from "./pages/Archive";
import { ToastContainer, toast } from "react-toastify";
import ManageTeam from "./pages/ManageTeam";
import CustomizedStake from "./pages/CreateOnboardingApp/CustomizedStake";

const queryClient = new QueryClient();

function App() {
  return (
    <div className="min-h-screen bg-black">
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <Routes>
            {/* <Route path="/" element={<OnboardLogin />} /> */}
            {/* <Route path="/Login" element={<Tslogin />} /> */}
            {/* <Route path="/Lidoconnect" Component={LidoConnect} /> */}
            <Route path="/" element={<Dashboard />} />
             <Route path="/Dashboard" element={<Dashboard />} />
            {/* <Route path="/Loginboard" element={<OnboardLogin />} /> */}
            <Route
              path="/CreateOnboardingApp"
              element={<CreateOnboardingApp />}
            />
            <Route path="/ManageTeam" element={<ManageTeam />} />
            {/* <Route path="/Lending" element={<LendingApp />} /> */}
            {/* <Route path="/Explore" element={<ExploreDApp />} />
            <Route path="/Abstraction" element={<AbstractionFlow />} />
            <Route path="/Customization" element={<CustomizedStake appName={""} />} /> */}
            {/* <Route path="/explore/:dappId" element={<ExploreView />} /> */}
            <Route path="/modular/:dappId" element={<ModularView />} />
            <Route path="/modular" element={<ModularFeatures />} />
            <Route path="/archive" element={<Archive />} />
            
            <Route path="*" element={<h1>Page Not Found</h1>} />
          </Routes>
          <ToastContainer
            icon={({ type, theme }) => {
              switch (type) {
                case "success":
                  return <img src="/assets/success-icon.svg" />;
              }
            }}
          />
          <Tslogin />
        </QueryClientProvider>
      </WagmiProvider>
    </div>
  );
}

export default App;
