import React, { useMemo, useState } from "react";
import CustomizedTransaction from "../Onboard_Login/OnboardStaking/TransactionModal";

import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoIosCheckmark } from "react-icons/io";
import { useAppSelector } from "../../state/hooks";
import TransactionDetails from "../../components/TransactionDetails";
import ThumbnailUpload from "../../components/ThumbnailUpload";
import {
  convertHexToRgba,
  getValidObjects,
  parseEscapedJSONString,
} from "../../utils/helper";
import { OnBoardingType, THEME } from "../../state/types";
import { IoCloseOutline } from "react-icons/io5";
import { MdArrowDropDown } from "react-icons/md";
import ImageOverlay from "../../components/ImageOverlay";

interface PreviewComponentProps {
  pageBgColor: string;
  accentColor: string;
  logo: string;
  title: string;
  subtitle: string;
  website: string;
  theme: THEME;
  btnTextColor: string;
}

const PreviewComponent: React.FC<PreviewComponentProps> = ({
  pageBgColor,
  accentColor,
  logo,
  title,
  subtitle,
  website,
  theme,
  btnTextColor,
}) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [noOfprotocol, setNoOfProtocol] = useState<number>(0);
  const [rgba, setRgba] = useState("rgba(0, 0, 0, 1)");

  const {
    onboardingType,
    abstractionFlow,
    onBoardingRecord,
    onBoardingAppDetails,
    isEditingOnboardingApp,
  } = useAppSelector((state) => ({
    onboardingType: state.onBoardingState.onBoardingType,
    abstractionFlow: state.onBoardingState.abstractionFlow,
    onBoardingRecord: state.onBoardingState.onBoardingRecord,
    onBoardingAppDetails: state.appState.onBoardingAppDetails,
    isEditingOnboardingApp: state.onBoardingState.isEditingOnboardingApp,
  }));

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  const TABS = ["Onboarding component", "Preview Thumbnail"];

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const bgColor = `bg-[${accentColor}]`;
  const pageBg = `bg-[${pageBgColor}]`;

  useMemo(() => {
    if (abstractionFlow) {
      const validAbstraction = getValidObjects(
        abstractionFlow.data.transaction_layout.layout
      );
      setNoOfProtocol(validAbstraction?.length);
    }
    const rgbaValue = convertHexToRgba(accentColor);
    setRgba(rgbaValue);
  }, [abstractionFlow, accentColor]);



  useMemo(() => {
    if (isEditingOnboardingApp) {
      if (onBoardingAppDetails?.data.abstraction_flow) {
        const validAbstraction = getValidObjects(
          parseEscapedJSONString(
            String(onBoardingAppDetails?.data.abstraction_flow)
          )?.transaction_layout?.layout
        );
        setNoOfProtocol(validAbstraction?.length);
      }
    }
  }, [isEditingOnboardingApp, onBoardingAppDetails?.data]);

  const destAssetIcon =
    onBoardingRecord.bridgeTypedestAsset?.icon ??
    onBoardingRecord.lendTypeDestAsset?.dest_icon ??
    onBoardingRecord.swapTypedestAsset?.destination_asset_logo;
  const destChainIcon =
    onBoardingRecord.bridgeTypedestChain?.network_logo_url ??
    onBoardingRecord.lendTypeDestChain?.dest_network_icon ??
    onBoardingRecord.swapTypedestChain?.destination_network_logo;
  const destAsset =
    onBoardingRecord.bridgeTypedestAsset?.name ??
    onBoardingRecord.lendTypeDestAsset?.dest_name ??
    onBoardingRecord.swapTypedestAsset?.destination_asset;
  const sourceAssetIcon =
    onBoardingRecord.bridgeTypesourceAsset?.icon ??
    onBoardingRecord.lendTypeOnboardingSourceAssets?.source_icon ??
    onBoardingRecord.swapTypesourceAsset?.source_asset_logo ??
    onBoardingRecord.stakeTypeSourceAssets?.source_icon;
  const sourceChainIcon =
    onBoardingRecord.bridgeTypesourceChain?.icon ??
    onBoardingRecord.lendOnboardingAssets?.onboarding_network_icon ??
    onBoardingRecord.swapTypesourceChain?.source_asset_network_logo ??
    onBoardingRecord.stakeTypeSourceChain?.source_network_icon;
  const sourceAsset =
    onBoardingRecord.bridgeTypesourceAsset?.name ??
    onBoardingRecord.lendTypeOnboardingSourceAssets?.source_name ??
    onBoardingRecord.swapTypesourceAsset?.source_asset ??
    onBoardingRecord.stakeOnboardingAssets?.onboarding_name;
  const OnBoarding = () => {
    return (
      <div className={`flex flex-col items-center w-full `}>
        {logo ? (
          <div className="flex justify-center w-full mt-[40px]">
            <img
              src={logo}
              className="max-w-[160px] max-h-[40px] object-cover"
            />
          </div>
        ) : (
          <div
            className={`w-[187px] ${
              theme == THEME.DARK ? "border-white" : "border-black"
            } flex justify-center items-center h-[60px] mt-[40px]  border border-dashed border-spacing-1 rounded-lg`}
          >
            <p
              className={`text-xl font-semibold ${
                theme == THEME.DARK ? "text-white" : "text-black"
              }`}
            >
              Logo
            </p>
          </div>
        )}
        <div
          className={`${
            theme == THEME.DARK ? "text-white" : "text-black"
          } text-center flex-col gap-4 mt-[24px] `}
        >
          <h1 className="text-2xl font-semibold text-center ">{title}</h1>
          <p
            className={`${
              theme == THEME.DARK ? "text-white" : "text-black"
            } text-base font-medium`}
          >
            {subtitle}
          </p>
          <p
            className={`text-base font-medium ${
              theme == THEME.DARK ? "text-[#CCCCCC]" : "text-black"
            } text-[15px]`}
          >
            {website}
          </p>
        </div>
        <div
          className={`${
            theme == THEME.DARK ? "text-white" : "text-black"
          } flex flex-col gap-2 w-[453px] mt-[24px]`}
        >
          <label
            className={` ${
              theme == THEME.DARK ? "text-[#D4D4D4]" : "text-black"
            } text-base font-semibold`}
          >
            {onboardingType == OnBoardingType.STAKE && "Staking amount"}
            {onboardingType == OnBoardingType.SWAP && "Swapping amount"}
            {onboardingType == OnBoardingType.LEND && "Lending amount"}
          </label>
          <div className="grid w-[453px] grid-cols-[75%,25%]">
            <input
              type="number"
              disabled
              placeholder="Insert number"
              className={`h-[56px] p-3 outline-none border border-r-0 rounded-r-none  rounded-lg bg-white/10`}
              style={{
                borderColor: accentColor,
              }}
            />
            <div
              className={`h-[56px] appearance-none gap-2 outline-none border flex justify-center items-center rounded-lg border-l-0 rounded-l-none bg-white/10`}
              style={{
                borderColor: accentColor,
              }}
            >
              {isEditingOnboardingApp && onBoardingAppDetails?.data ? (
                <>
                  {" "}
                  <ImageOverlay
                    mainImageSrc={
                      onBoardingAppDetails?.data.asset_images?.source_asset_icon
                    }
                    overlayImageSrc={
                      onBoardingAppDetails?.data.asset_images?.source_asset_icon
                    }
                  />
                  <p
                    className={`${
                      theme == THEME.DARK ? "text-[#D4D4D4]" : "text-black"
                    } text-base font-semibold leading-5 `}
                  >
                    {
                      onBoardingAppDetails?.data.asset_images
                        ?.source_asset_symbol
                    }{" "}
                  </p>
                </>
              ) : (
                <>
                  {" "}
                  <ImageOverlay
                    mainImageSrc={sourceAssetIcon}
                    overlayImageSrc={sourceChainIcon}
                  />
                  <p className={`text-base font-semibold ${
                      theme == THEME.DARK ? "text-[#D4D4D4]" : "text-black"
                    } leading-5`}>
                    {sourceAsset}{" "}
                  </p>
                </>
              )}
            </div>
          </div>

          <div className="flex justify-between px-2">
            <p
              className={`${
                theme == THEME.DARK
                  ? "text-[#D4D4D4]  font-semibold"
                  : "text-black"
              } text-[15px]`}
            >
              Balance <span className="font-semibold">0</span>{" "}
              <span
                className={`font-semibold ${
                  theme == THEME.DARK ? "text-white" : "text-black"
                }`}
              >
                {/* {onBoardingRecord.sourceAsset?.name} */}
                {isEditingOnboardingApp
                  ? onBoardingAppDetails?.data.asset_images?.source_asset_symbol
                  : sourceAsset}
              </span>
            </p>
            {/* <p className="text-base font-semibold text-primary-default">
              +Add fund
            </p> */}
          </div>
        </div>
        {onboardingType == OnBoardingType.LEND ||
          (onboardingType == OnBoardingType.SWAP && (
            <div
              className={`${
                theme == THEME.DARK ? "text-white" : "text-black"
              } flex flex-col gap-2 w-[453px] mt-[32px]`}
            >
              <label
                className={` ${
                  theme == THEME.DARK ? "text-[#D4D4D4]" : "text-black"
                } text-base font-semibold`}
              >
                You get
              </label>
              <div className="grid w-[453px] grid-cols-[75%,25%]">
                <input
                  type="number"
                  disabled
                  placeholder="0.00"
                  className={`h-[56px] p-3 outline-none rounded-r-none  rounded-lg bg-white/10`}
                  
                />
                <div
                  className={`h-[56px] appearance-none gap-2 outline-none flex justify-center items-center rounded-lg bg-white/10`}
                  
                >
                  {/* <img
                  className="w-[24px] h-[24px]"
                  src="/assets/stMonad.svg"
                  alt={onBoardingRecord.bridgeTypedestAsset?.symbol ?? onBoardingRecord.swapTypedestAsset?.destination_asset ?? onBoardingRecord.lendTypeDestAsset?.dest_icon}
                /> */}
                  {isEditingOnboardingApp && onBoardingAppDetails?.data ? (
                    <>
                      {" "}
                      <ImageOverlay
                        mainImageSrc={
                          onBoardingAppDetails?.data.asset_images
                            ?.dest_asset_icon
                        }
                        overlayImageSrc={
                          onBoardingAppDetails?.data.asset_images
                            ?.dest_asset_icon
                        }
                      />
                      <p
                        className={`text-base font-semibold leading-5 ${
                      theme == THEME.DARK ? "text-[#D4D4D4]" : "text-black"
                    }`}
                      >
                        {
                          onBoardingAppDetails?.data.asset_images
                            ?.dest_asset_symbol
                        }{" "}
                      </p>
                    </>
                  ) : (
                    <>
                      {" "}
                      <ImageOverlay
                        mainImageSrc={destAssetIcon}
                        overlayImageSrc={destChainIcon}
                      />
                      <p className={`text-base ${
                      theme == THEME.DARK ? "text-[#D4D4D4]" : "text-black"
                    } font-semibold leading-5`}>
                        {destAsset}{" "}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        <div className="flex flex-col items-start w-full ">
          <div className="flex items-center justify-start gap-2 mt-[32px]">
            <button
              style={{
                borderColor: accentColor,
                color: accentColor,
                backgroundColor: rgba,
              }}
              className="border text-base font-semibold rounded-full h-[29px] w-[145px]"
            >
              Transaction flow
            </button>
            <p
              className={`${
                theme == THEME.DARK ? "text-[#AAA]" : "text-black"
              } font-medium text-[15px]`}
            >
              via {noOfprotocol} protocol
            </p>
          </div>
        </div>
        {onboardingType != OnBoardingType.SWAP && (
          <div
            
            className="flex flex-col w-[453px] p-[16px] items-stretch gap-[8px] mt-[32px] h-[86px] border rounded-lg"
          >
            <div
              className={`flex items-center justify-between ${
                theme == THEME.DARK ? "text-gray-light2" : "text-black"
              }`}
            >
              <p
                className={`text-[15px] font-medium ${
                  theme == THEME.DARK ? "text-gray-light2" : "text-black"
                }`}
              >
                {onboardingType == OnBoardingType.STAKE && " Reference APR"}
                {onboardingType == OnBoardingType.LEND && "Lending terms"}
              </p>
              <p
                className={`${
                  theme == THEME.DARK ? "text-white" : "text-black"
                }text-base font-bold`}
              >
                {onboardingType == OnBoardingType.STAKE && "10%"}
                {onboardingType == OnBoardingType.LEND && "Flex"}
              </p>
            </div>
            <div className={`flex items-center justify-between `}>
              <p
                className={`text-[15px] font-medium ${
                  theme == THEME.DARK ? "text-gray-light2" : "text-black"
                }`}
              >
                {onboardingType == OnBoardingType.STAKE &&
                  " Monthly est. rewards"}
                {onboardingType == OnBoardingType.LEND && "Estimate APY"}
              </p>
              <p
                className={`text-base font-bold ${
                  theme == THEME.DARK ? "text-white" : "text-black"
                }`}
              >
                {onboardingType == OnBoardingType.STAKE && "-"}
                {onboardingType == OnBoardingType.LEND && "3%"}
              </p>
            </div>
          </div>
        )}
        {onboardingType == OnBoardingType.SWAP && (
          <div
            
            className="border flex items-center justify-between w-[453px] py-[12px] px-[16px] mt-[32px] rounded-lg"
          >
            <div>
              {isEditingOnboardingApp && onBoardingAppDetails?.data ? (
                <p
                  className={`text-[15px] font-medium ${
                    theme == THEME.DARK ? "text-gray-light2" : "text-black"
                  }`}
                >
                  1{" "}
                  {onBoardingAppDetails?.data.asset_images?.source_asset_symbol}{" "}
                  = 34.49{" "}
                  {onBoardingAppDetails?.data.asset_images?.dest_asset_symbol}
                </p>
              ) : (
                <p
                  className={`text-[15px] font-medium ${
                    theme == THEME.DARK ? "text-gray-light2" : "text-black"
                  }`}
                >
                  1 {sourceAsset} = 34.49 {destAsset}
                </p>
              )}
            </div>
            <MdArrowDropDown
              className={`${
                theme == THEME.DARK ? "text-white" : "text-black"
              } w-[20px] h-[20px]`}
            />
          </div>
        )}
        <div className="mt-[32px]">
          <button
            style={{
              backgroundColor: accentColor,
              color: btnTextColor,
            }}
            disabled
            className={`w-[453px] ${
              theme == THEME.DARK ? "text-white" : "text-black"
            } text-lg font-semibold rounded-lg h-[56px] `}
          >
            {onboardingType == OnBoardingType.STAKE && "Stake"}
            {onboardingType == OnBoardingType.SWAP && "Swap"}
            {onboardingType == OnBoardingType.LEND && "Lend"}
          </button>
        </div>
      </div>
    );
  };

  const SignUp = () => {
    return (
      <div className="flex flex-col h-full gap-6">
        <div className="flex flex-col items-center justify-center gap-3">
          <img className="w-[170px] h-[35px]" src={logo} />
          <p className="text-[24px] font-normal text-white">
            Signature request
          </p>
          {/* <div className="flex flex-col items-center gap-2">
            <img src="/assets/lido.png" className="w-[44px]" />
            <p className="text-base font-semibold">Lido Finance</p>
            <a className="text-[#AAAAAA]">https://lido.fi/</a>
          </div> */}
        </div>
        <div className="flex flex-col items-center gap-1">
          <p className="w-full pl-4 text-left text-[#FFFFFF]">Approve with</p>
          <div
            className="flex item-center w-[436px] px-4 py-3 border p-2 bg-[#222] rounded-lg"
            style={{
              borderColor: accentColor,
            }}
          >
            <img
              src="/assets/eth_logo_with_bg.svg"
              alt="ETH LOGO"
              className="w-[35px] h-[35px]"
            />
            <div className="flex flex-col justify-center h-[35px]">
              <p className="text-[15px] font-semibold text-white">
                Main account
              </p>
              <p className="text-base">0x1a3a8efc2f6a...97f1b1403c5a45084</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-1">
          <p className="w-full pl-4 text-left text-[#FFFFFF]">
            This app would like to
          </p>
          <div
            className="flex flex-col gap-2 item-center w-[436px] px-4 py-3 border p-2 bg-[#222] rounded-lg"
            style={{
              borderColor: accentColor,
            }}
          >
            <div className="flex items-center gap-1">
              <IoIosCheckmark className="bg-[#039855] rounded-full text-white" />
              <p className="text-[#CCCCCC]">View your balance and activities</p>
            </div>
            <div className="flex items-center gap-1">
              <IoIosCheckmark className="bg-[#039855] rounded-full text-white" />
              <p className="text-[#CCCCCC]">
                Request approval for transactions
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-evenly">
          <button className="w-[210px] h-[48px] text-lg rounded-[40px] bg-white text-black">
            Cancel
          </button>
          <button
            className={`w-[210px] h-[48px] text-lg rounded-[40px] ${bgColor} text-white`}
          >
            Approve and Finish
          </button>
        </div>
        <div className="flex justify-center py-2">
          <p>
            By signing up, you agree to the{" "}
            <span className="text-primary-light">
              Terms of Service and Privacy Policy.
            </span>{" "}
          </p>
        </div>
      </div>
    );
  };

  const ViewFullScreen = () => {
    return (
      <Dialog
        open={isOpen}
        as="div"
        className="relative z-10 focus:outline-none"
        onClose={() => {}}
      >
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex items-center justify-center min-h-full ">
            <DialogPanel
              transition
              //onClick={close}
              className="w-full h-fit flex pb-[45px] flex-col rounded-xl bg-black duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
            >
              <div className="flex items-end justify-end w-full px-5 mb-2">
                <button onClick={close}>
                  <IoCloseOutline className="w-[40px] h-[40px]" color="#FFF" />
                </button>
              </div>
              <div
                style={{ backgroundColor: pageBgColor }}
                className="h-full flex flex-col items-center pt-6 pb-[48px] px-6 mx-[64px] "
              >
                <div className="flex justify-between w-full">
                  {logo ? (
                    <img
                      src={logo}
                      className=" max-w-[160px] max-h-[40px] object-contain"
                    />
                  ) : (
                    <div className="w-[187px] flex justify-center items-center h-[60px]  border border-dashed border-spacing-1 rounded-lg">
                      <p className="text-xl font-semibold">Logo</p>
                    </div>
                  )}
                  <button className="w-[152px] text-white h-[44px] rounded-full border border-white">
                    Connect Wallet
                  </button>
                </div>
                <div className="flex items-center justify-center h-full ">
                  <div
                    className={`w-[517px] flex flex-col px-[32px]  h-fit ${
                      theme == THEME.DARK ? "bg-[#121018]" : "bg-white"
                    } rounded-xl`}
                  >
                    {activeTab == 0 && <OnBoarding />}
                    {/* {activeTab == 1 && (
            <TransactionDetails logo={logo} accentColor={accentColor} />
          )}
          {activeTab == 2 && <SignUp />} */}
                    {/* {activeTab == 1 && (
                      <ThumbnailUpload
                        title={title}
                        subtile={subtitle}
                        logo={logo}
                      />
                    )} */}
                    <div className="flex items-center justify-center my-6">
                      <img
                        src="/assets/trademark.svg"
                        className="w-[165px] h-[21px]"
                        alt="powered by townesquare"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    );
  };

  return (
    <div className="">
      {/* Tabs */}
      <div className="flex items-center pb-[16px] justify-center w-full gap-15">
        {TABS.map((tab, index) => (
          <div className="flex flex-col items-center justify-center">
            <button
              key={tab}
              // className={`tab ${activeTab === tab ? "active" : ""}`}
              className="mb-1 text-base font-semibold text-white"
              onClick={() => handleTabClick(index)}
            >
              {tab}
            </button>
            {activeTab === index && (
              <div className="w-[32px] border-b-4 border-primary-light"></div>
            )}
          </div>
        ))}
      </div>
      {activeTab == 1 && (
        <p className="text-[16px] text-gray-light-1 my-[16px]">
          This is how the Onboarding link thumbnail will look when you share the
          link on socials
        </p>
      )}
      {activeTab == 0 && (
        <p className="text-[16px] text-gray-light-1 my-[16px]">
          This is the the component the users will see after they have clicked
          on the Onboarding link
        </p>
      )}

      {/* Preview Panel */}
      <div
        className="w-[651px] flex-col h-fit flex justify-center items-center rounded-xl pt-[32px] pb-[48px] px-[40px]"
        style={{ backgroundColor: pageBgColor }}
      >
        {activeTab == 1 && (
          <div className="flex flex-col items-start justify-start w-[517px] mb-[16px]">
            <p className="text-white">Hi. Try out our onboarding app here.</p>
            <a className="text-[#5A99F7]">
              https: //townsquare.xyz/apriori/stake-stMonad/12344
            </a>
          </div>
        )}
        <div
          className={`w-[517px] flex flex-col px-[32px]  h-full ${
            theme == THEME.DARK ? "bg-[#121018]" : "bg-white"
          } rounded-xl`}
        >
          {activeTab == 0 && <OnBoarding />}
          {/* {activeTab == 1 && (
            <TransactionDetails logo={logo} accentColor={accentColor} />
          )}
          {activeTab == 2 && <SignUp />} */}
          {activeTab == 1 && (
            <ThumbnailUpload title={title} subtile={subtitle} logo={logo} />
          )}
          <div className="flex items-center justify-center my-6">
            <img
              src="/assets/trademark.svg"
              className="w-[165px] h-[21px]"
              alt="powered by townesquare"
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center mt-4">
        {activeTab == 1 ? (
          <div></div>
        ) : (
          <button
            className="font-semibold outline-none text-primary-light"
            onClick={open}
          >
            View in full screen
          </button>
        )}
      </div>
      <ViewFullScreen />
    </div>
  );
};

export default PreviewComponent;
