import React, { useState } from "react";
import { PiDotsThreeOutlineFill, PiCopyLight } from "react-icons/pi";
import {
  Field,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import {
  addBonusToOnBoardingApp,
  toggleActiveOnBoarding,
  toggleArchiveOnBoarding,
} from "../api/project/route";
import { useQueryClient } from "@tanstack/react-query";
import { useAppDispatch } from "../state/hooks";
import {
  updateIsEditingOnboardingApp,
  updateOnboardingId,
} from "../state/onboarding/onboarding";
import { OnBoardingType } from "../state/types";
import { capitalize } from "lodash";
import { errorMessages, getCreatedDate, SessionKeys } from "../utils/helper";
import { handleFetchOnboardingAppDetails } from "../state/action";
import {
  Button,
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
  Input,
} from "@headlessui/react";
import { IoMdClose } from "react-icons/io";
import { AiFillThunderbolt } from "react-icons/ai";
import { toast } from "react-toastify";

const OnBoardingCard = ({
  name,
  isActive,
  title,
  sourceAsset,
  onBoardingLink,
  onBoardingId,
  projectId,
  isArchived,
  onboardingType,
  destAsset,
  assets,
  dest_chain,
  source_chain,
  createdTime,
  isProjectOwner,
  bonusPercentage,
  isSuperAdmin,
}: {
  name: string;
  isActive: boolean;
  title: string | null;
  sourceAsset: string | null;
  onBoardingLink: string | null;
  onBoardingId: number;
  source_chain: string | null;
  dest_chain: string | null;
  projectId: number;
  isArchived: boolean;
  onboardingType: string;
  destAsset: string | null;
  assets: {
    source_asset_icon: string;
    dest_asset_icon: string;
    source_asset_symbol: string;
    dest_asset_symbol: string;
  } | null;
  createdTime: string;
  isProjectOwner?: boolean;
  isSuperAdmin?: boolean;
  bonusPercentage: number;
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const [linkIsCopied, setLinkIsCopied] = useState(false);
  const [openAddBonus, setOpenAddBonus] = useState<boolean>(false);

  const mutation = useMutation({
    mutationFn: async () => {
      return await toggleActiveOnBoarding(String(onBoardingId));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getAllOnboardingFromProjectId", projectId],
      });
      queryClient.invalidateQueries({
        queryKey: ["fetchSingleOnboardingApp", onBoardingId],
      });
    },
  });

  const archive = useMutation({
    mutationFn: async () => {
      return await toggleArchiveOnBoarding(String(onBoardingId));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getAllOnboardingFromProjectId", projectId],
      });
      queryClient.invalidateQueries({
        queryKey: ["fetchSingleOnboardingApp", onBoardingId],
      });
    },
  });

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${onBoardingLink}`);
    setLinkIsCopied(true);
    setTimeout(() => {
      setLinkIsCopied(false);
    }, 2000);
  };

  const handleOnEdit = () => {
    dispatch(updateOnboardingId(onBoardingId));
    dispatch(
      handleFetchOnboardingAppDetails({
        onBoardingId: onBoardingId,
      })
    );
    dispatch(updateIsEditingOnboardingApp(true));
    sessionStorage.setItem(
      SessionKeys.isEditingOnboardingApp,
      JSON.stringify(true)
    );
    sessionStorage.setItem(SessionKeys.onBoardingAppId, String(onBoardingId));
    navigate("/CreateOnboardingApp");
  };

  const closeAddBonusModal = () => {
    setOpenAddBonus(false);
  };

  const openAddBonusModal = () => {
    setOpenAddBonus(true);
  };

  const AddBonusDialog = () => {
    const [bonusAmount, setBonusAmount] = useState<string>("");
    const handleAddBonusAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setBonusAmount(value);
    };

    const notifyBonusAdded = () => {
      toast.success(`Bonus has been applied`, {
        position: "top-right",
        className:
          "w-[340px] bg-[#222] border border-[#666] text-[14px] rounded-[4px] py-[24px] px-[16px] text-white",
        progressClassName: "bg-[#2ab576]",
      });
    };

    const addBonus = useMutation({
      mutationFn: async () => {
        return await addBonusToOnBoardingApp(
          Number(bonusAmount),
          String(onBoardingId)
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["getAllOnboardingFromProjectId", projectId],
        });
        queryClient.invalidateQueries({
          queryKey: ["fetchSingleOnboardingApp", onBoardingId],
        });
        closeAddBonusModal();
        notifyBonusAdded();
      },
      onError(error, variables, context) {
        errorMessages(error.message);
      },
    });

    const disableAddBonusButton =
      !bonusAmount || Number(bonusAmount) <= 0 || Number(bonusAmount) > 100;

    return (
      <Dialog
        open={openAddBonus}
        as="div"
        className="relative z-10 focus:outline-none"
        onClose={closeAddBonusModal}
      >
        <DialogBackdrop className="fixed inset-0 bg-black/70" />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4">
            <DialogPanel
              transition
              className="w-[374px] h-[190px] p-[32px] gap-[56px] rounded-xl bg-[#222] border border-gray-light-3 flex flex-col items-center justify-between data-[closed]:opacity-0"
            >
              <DialogTitle
                as="div"
                className="flex items-center justify-between w-full"
              >
                <h1 className="text-[20px] text-white font-semibold leading-[24px]">
                  Manage app’s points bonus
                </h1>
                <IoMdClose
                  onClick={() => {
                    setOpenAddBonus(false);
                  }}
                  className="text-end"
                  size={24}
                  color="#fff"
                />
              </DialogTitle>

              <div className="flex gap-[24px] items-center">
                <Field className="flex items-center gap-[12px]">
                  <Input
                    value={bonusAmount}
                    onChange={handleAddBonusAmount}
                    placeholder="0"
                    type="number"
                    className="w-[85px] no-spinner px-[16px] outline-none py-[12px] placeholder:text-white text-white h-[46px] rounded-[40px] bg-gray-dark-3 border-white border"
                  />
                  <label className="text-[20px] font-normal leading-[24px] text-white">
                    %
                  </label>
                </Field>
                <button
                  onClick={() => {
                    addBonus.mutate();
                  }}
                  disabled={disableAddBonusButton}
                  className="w-[133px] h-[46px] text-[15px] font-medium text-center bg-primary-default rounded-[32px] text-white active:bg-primary-active hover:bg-primary-hover disabled:opacity-50"
                >
                  Apply bonus
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    );
  };



  return (
    <div className="py-[32px] px-[24px] relative rounded-2xl border border-white/40 bg-dark-gray-2 w-[290px] h-[370px] ">
      <div className="flex items-center justify-between w-full m-0">
        <p className="text-[15px] font-medium">
          {capitalize(onboardingType)} app
        </p>
        <div className="flex items-center justify-between gap-[12px] ">
          {isActive ? (
            <button className="text-sm flex justify-center text-white font-semibold items-center border-2 bg-[#2AB576]/20 border-[#2AB576] h-[28px] w-[70px] outline-none rounded-[38px]">
              Active
            </button>
          ) : (
            <button className="text-sm flex justify-center text-white items-center border font-semibold bg-white/10 border-gray-light-1 h-[28px] w-[75px] outline-none rounded-[38px]">
              Unactive
            </button>
          )}
          {/* <button className="h-[32px] w-[32px] border flex justify-center items-center border-gray-light-3 outline-none">
            <PiDotsThreeOutlineFill color="#FFF" />
          </button> */}
          {isArchived == false && isProjectOwner == true && (
            <div className=" w-[32px]">
              <Menu>
                <MenuButton className="inline-flex items-center justify-center w-[32px] h-[32px] font-semibold text-white rounded outline-none border-gray-light-3 focus:outline-none">
                  <img className="w-[32px]" src="/assets/hamburger_btn.svg" />
                </MenuButton>

                <MenuItems
                  transition
                  anchor="bottom end"
                  className="w-[124px] mt-2 origin-top-right border rounded-lg border-gray-light-3 bg-[#404040] p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
                >
                  <MenuItem>
                    <button
                      onClick={handleOnEdit}
                      className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-primary-default"
                    >
                      Edit
                    </button>
                  </MenuItem>
                  <MenuItem>
                    <button className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-primary-default">
                      <a target="_blank" href={`${onBoardingLink}`}>
                        Preview App
                      </a>
                    </button>
                  </MenuItem>
                  <MenuItem>
                    <button
                      onClick={() => {
                        mutation.mutate();
                      }}
                      className={`group flex w-full items-center gap-2 ${
                        isActive ? "text-[#FF4471]" : "text-white"
                      } rounded-lg py-1.5 px-3 data-[focus]:bg-primary-default`}
                    >
                      {isActive ? " Deactivate" : "Activate"}
                    </button>
                  </MenuItem>
                  <MenuItem>
                    <button
                      onClick={() => {
                        archive.mutate();
                      }}
                      className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-primary-default"
                    >
                      Archive
                    </button>
                  </MenuItem>
                  {isSuperAdmin && (
                    <>
                      <div className="h-px my-1 bg-gray-light-3" />
                      <MenuItem>
                        <button
                          onClick={() => {
                            openAddBonusModal();
                          }}
                          className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-primary-default"
                        >
                          Change bonus
                        </button>
                      </MenuItem>
                    </>
                  )}
                </MenuItems>
              </Menu>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-[1px] py-3">
        <h2 className="text-[23px] font-medium text-white">{name}</h2>
        <p className="text-[13px] text-[#AAA] ">
          Created on {getCreatedDate(createdTime)}
        </p>
      </div>
      {bonusPercentage > 0 && (
        <div className="flex justify-center gap-[4px] items-center w-[97px] rounded-[30.186px] h-[24px] border border-[#00EEFD]">
          <AiFillThunderbolt size={14} color="#00EEFD" />
          <p className="text-[#00EEFD] text-[12px] font-bold">
            {" "}
            {`+${bonusPercentage}%`}
          </p>
          <p className="text-[12px]">bonus</p>
        </div>
      )}
      {onboardingType == OnBoardingType.STAKE && (
        <div className="flex flex-col gap-2 my-[24px]">
          <div className="flex items-center gap-2">
            <span className="text-gray-light-1 text-[15px] font-medium">
              Send
            </span>
            <span className="text-[15px] font-medium flex items-center gap-1 text-white uppercase">
              <img
                className="w-[16px] h-[16px] rounded-full"
                src={assets?.source_asset_icon}
              />{" "}
              {assets?.source_asset_symbol}
              <span className="text-[#CCCCCC] capitalize">
                (
                {source_chain
                  ? source_chain.charAt(0).toUpperCase() +
                    source_chain.slice(1).toLowerCase()
                  : ""}
                )
              </span>
            </span>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-gray-light-1 text-[15px] font-medium">
              Stake
            </span>
            <span className="text-[15px] flex items-center gap-1 font-medium text-white">
              <img
                className="w-[16px] h-[16px] rounded-full"
                src={assets?.dest_asset_icon}
              />
              {assets?.dest_asset_symbol}
              <span className="text-[#CCCCCC]">
                (
                {dest_chain
                  ? dest_chain.charAt(0).toUpperCase() + dest_chain.slice(1)
                  : ""}
                )
              </span>
            </span>
          </div>
          {/* <div className="flex items-center">
            <span className="text-gray-light-1 text-[15px] font-medium">
              Reference APR
            </span>
            <span className="ml-2 text-white text-[15px] font-medium">10%</span>
          </div> */}
        </div>
      )}
      {onboardingType == OnBoardingType.SWAP && (
        <div className="flex flex-col gap-2 my-[24px]">
          <div className="flex items-center gap-2">
            <span className="text-gray-light-1 text-[15px] font-medium">
              From
            </span>
            <span className="text-[15px] font-medium flex items-center gap-1 text-white uppercase">
              <img
                className="w-[16px] h-[16px] rounded-full"
                src={assets?.source_asset_icon}
              />{" "}
              {assets?.source_asset_symbol}
              <span className="text-[#CCCCCC] capitalize">
                ({source_chain})
              </span>
              <span className="text-[#CCCCCC]"></span>
            </span>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-gray-light-1 text-[15px] font-medium">
              To
            </span>
            <span className="text-[15px] flex items-center gap-1 font-medium text-white">
              <img
                className="w-[16px] h-[16px] rounded-full"
                src={assets?.dest_asset_icon}
              />
              {assets?.dest_asset_symbol}
              <span
                className="text-[#CCCCCC]"
                style={{ textTransform: "none" }}
              >
                (
                {dest_chain
                  ? dest_chain.charAt(0).toUpperCase() +
                    dest_chain.slice(1).toLowerCase()
                  : ""}
                )
              </span>
            </span>
          </div>
        </div>
      )}

      {onboardingType == OnBoardingType.LEND && (
        <div className="flex flex-col gap-2 my-[24px]">
          <div className="flex items-center gap-2">
            <span className="text-gray-light-1 text-[15px] font-medium">
              Send
            </span>
            <span className="text-[15px] font-medium flex items-center gap-1 text-white uppercase">
              <img
                className="w-[16px] h-[16px] rounded-full"
                src={assets?.source_asset_icon}
              />{" "}
              {assets?.source_asset_symbol}
              <span className="text-[#CCCCCC] capitalize">
                (
                {source_chain
                  ? source_chain.charAt(0).toUpperCase() + source_chain.slice(1)
                  : ""}
                )
              </span>
              <span className="text-[#CCCCCC]"></span>
            </span>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-gray-light-1 text-[15px] font-medium">
              Lend
            </span>
            <span className="text-[15px] flex items-center gap-1 font-medium text-white">
              <img
                className="w-[16px] h-[16px] rounded-full"
                src={assets?.dest_asset_icon}
              />
              {assets?.dest_asset_symbol}
              <span className="text-[#CCCCCC]">
                (
                {dest_chain
                  ? dest_chain.charAt(0).toUpperCase() + dest_chain.slice(1)
                  : ""}
                )
              </span>
            </span>
          </div>
          <div className="flex items-center">
            <span className="text-gray-light-1 text-[15px] font-medium">
              Reference APR
            </span>
            <span className="ml-2 text-white text-[15px] font-medium">10%</span>
          </div>
        </div>
      )}

      {/* Onboarding link */}
      <div className="bottom-[32px] flex absolute flex-col gap-2">
        <p className="text-[15px] text-white">Onboarding link</p>
        <div className="flex gap-x-2 bg-gray-dark-3 justify-between items-center px-4 h-[44px] w-[242px] border border-gray-light-1 rounded-[40px]">
          <p className="text-[13px] whitespace-nowrap overflow-hidden text-ellipsis text-white">
            {onBoardingLink}
          </p>
          <button
            onClick={() => {
              handleCopyLink();
            }}
            className="outline-none "
          >
            <PiCopyLight size={20} color="#B882FF" />
          </button>
        </div>
        {linkIsCopied && (
          <div className="bg-gray-dark-3 absolute right-0 bottom-10 flex justify-center items-center rounded w-[66px] h-[32px] border border-gray-light-3">
            <p className="text-[13px]">Copied</p>
          </div>
        )}
      </div>
      <AddBonusDialog />
    </div>
  );
};

export default OnBoardingCard;
