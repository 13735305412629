import { configureStore } from "@reduxjs/toolkit";

import dialogSlice from "./dialog";
import onBoardingSlice from "./onboarding/onboarding";
import userSlice from "./user";
import appSlice from "./app";

export const store = configureStore({
  reducer: {
    dialogState: dialogSlice,
    onBoardingState: onBoardingSlice,
    userState: userSlice,
    appState: appSlice,
  },
  middleware: (getDefaultMiddleware) =>  getDefaultMiddleware({ serializableCheck: false }),
    // getDefaultMiddleware({
    //   serializableCheck: {
    //     ignoredActions: [
    //       "onBoarding/updateCustomization",
    //       "onBoarding/updateBannerFile",
    //     ],
    //     ignoredPaths: [
    //       "onBoardingState.customization.file",
    //       "onBoardingState.customization.bannerFile",
    //       "payload.File"
    //     ],
    //   },
    // }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
