import React, { useState } from 'react';
import { IoMdArrowDropup, IoMdArrowDropdown } from 'react-icons/io';
import { useAppDispatch } from '../../state/hooks';
import { setMemberRole } from '../../state/app';
import { useMutation } from '@tanstack/react-query';
import { removeMember, updateMemberRole } from '../../api/project/route';
import { useQueryClient } from '@tanstack/react-query';
import { ToastContainer, toast } from 'react-toastify';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  Menu,
  MenuButton,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import { errorMessages } from '../../utils/helper';
import { useAccount } from 'wagmi';
import { useAppSelector } from '../../state/hooks';

const Dropdown = ({
  options,
  newWalet,
  memberId,
  defaultValue,
  memberAddress,
  disabled = false,
}: {
  options: Array<{
    role: string;
    roleId: number;
  }>;
  defaultValue: {
    role: string;
    roleId: number;
  };
  newWalet: boolean;
  memberId?: string;
  memberAddress?: string;
  disabled?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(defaultValue);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const filteredOptions = options.filter(
    (option) => option.role !== selected.role
  );
  const protocolId = useAppSelector((state) => state.appState.protocolId);
  const account = useAccount();

  const updateMember = useMutation({
    mutationFn: async () => {
      return await updateMemberRole(selected.roleId, memberId);
    },
    onSuccess: () => {
      setIsOpen(false);
      queryClient.invalidateQueries({
        queryKey: ['getAllMembersList', protocolId, account.address],
      });
    },
    onError(error, variables, context) {
      errorMessages(error.message);
    },
  });

  const removeTeamMember = useMutation({
    mutationFn: async () => {
      return await removeMember(memberId);
    },
    onSuccess: (data) => {
      setIsModalOpen(false);
      queryClient.invalidateQueries({
        queryKey: ['getAllMembersList', protocolId, account.address],
      });
    },
    onError: (error, variables, context) => {
      errorMessages(error.message);
    },
  });



  const handleSelectedRole = (option: { role: string; roleId: number }) => {
    if (newWalet) {
      setSelected(option);
      setIsOpen(false);
      dispatch(setMemberRole(option));
    }
    if (!newWalet) {
      if (option.roleId <= 2) {
        setSelected(option);
        updateMember.mutate();
      }
      if (option.roleId == 3) {
        setIsModalOpen(true);
      }
    }
  };

  return (
    <div className="relative  inline-block w-[120px]">
      {/* Dropdown Container */}
      <div
        className={`border  border-gray-light-1 bg-[#121212] text-white overflow-hidden ${
          isOpen ? 'rounded-t-[20px] border-b-0' : 'rounded-[20px]'
        }`}
      >
        {/* Header Button */}
        <button
          onClick={() => setIsOpen(!isOpen)}
          className=" flex px-[24px] w-full hover:bg-[#2A2A2A]   h-[46px] justify-between disabled:opacity-50 disabled:text-white/50 disabled:bg-black/50 items-center text-[14px]  py-[10px] "
        >
          <p className="text-[14px] text-center w-full text-white">
            {' '}
            {selected.role}{' '}
          </p>
          {isOpen ? (
            <IoMdArrowDropup className="w-[20px] h-[20px]" color="#FFF" />
          ) : (
            <IoMdArrowDropdown className="w-[20px] h-[20px]" color="#FFF" />
          )}
        </button>
      </div>

      {/* Options */}
      <div
        className={`absolute  z-10  w-full bg-[#121212] border border-t-0 border-gray-400 rounded-b-[20px] overflow-hidden ${
          isOpen ? 'max-h-[300px] opacity-100  ' : 'max-h-0 opacity-0'
        }`}
      >
        {isOpen &&
          filteredOptions.map((option, index: number) => (
            <button
              key={index}
              onClick={() => {
                handleSelectedRole(option);
              }}
              className={`w-full py-[10px] px-[24px] block hover:bg-[#2A2A2A]  text-[14px] transition-colors text-left ${
                option.role === 'Remove' ? 'text-[#FF4471]' : 'text-gray-300'
              }  `}
            >
              {option.role}
            </button>
          ))}
      </div>

      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="relative z-50"
      >
        <DialogBackdrop className="fixed inset-0 bg-black/80" />
        <div className="fixed inset-0 flex items-center justify-center w-screen p-4">
          <DialogPanel className="w-[532px] h-[296px] p-6 border-white/40 rounded-2xl bg-[#222] border">
            <DialogTitle className="flex items-center justify-end font-bold">
              <button
                onClick={() => setIsModalOpen(false)}
                className="text-white"
              >
                <img
                  src="/assets/close.svg"
                  alt="close"
                  className="h-[24px] w-[24px]"
                />
              </button>
            </DialogTitle>
            <div className="flex flex-col items-center justify-center gap-5 mt-4 px-18">
              <p className="text-[20px] font-semibold text-center ">
                Are you sure you want to remove{' '}
                {`${memberAddress?.slice(0, 6)}...${memberAddress?.slice(-4)}`}{' '}
                from the team?
              </p>
              <p className="text-base text-center text-gray-light-1">
                This team member won’t be able to create new Onboarding flows
                unless invited again.
              </p>
              <div className="flex justify-center gap-6">
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="text-base text-primary-default"
                >
                  Don’t remove
                </button>
                <button
                  className="bg-primary-default w-[200px] font-medium text-white rounded-full h-[40px]"
                  onClick={() => {
                    removeTeamMember.mutate();
                  }}
                >
                  Remove team Member
                </button>
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  );
};

export default Dropdown;
