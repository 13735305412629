import { LendDestAsset, LendDestination, SwapAssets, SwapPairs } from "./../../api/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OnBoardingType, THEME } from "../types";
import { handleCreateOnBoardingRecord } from "./createOnboardingRecord";
import {
  Asset,
  BasePair,
  BridgeResponseInterface,
  Destination,
  LendAssets,
  listOfChain,
  Pairs,
  ProtocolAction,
  SourceAssets,
  StakeAssets,
  StakeDestination,
  StakeSource,
  SwapResponseInterface,
  Token,
} from "../../api/types";
import { handleFetchOnboardingAppDetails } from "../action";

export type OnBoardingRecord = {
  protocolId: number;
  type: string;
  bridgeTypesourceChain: BridgeResponseInterface | null;
  bridgeTypesourceAsset: Asset | null;
  bridgeTypedestAsset: Token | null;
  bridgeTypedestChain: Destination | null;
  swapTypesourceChain: SwapResponseInterface | null;
  swapTypesourceAsset: BasePair | null;
  swapTypedestAsset: SwapAssets | null;
  swapTypedestChain: SwapPairs | null;
  stakeOnboarding: {
    onboarding_network: string;
    onboarding_network_icon: string;
    onboarding_network_chainID: string;
    onboarding_network_slug: string;
    assets: StakeAssets[];
  } | null;
  stakeOnboardingAssets: StakeAssets | null;
  stakeTypeSourceChain: StakeSource | null;
  stakeTypeSourceAssets: SourceAssets | null;
  stakeTypeDestChain: StakeDestination | null;
  lendOnboardingAssets: {
    onboarding_network: string;
    onboarding_network_icon: string;
    onboarding_network_chainID: string;
    onboarding_network_slug: string;
    assets: LendAssets[];
  } | null;
  lendTypeOnboardingSourceAssets: LendAssets | null;
  lendTypeDestChain: LendDestination | null;
  lendTypeDestAsset: LendDestAsset | null;
};

export type AbstractionFlow = {
  statusCode?: number;
  data: AbstractionData;
};

export type AbstractionData = {
  id: string;
  transaction_layout: {
    layout: {
      bridge: {
        from: {
          name: string;
          asset: string;
          blockchain: string;
          assetLogo: string;
        };
        to: {
          asset: string;
          name: string;
          blockchain: string;
          assetLogo: string;
        };
        protocols: Protocol[];
      }[];
      swap: {
        from: {
          name: string;
          asset: string;
          blockchain: string;
          assetLogo: string;
        };
        to: {
          asset: string;
          blockchain: string;
          assetLogo: string;
        };
        protocols: Protocol[];
      }[];
      stake: {
        from: {
          name: string;
          asset: string;
          blockchain: string;
          assetLogo: string;
        };
        to: {
          asset: string;
          blockchain: string;
          assetLogo: string;
        };
        protocols: Protocol[];
      }[];
    };
  };
};

export type Protocol = {
  name: string;
  logo: string;
  id: number;
};

export type Customization = {
  onboardingId?: string;
  file?: File;
  theme: THEME;
  pageColor: string;
  accentColor: string;
  title: string;
  subtitle: string;
  website: string;
  bannerFile?: File;
  name: string;
};
interface onBoardingState {
  projectName: string;
  onBoardingId: number;
  onBoardingType: string;
  onBoardingRecord: OnBoardingRecord;
  abstractionFlow: AbstractionFlow | null;
  customization: Customization;
  selectedSourceChain: listOfChain | undefined;
  isEditingOnboardingApp: boolean;
  protocolActions: ProtocolAction[];
}

const initialState: onBoardingState = {
  projectName: "",
  onBoardingId: 0,
  onBoardingType: OnBoardingType.STAKE,
  onBoardingRecord: {
    type: OnBoardingType.STAKE,
    protocolId: 0,
    bridgeTypedestAsset: null,
    bridgeTypesourceAsset: null,
    bridgeTypedestChain: null,
    bridgeTypesourceChain: null,
    swapTypesourceChain: null,
    swapTypesourceAsset: null,
    swapTypedestAsset: null,
    swapTypedestChain: null,
    stakeOnboarding: null,
    stakeOnboardingAssets: null,
    stakeTypeSourceChain: null,
    stakeTypeDestChain: null,
    stakeTypeSourceAssets: null,
    lendOnboardingAssets: null,
    lendTypeOnboardingSourceAssets: null,
    lendTypeDestChain: null,
    lendTypeDestAsset: null,
  },
  abstractionFlow: null,
  customization: {
    file: undefined,
    onboardingId: "",
    theme: THEME.DARK,
    pageColor: "#666666",
    title: "",
    subtitle: "",
    website: "",
    accentColor: "#9264F8",
    bannerFile: undefined,
    name: "",
  },
  selectedSourceChain: undefined,
  isEditingOnboardingApp: false,
  protocolActions: [],
};

export const onBoardingSlice = createSlice({
  name: "onBoarding",
  initialState,
  reducers: {
    updateProjectName: (state, action: PayloadAction<string>) => {
      state.projectName = action.payload;
    },
    updateOnBoardingType: (state, action: PayloadAction<string>) => {
      state.onBoardingType = action.payload;
      state.onBoardingRecord.type = action.payload;
    },
    updateOnBoardingRecord: (
      state,
      action: PayloadAction<OnBoardingRecord>
    ) => {
      state.onBoardingRecord = action.payload;
    },
    updateIsEditingOnboardingApp: (state, action: PayloadAction<boolean>) => {
      state.isEditingOnboardingApp = action.payload;
    },
    updateOnboardingId: (state, action: PayloadAction<number>) => {
      state.onBoardingId = action.payload;
    },
    updateAppName: (state, action: PayloadAction<string>) => {
      state.customization.name = action.payload;
    },
    updateBridgeTypeSourceChain: (
      state,
      action: PayloadAction<BridgeResponseInterface>
    ) => {
      state.onBoardingRecord.bridgeTypesourceChain = action.payload;
    },
    updateBridgeTypeSourceAsset: (
      state,
      action: PayloadAction<Asset | null>
    ) => {
      state.onBoardingRecord.bridgeTypesourceAsset = action.payload;
    },
    updateBridgeTypeDestChain: (
      state,
      action: PayloadAction<Destination | null>
    ) => {
      state.onBoardingRecord.bridgeTypedestChain = action.payload;
    },
    updateBridgeTypeDestAsset: (state, action: PayloadAction<Token | null>) => {
      state.onBoardingRecord.bridgeTypedestAsset = action.payload;
    },
    updateSwapTypeSourceChain: (
      state,
      action: PayloadAction<SwapResponseInterface>
    ) => {
      state.onBoardingRecord.swapTypesourceChain = action.payload;
    },
    updateSwapTypeSourceAsset: (
      state,
      action: PayloadAction<BasePair | null>
    ) => {
      state.onBoardingRecord.swapTypesourceAsset = action.payload;
    },
    updateSwapTypeDestChain: (
      state,
      action: PayloadAction<SwapPairs | null>
    ) => {
      state.onBoardingRecord.swapTypedestChain = action.payload;
    },
    updateSwapTypeDestAsset: (state, action: PayloadAction<SwapAssets | null>) => {
      state.onBoardingRecord.swapTypedestAsset = action.payload;
    },
    updateStakeOnboarding: (
      state,
      action: PayloadAction<{
        onboarding_network: string;
        onboarding_network_icon: string;
        onboarding_network_chainID: string;
        onboarding_network_slug: string;
        assets: StakeAssets[];
      } | null>
    ) => {
      state.onBoardingRecord.stakeOnboarding = action.payload;
    },
    updateStakeOnboardingAssets: (
      state,
      action: PayloadAction<StakeAssets | null>
    ) => {
      state.onBoardingRecord.stakeOnboardingAssets = action.payload;
    },
    updateStakeTypeSourceChain: (
      state,
      action: PayloadAction<StakeSource | null>
    ) => {
      state.onBoardingRecord.stakeTypeSourceChain = action.payload;
    },
    updateStakeTypeDestChain: (
      state,
      action: PayloadAction<StakeDestination | null>
    ) => {
      state.onBoardingRecord.stakeTypeDestChain = action.payload;
    },
    updateStakeTypeSourceAssets: (
      state,
      action: PayloadAction<SourceAssets | null>
    ) => {
      state.onBoardingRecord.stakeTypeSourceAssets = action.payload;
    },
    updateLendOnboardingAssets: (
      state,
      action: PayloadAction<{
        onboarding_network: string;
        onboarding_network_icon: string;
        onboarding_network_chainID: string;
        onboarding_network_slug: string;
        assets: LendAssets[];
      } | null>
    ) => {
      state.onBoardingRecord.lendOnboardingAssets = action.payload;
    },
    updateLendTypeOnboardingSourceAssets: (
      state,
      action: PayloadAction<LendAssets | null>
    ) => {
      state.onBoardingRecord.lendTypeOnboardingSourceAssets = action.payload;
    },
    updateLendTypeDestinationChain: (
      state,
      action: PayloadAction<LendDestination | null>
    ) => {
      state.onBoardingRecord.lendTypeDestChain = action.payload;
    },
    updateLendTypeDestinationAsset: (
      state,
      action: PayloadAction<LendDestAsset | null>
    ) => {
      state.onBoardingRecord.lendTypeDestAsset = action.payload;
    },
    updateTheme: (state, action: PayloadAction<THEME>) => {
      state.customization.theme = action.payload;
    },
    updatePageColor: (state, action: PayloadAction<string>) => {
      state.customization.pageColor = action.payload;
    },
    updateAccentColor: (state, action: PayloadAction<string>) => {
      state.customization.accentColor = action.payload;
    },
    updateTitle: (state, action: PayloadAction<string>) => {
      state.customization.title = action.payload;
    },
    updateSubtitle: (state, action: PayloadAction<string>) => {
      state.customization.subtitle = action.payload;
    },
    updateLogoFile: (state, action: PayloadAction<File | undefined>) => {
      state.customization.file = action.payload;
    },
    updateWebsite: (state, action: PayloadAction<string>) => {
      state.customization.website = action.payload;
    },
    updateCustomization: (state, action: PayloadAction<Customization>) => {
      state.customization = action.payload;
    },
    updateBannerFile: (state, action: PayloadAction<File | undefined>) => {
      state.customization.bannerFile = action.payload;
    },
    updateSelectedSourceChain: (
      state,
      action: PayloadAction<listOfChain | undefined>
    ) => {
      state.selectedSourceChain = action.payload;
    },
    updateProtocolActions: (state, action: PayloadAction<ProtocolAction[]>) => {
      state.protocolActions = action.payload;
    },
    clearOnBoardingRecord: (state) => {
      state.onBoardingRecord = {
        type: OnBoardingType.STAKE,
        protocolId: 0,
        bridgeTypedestChain: null,
        bridgeTypesourceAsset: null,
        bridgeTypesourceChain: null,
        bridgeTypedestAsset: null,
        swapTypesourceChain: null,
        swapTypesourceAsset: null,
        swapTypedestAsset: null,
        swapTypedestChain: null,
        stakeOnboarding: null,
        stakeOnboardingAssets: null,
        stakeTypeSourceChain: null,
        stakeTypeDestChain: null,
        stakeTypeSourceAssets: null,
        lendOnboardingAssets: null,
        lendTypeOnboardingSourceAssets: null,
        lendTypeDestChain: null,
        lendTypeDestAsset: null,
      };
      // state.projectName = "";
      state.abstractionFlow = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      handleCreateOnBoardingRecord.fulfilled,
      (state, action: PayloadAction<AbstractionFlow>) => {
        state.abstractionFlow = action.payload;
        state.customization.onboardingId = action.payload.data.id;
      }
    );
    builder.addCase(
      handleFetchOnboardingAppDetails.fulfilled,
      (state, action) => {
        state.customization = {
          file: undefined,
          pageColor: action.payload.data.page_color,
          accentColor: action.payload.data.accent_color,
          onboardingId: String(action.payload.data.id),
          theme: action.payload.data.theme,
          title: action.payload.data.title,
          subtitle: action.payload.data.subtitle,
          website: action.payload.data.website,
          bannerFile: undefined,
          name: action.payload.data.name,
        };
      }
    );
  },
});

export const {
  updateProjectName,
  updateOnBoardingType,
  updateOnBoardingRecord,
  updateBridgeTypeDestChain,
  updateBridgeTypeDestAsset,
  updateBridgeTypeSourceAsset,
  updateBridgeTypeSourceChain,
  updateSwapTypeDestChain,
  updateSwapTypeDestAsset,
  updateSwapTypeSourceAsset,
  updateSwapTypeSourceChain,
  updateCustomization,
  updateSelectedSourceChain,
  updateBannerFile,
  updateIsEditingOnboardingApp,
  updateOnboardingId,
  clearOnBoardingRecord,
  updateAppName,
  updateAccentColor,
  updatePageColor,
  updateSubtitle,
  updateTheme,
  updateTitle,
  updateLogoFile,
  updateWebsite,
  updateProtocolActions,
  updateStakeOnboarding,
  updateStakeOnboardingAssets,
  updateStakeTypeSourceChain,
  updateStakeTypeDestChain,
  updateStakeTypeSourceAssets,
  updateLendOnboardingAssets,
  updateLendTypeOnboardingSourceAssets,
  updateLendTypeDestinationChain,
  updateLendTypeDestinationAsset,
} = onBoardingSlice.actions;
export default onBoardingSlice.reducer;
