import axios from "axios";
import { BACKEND_URL } from "../env";
import { signUpResponse, walletType } from "../types";

export const signUpWithPassword = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  try {
    return await axios.post("", {
      email,
      auth_provider: "password",
      password,
    });
  } catch (error) {
    console.error(error, "signUp");
  }
};

export const signUpWithGoogle = async ({
  email,
  provider_id,
}: {
  email: string;
  provider_id: string;
}) => {
  try {
    return await axios.post("", {
      email,
      auth_provider: "google",
      provider_id,
    });
  } catch (error) {
    console.error(error, "signUp");
  }
};

export const signUpWithGithub = async ({
  email,
  provider_id,
}: {
  email: string;
  provider_id: string;
}) => {
  try {
    return await axios.post("", {
      email,
      auth_provider: "github",
      provider_id,
    });
  } catch (error) {
    console.error(error, "signUp");
  }
};

export const signUpWithWallet = async ({
  accountAddress,
  walletType,
}: {
  accountAddress: string;
  walletType: walletType;
}): Promise<signUpResponse> => {
  try {
    const response = await axios.post(`${BACKEND_URL}api/clients/sign-up`, {
      auth_provider: walletType,
      provider_id: accountAddress,
    });
    return {
      statusCode: response.data.statusCode,
      data: response.data.data,
    };
  } catch (error) {
    console.error(error, "signInWithWallet");
    return {
      statusCode: 400,
      data: {
        token: "",
      },
      error: error,
    };
  }
};
