import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  Button,
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
} from "@headlessui/react";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useAccount, useDisconnect } from "wagmi";
import { useAppSelector, useAppDispatch } from "../state/hooks";
import { toggleLoginOrSignupDialog } from "../state/dialog";
import { clearOnBoardingRecord } from "../state/onboarding/onboarding";
import { useFetchUserDetails } from "../hooks/projects";
import { RoleId } from "../api/types";
import { IoMdClose } from "react-icons/io";

const UserMenu = ({ isModularPage }: { isModularPage?: boolean }) => {
  const navigate = useNavigate();
  const account = useAccount();
  const dispatch = useAppDispatch();
  const userAddress = useAppSelector((state) => state.userState.userAddress);
  const protocolId = useAppSelector(
    (state) => state.appState.protocolId
  );
  let [claimedProject, setClaimedProject] = useState<number>();
  const [showOptions, setShowOptions] = useState(false);
  const { disconnect, connectors } = useDisconnect();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const userDetails = useFetchUserDetails({ userAddress: account.address });
  useMemo(() => {
    if (
      userDetails.data &&
      userDetails.data.__company__?.__projects__?.length > 0
    ) {
      const id =
        userDetails.data.__company__.__projects__[
          userDetails.data.__company__.__projects__.length - 1
        ]?.id;
      setClaimedProject(id);
    }
  }, [userDetails.data, protocolId]);

  const handleClickOutside = (event: MouseEvent) => {
    const dropdown = dropdownRef.current;
    const button = buttonRef.current;
    if (
      dropdown &&
      button &&
      !dropdown.contains(event.target as Node) &&
      !button.contains(event.target as Node)
    ) {
      setShowOptions(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDisconnect = () => {
    if (isModularPage) {
      connectors.map((connector) => disconnect({ connector }));
      setShowOptions(false);
      navigate(window.location.pathname, { replace: true });
    } else {
      connectors.map((connector) => disconnect({ connector }));
      navigate("/");
      dispatch(clearOnBoardingRecord());
      setShowOptions(false);
      //window.location.reload();
      navigate(window.location.pathname, { replace: true });
    }
    //sessionStorage.clear()
  };

  let [isOpen, setIsOpen] = useState(false);

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  const handleManageTeam = () => {
    if (
      (protocolId == claimedProject &&
        userDetails.data?.__roles__[0]?.id == RoleId.ADMIN) ||
      userDetails.data?.is_super_admin
    ) {
      navigate("/ManageTeam");
      setShowOptions(false);
    } else {
      setIsOpen(true);
      setShowOptions(false);
    }
  };

  const CannotAccessPageModal = () => {
    return (
      <>
        <Dialog
          open={isOpen}
          as="div"
          className="relative z-10 focus:outline-none"
          onClose={close}
        >
          <DialogBackdrop className="fixed inset-0 bg-black/70" />
          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex items-center justify-center min-h-full">
              <DialogPanel
                transition
                className="w-[283px] h-[174px] rounded-xl p-[32px] bg-dark-gray-2 border border-gray-light-3 duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
              >
                {/* <DialogTitle
                  as="div"
                  className="flex justify-end w-full mb-[16px]"
                >
                </DialogTitle> */}
                {/* <p className="mt-2 text-sm/6 text-white/50">
                  Your payment has been successfully submitted. We’ve sent you
                  an email with all of the details of your order.
                </p>
                <div className="mt-4">
                  <Button
                    className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700"
                    onClick={close}
                  >
                    Got it, thanks!
                  </Button>
                </div> */}
                <div className="flex flex-col items-center justify-center w-full">
                  <p className="16px leading-[21px] text-center mb-[32px]">
                    You are not an Admin of this project space
                  </p>
                  <Button
                    className="bg-primary-default w-[98px] text-white h-[38px] hover:bg-primary-hover active:bg-primary-active rounded-[40px]"
                    onClick={close}
                  >
                    OK
                  </Button>
                </div>
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      </>
    );
  };
  return (
    <div className="relative">
      <div
        ref={buttonRef}
        onClick={() => {
          if (!account?.isConnected) {
            dispatch(toggleLoginOrSignupDialog(true));
            setShowOptions(false);
          } else {
            setShowOptions((prev) => !prev);
          }
        }}
        className={`w-40 h-[46px] flex items-center gap-x-1 py-2.5 border border-gray-light-1 hover:bg-tertiary-hover active:bg-tertiary-active rounded-[32px] bg-dark-lg cursor-pointer
          ${
            !account?.isConnected
              ? "justify-center"
              : "justify-between pl-6 pr-4"
          }`}
      >
        <p className="text-[14px] leading-[24px]">
          {!account.isConnected
            ? "Connect wallet"
            : `${account?.address?.slice(0, 6)}...${account?.address?.slice(
                -4
              )}`}
        </p>
        {account?.isConnected && !showOptions ? (
          <IoMdArrowDropdown className="w-4 h-4 text-white" />
        ) : (
          account?.isConnected && (
            <IoMdArrowDropup className="w-4 h-4 text-white" />
          )
        )}
      </div>
      {showOptions && (
        <div
          ref={dropdownRef}
          className="absolute w-[157.36px] border-[#CCCCCC] rounded-[16px] border justify-center mt-[4px]"
        >
          <div
            onClick={() => {
              handleManageTeam();
            }}
            className={`py-[11px] ${
              !protocolId && "hidden"
            } cursor-pointer bg-[#0E0E0E] rounded-t-[16px] px-4 hover:bg-[#404040]`}
          >
            <p>Manage team</p>
          </div>

          <div
            onClick={handleDisconnect}
            className={`py-[11px] cursor-pointer px-4 bg-[#0E0E0E] ${
              !protocolId && "rounded-t-[16px]"
            } rounded-b-[16px] hover:bg-[#404040]`}
          >
            <p>Logout</p>
          </div>
        </div>
      )}
      {/* {account.isConnected ? (
        <Menu>
          {account.address && (
            <MenuButton className=" inline-flex w-[157px] data-[open]:rounded-[2px] data-[open]:border-b-0 z-10 pb-2 rounded-[40px] h-[44px] items-center gap-2 border py-1.5 px-3 text-sm/6 text-white shadow-inner shadow-white/10 outline-none">
              {account?.address.slice(0, 6)}...{account.address.slice(-4)}
              <IoMdArrowDropdown className="text-white" />
            </MenuButton>
          )}

          <MenuItems
            transition
            anchor="bottom"
            className=" w-[var(--button-width)] border-t-0 rounded-[2px] -mt-1 border border-white bg-gray-dark-3 p-1 text-sm/6 text-white transition duration-100 ease-out focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
          >
            <MenuItem>
              <button
                onClick={() => {
                  navigate("/ManageTeam");
                }}
                className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
              >
                Manage Team
              </button>
            </MenuItem>
            <MenuItem>
              <button
                onClick={handleDisconnect}
                className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
              >
                Logout
              </button>
            </MenuItem>
          </MenuItems>
        </Menu>
      ) : (
        <button
          onClick={() => dispatch(toggleLoginOrSignupDialog(true))}
          className="text-white border border-white  h-[46px] outline-none font-medium text-base rounded-[32px] w-[157px]"
        >
          Connect Wallet
        </button>
      )} */}
      <CannotAccessPageModal />
    </div>
  );
};

export default UserMenu;
