import { jwtDecode } from "jwt-decode";
import * as jose from "jose";
import { secp256k1 } from "ethereum-cryptography/secp256k1";
import { keccak256 } from "ethereum-cryptography/keccak";
import { toHex, utf8ToBytes } from "ethereum-cryptography/utils";
import * as secrets from "secrets.js-grempe";
// import { openDB } from 'idb';
import { Buffer } from "buffer";
import sss from "shamirs-secret-sharing";
import * as CryptoJS from "crypto-js";
import axios from "axios";
import { toast } from "react-toastify";
import { AbstractionData } from "../state/onboarding/onboarding";
import {
  Asset,
  BasePair,
  BridgeResponseInterface,
  LendResponseInterface,
  SwapResponseInterface,
} from "../api/types";
(window as any).Buffer = Buffer;

const secret = "4865616c2324476f7425546865572472";
const initVector = "6824616c74682553";
export const validateIDToken = async (idToken: string) => {
  try {
    // Decode the ID token
    const decodedToken: any = jwtDecode(idToken);

    // Verify the token's signature and expiration
    const { iss, aud, exp } = decodedToken;
    const isExpired = exp < Date.now() / 1000;

    if (isExpired) throw new Error("Token expired");

    // Fetch the public key from the identity provider
    const JWKS = await jose.createRemoteJWKSet(
      new URL(`https://www.googleapis.com/oauth2/v3/certs`)
    );

    // Validate the token signature
    const { payload } = await jose.jwtVerify(idToken, JWKS, {
      issuer: iss,
      audience: aud,
    });

    // ID Token is valid, proceed with the flow
    console.log("Token is valid:", payload);
    return payload;
  } catch (error) {
    console.error("Invalid ID token:", error);
  }
};

const secretKey = "my-secret-key-12345";

export const handleEncrypt = (share: string) => {
  const plaintext = share;

  const ciphertext = CryptoJS.AES.encrypt(plaintext, secretKey).toString();
  console.log("Encrypted text:", ciphertext);
  return ciphertext;
};

export const handleDecrypt = (share_enc: string) => {
  const bytes = CryptoJS.AES.decrypt(share_enc, secretKey);
  const plainText = bytes.toString(CryptoJS.enc.Utf8);
  return plainText;
};

export const storeDeviceShare = async (share: string) => {
  const encryptedShare = handleEncrypt(share);
  localStorage.setItem("share", encryptedShare);
  return;
  // try {
  //   const db = await openDB('device-share', 1, {
  //     upgrade(db) {
  //       db.createObjectStore('shares', {
  //         keyPath: 'id',
  //         autoIncrement: true,
  //       });
  //     },
  //   });
  //   // Store device share
  //   return await db
  //     .put('shares', { id: 'deviceShare', share: encryptedShare })
  //     .then((res) => {
  //       console.log('========== stored device share ========');
  //       console.log(res);
  //       return true;
  //     })
  //     .catch((err) => {
  //       console.log('====== Failed to store device share =======');
  //       console.log(err);
  //       return false;
  //     });
  // } catch (err) {
  //   console.log('====== Failed to store device share =======');
  //   console.log(err);
  //   return false;
  // }
};

export const getDeviceShare = async () => {
  const share = localStorage.getItem("share");

  if (share) {
    const decryptedShare = handleDecrypt(share);

    return decryptedShare as string;
  } else return null;
  // const db = await initDB();
  // const storedShare = await db.get('shares', 'deviceShare');
  // if (storedShare) {
  //   const decryptedShare = handleDecrypt(storedShare.share);
  //   return decryptedShare as string;
  // } else return null;
};

// const initDB = async () => {
//   const db = await openDB('device-share', 1, {
//     upgrade(db) {
//       if (!db.objectStoreNames.contains('shares')) {
//         db.createObjectStore('shares', { keyPath: 'id' });
//       }
//     },
//   });
//   return db;
// };
export const saveShareToProvider = async (share: string, sub: string) => {
  const encryptedShare = handleEncrypt(share);
  return await axios
    .post("http://localhost:3001/save-share", {
      sub,
      share_e: encryptedShare,
    })
    .then((res) => {
      if (res.data.status === "success") {
        return true;
      } else return false;
    })
    .catch((err) => {
      console.log("========= Failed to save share to provider ========");
      console.log(err);
      return false;
    });
};

export const generateWallet = async (sub: string) => {
  // check if db has a share
  const storedShare = await getDeviceShare();
  if (storedShare) {
    // get user wallet address
    console.log("Stored share:", storedShare);
    // reconstruct the private key
    const shares = [storedShare];
    const priv = combineSecret(shares);
    const publicKeyUncomp = secp256k1.getPublicKey(priv, false);
    const address = keccak256(publicKeyUncomp.slice(1)).slice(-20);
    const addressHex = "0x" + toHex(address);
    return {
      privateKey: priv,
      shares: shares,
      address: addressHex,
    };
  }
  // return;
  console.log("========= Generating wallet ==========");
  const keyShare = keccak256(utf8ToBytes(sub));
  console.log("========= key sub ==========");
  // console.log(keyShare);
  console.log(Buffer);

  const randomKeyShare = secp256k1.utils.randomPrivateKey();
  const combinedKey = Buffer.from(keyShare).map(
    (byte, index) => byte ^ randomKeyShare[index]
  );
  const privateKeyHex = toHex(combinedKey);
  const publicKeyComp = secp256k1.getPublicKey(combinedKey, true);
  // Derive the uncompressed public key from the private key
  const publicKeyUncomp = secp256k1.getPublicKey(combinedKey, false);
  const address = keccak256(publicKeyUncomp.slice(1)).slice(-20);
  const addressHex = "0x" + toHex(address);
  console.log("========= private key before sharing ==========");
  console.log(privateKeyHex);
  try {
    const shares = splitSecret(
      privateKeyHex,
      parseInt(process.env.REACT_APP_NUMBER_OF_KEYS_SHARES!!),
      parseInt(process.env.REACT_APP_KEY_THRESHOLD!!)
    );

    const [deviceShare] = await Promise.all([
      // saveShareToProvider(shares[0], sub),
      storeDeviceShare(shares[0]),
    ]);
    // console.log('========= provider share saved ==========');
    // console.log(providerShare);
    console.log("========= device share saved ==========");
    console.log(deviceShare);

    console.log("========== shares ==========");
    console.log(shares);
    const priv = combineSecret(shares);
    console.log("========= private key after combining ==========");
    console.log(priv);
    return {
      privateKey: privateKeyHex,
      shares: shares,
      address: addressHex,
    };
  } catch (err) {
    console.log(err);
  }
};

const combineSecret = (sharesHex: any) => {
  // Convert hex shares back to Buffers
  const shares = sharesHex.map((shareHex: any) => Buffer.from(shareHex, "hex"));

  // Combine the shares to reconstruct the secret
  const secretBuffer = sss.combine(shares);

  // Convert the Buffer back to a hex string
  return secretBuffer.toString("hex");
};

const splitSecret = (
  secretHex: string,
  totalShares: number,
  threshold: number
) => {
  // Convert the secret to a Buffer
  const secretBuffer = Buffer.from(secretHex, "hex");

  // Split the secret into shares
  const shares = sss.split(secretBuffer as any, {
    shares: totalShares,
    threshold: threshold,
  });

  // Convert shares to hex for storage or transmission
  const sharesHex = shares.map((share: any) => share.toString("hex"));
  return sharesHex;
};

export function getValidObjects(obj?: Object) {
  if (!obj) {
    return [];
  }
  return Object.entries(obj).filter(
    ([, value]) =>
      typeof value === "object" &&
      value !== null &&
      Object.keys(value).length > 0
  );
}

export function trimSpaces(sentence: string): string {
  return sentence.replace(/^\s+|\s+$/g, "");
}

export const convertHexToRgba = (hex: string) => {
  const sanitizedHex = hex.replace(/^#/, "");
  if (sanitizedHex.length === 6 && /^[0-9A-Fa-f]{6}$/.test(sanitizedHex)) {
    const r = parseInt(sanitizedHex.substring(0, 2), 16);
    const g = parseInt(sanitizedHex.substring(2, 4), 16);
    const b = parseInt(sanitizedHex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, 0.15)`;
  }
  return "Invalid hex value";
};

const getMonth = (index: number): string => {
  const MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return MONTHS[index];
};

export const getCreatedDate = (time: string) => {
  const month = new Date(time).getMonth();
  const date = new Date(time).getUTCDate();
  const year = new Date(time).getUTCFullYear();

  return `${date} ${getMonth(month)} ${year}`;
};

export const errorMessages = (errorMsg: string) => {
  toast.error(`${errorMsg}`, {
    position: "top-right",
    className:
      "w-[340px] bg-[#222] border border-[#666] text-[14px] rounded-[4px] py-[24px] px-[16px] text-white",
    progressClassName: "bg-[#FF4471]",
  });
};

export enum SessionKeys {
  ProtocolId = "ProtocolId",
  ProtocolName = "ProtocolName",
  isClaimedProtocol = "isClaimedProtocol",
  isEditingOnboardingApp = "isEditingOnboardingApp",
  onBoardingAppId = "onBoardingAppId",
  OnBoardingType = "onBoardingtype",
}

export const setSession = ({
  protocolId,
  protocolName,
  isClaimedProtocol,
}: {
  protocolId: string;
  protocolName: string;
  isClaimedProtocol: boolean;
}) => {
  sessionStorage.setItem(SessionKeys.ProtocolId, protocolId);
  sessionStorage.setItem(SessionKeys.ProtocolName, protocolName);
  sessionStorage.setItem(
    SessionKeys.isClaimedProtocol,
    JSON.stringify(isClaimedProtocol)
  );
};

export const getSession = (): {
  protocolId: number;
  protocolName: string;
  isClaimedProtocol: boolean;
} => {
  return {
    protocolId: Number(sessionStorage.getItem(SessionKeys.ProtocolId)),
    protocolName: String(sessionStorage.getItem(SessionKeys.ProtocolName)),
    isClaimedProtocol: JSON.parse(
      sessionStorage.getItem(SessionKeys.isClaimedProtocol) ?? "false"
    ),
  };
};

export function parseEscapedJSONString(
  inputString: string
): AbstractionData | null {
  try {
    //  const cleanedString = inputString
    //   .replace(/^"|"$/g, '') // Remove the outermost quotes
    //   .replace(/\\"/g, '"')  // Remove escaped quotes
    //   .replace(/\\\\/g, '\\')
    //   .replace(/\\\\/g, '\\')
    //   .replace(/\\\\/g, '\\')
    //   ; // Convert escaped backslashes

    // Step 1: Replace escaped quotes with actual quotes
    const cleanedString = JSON.parse(inputString)
      .replace(/\\"/g, '"')
      .replace(/\\\\/g, "\\");

    // Step 2: Remove outer quotes if present
    const properlyFormattedString =
      cleanedString.startsWith('"') && cleanedString.endsWith('"')
        ? cleanedString.slice(1, -1)
        : cleanedString;

    // Step 3: Parse the JSON string into an object
    console.log(JSON.parse(properlyFormattedString), "cleanedString");
    return JSON.parse(properlyFormattedString);
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return null; // Return null if parsing fails
  }
}

export const protocolResponse = (protocolId: number, data: any) => {
  if (protocolId == 2) {
    return data.bridge as BridgeResponseInterface[];
  }
  if (protocolId == 3) {
    return data.swap as SwapResponseInterface[];
  }
};

export function isBridgeResponse(
  value: unknown
): value is BridgeResponseInterface {
  return typeof value === "object" && value !== null && "bridge" in value;
}

export function isSwapResponse(value: unknown): value is SwapResponseInterface {
  return typeof value === "object" && value !== null && "swap" in value;
}

export function isStakeResponse(
  value: unknown
): value is SwapResponseInterface {
  return typeof value === "object" && value !== null && "stake" in value;
}

export function isLendResponse(value: unknown): value is LendResponseInterface {
  return typeof value === "object" && value !== null && "lend" in value;
}

export function isSwapSourceAsset(value: unknown): value is BasePair {
  return typeof value === "object" && value !== null && "pairs" in value;
}

export function isBridgeSourceAsset(value: unknown): value is Asset {
  return typeof value === "object" && value !== null && "destinations" in value;
}

