import { http, createConfig } from "wagmi";
import { mainnet, sepolia, polygon, base } from "wagmi/chains";

import { injected, metaMask } from "wagmi/connectors";

const phantomConnectorInjected = injected({
  target: "phantom",
});

const backPackConnectorInjected = injected({
  target: "backpack",
});

const connectors = [
  phantomConnectorInjected,
  backPackConnectorInjected,
  ...(typeof window !== "undefined" ? [metaMask()] : []), // Conditionally add metaMask
];

export const config = createConfig({
  chains: [mainnet, sepolia, polygon, base],
  connectors,
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http(),
    [base.id]: http(),
    [polygon.id]: http(),
  },
});
