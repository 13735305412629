import React, { useState, useMemo, useEffect } from 'react';
import OnBoardingHeader from '../../components/OnBoardingHeader';

import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { FaSortUp } from 'react-icons/fa';
import clsx from 'clsx';
import ManageTeamSelectBox from '../../components/ManageTeamSelectBox';
import { useMutation } from '@tanstack/react-query';
import { inviteMembers } from '../../api/project/route';
import { useAppSelector } from '../../state/hooks';

import {
  useFetchUserDetails,
  useGetAllMembersList,
} from '../../hooks/projects';
import ArrowBack from '../../assets/svg/Arrow_back.svg';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { useAccount } from 'wagmi';
import { RoleId } from '../../api/types';
import { useNavigate } from 'react-router-dom';
import { errorMessages, SessionKeys } from '../../utils/helper';

// Define the structure of a team member
interface TeamMember {
  address: string;
  role: string;
}

const options = [
  { role: 'Member', roleId: 2 },
  { role: 'Admin', roleId: 1 },
  { role: 'Remove', roleId: 3 },
];

const ManageTeam = () => {
  const protocolId = useAppSelector((state) => state.appState.protocolId);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const memberRole = useAppSelector((state) => state.appState.memberRole);
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([
    { address: '0x1998.....5a8f8b', role: 'Admin' },
    { address: '0x1998.....5a8f8b', role: 'Member' },
  ]);

  const [newWallet, setNewWallet] = useState<string>('');
  const [newRole, setNewRole] = useState<string>('Member');
  const account = useAccount();
  const userDetails = useFetchUserDetails({ userAddress: account.address });

  const [inviteNewMemberEnabled, setInviteNewMemberEnabled] = useState(false);
  const [selectedMember, setSelectedMember] = useState<TeamMember | null>(null); // Properly typing selectedMember

  // const handleAddMember = () => {
  //   if (newWallet) {
  //     setTeamMembers([...teamMembers, { address: newWallet, role: newRole }]);
  //     setNewWallet(""); // Clear input after adding
  //   }
  // };

  const membersList = useGetAllMembersList({
    isSuperAdmin: userDetails.data?.is_super_admin ?? false,
    projectId: protocolId,
    userAddress: account.address,
  });

  const notifyAddMember = () => {
    toast.success(`${memberRole.role} wallet has been added`, {
      position: 'top-right',
      className:
        'w-[340px] bg-[#222] border border-[#666] text-[14px] rounded-[4px] py-[24px] px-[16px] text-white',
      progressClassName: 'bg-[#2ab576]',
    });
  };

  // const openModal = (member: TeamMember) => {
  //   setSelectedMember(member);
  //   setIsModalOpen(true);
  // };

  // const handleRemoveMember = (email: string) => {
  //   setTeamMembers(teamMembers.filter((member) => member.address !== email));
  //   setIsModalOpen(false);
  // };

  useMemo(() => {
    if (newWallet) {
      setInviteNewMemberEnabled(true);
    } else {
      setInviteNewMemberEnabled(false);
    }
  }, [newWallet]);

  const addTeamMember = useMutation({
    mutationFn: () => {
      return inviteMembers(newWallet, memberRole.roleId, protocolId);
    },
    onSuccess: (data) => {
      notifyAddMember();
      queryClient.invalidateQueries({
        queryKey: ['getAllMembersList', protocolId, account.address],
      });
      setNewWallet('');
    },
    onError(error, variables, context) {
      errorMessages(error.message);
    },
  });

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['getAllMembersList', protocolId, account.address],
    });
  }, [userDetails.data]);

  // const SelectBox = ({ teamMember }: { teamMember: TeamMember }) => {
  //   const [selected, setSelected] = useState(options[1]);
  //   return (
  //     <Listbox value={selected} onChange={setSelected}>
  //       <ListboxButton
  //         className={clsx(
  //           "relative block w-[105px] h-[40px] border border-white rounded-[40px]  bg-gray-dark-3/20 py-1.5 pr-8 pl-3 text-left text-sm/6 text-white",
  //           "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
  //         )}
  //       >
  //         {selected}
  //         <IoMdArrowDropdown
  //           className="absolute pointer-events-none group top-3 right-4 size-4 "
  //           aria-hidden="true"
  //           color="#FFF"
  //         />
  //       </ListboxButton>
  //       <ListboxOptions
  //         anchor="selection"
  //         transition
  //         className={clsx(
  //           "w-[var(--button-width)] mt-1 rounded-[22px] border border-white py-2 bg-black last:text-red-700 focus:outline-none",
  //           "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0"
  //         )}
  //       >
  //         {/* {options.map((option, index) => (
  //           <ListboxOption
  //             key={index}
  //             value={option}
  //             onClick={() => {

  //             }}
  //             className={`group flex cursor-default ${index == 2 ? "text-[#FF4471]": "text-white"}  items-center gap-2  rounded-lg py-1.5 px-3 select-none data-[focus]:bg-primary-default`}
  //           >
  //             <div className=" text-sm/6">{option}</div>
  //           </ListboxOption>
  //         ))} */}
  //         <ListboxOption
  //           value="Member"
  //           onClick={() => {
  //             setSelected("Member");
  //           }}
  //           className={`group flex justify-between cursor-default  text-white items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10`}
  //         >
  //           <p className=" text-sm/6">Member</p>
  //           <FaSortUp className="p-0 mt-2" color="#FFF" />
  //         </ListboxOption>
  //         <ListboxOption
  //           value="Admin"
  //           onClick={() => {
  //             setSelected("Admin");
  //           }}
  //           className={`group flex cursor-default  text-white items-center gap-2  rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10`}
  //         >
  //           <p className=" text-sm/6">Admin</p>
  //         </ListboxOption>
  //         <ListboxOption
  //           value="Remove"
  //           onClick={() => {
  //             openModal(teamMember);
  //           }}
  //           className={`group flex cursor-default   items-center gap-2  rounded-lg py-1.5 px-3 select-none hover:bg-white/10 data-[focus]:bg-white/10`}
  //         >
  //           <p className="text-[#FF4471] text-sm/6">Remove</p>
  //         </ListboxOption>
  //       </ListboxOptions>
  //     </Listbox>
  //   );
  // };

  return (
    <div>
      <OnBoardingHeader showProjectName={true} />
      {userDetails.data?.is_super_admin ||
      (protocolId == userDetails.data?.__company__.__projects__[0]?.id &&
        userDetails.data?.__roles__[0]?.id == RoleId.ADMIN) ? (
        <div
          className="py-2 bg-black bg-no-repeat bg-cover px-25"
          style={{
            backgroundImage: 'url(/assets/manage.svg)',
          }}
        >
          <div className="flex items-center gap-x-[24px]">
            <div
              onClick={() => {
                navigate(-1);
              }}
              className="px-[7px] py-[7px] border bg-[#222222] border-[#666666] cursor-pointer rounded-lg"
            >
              <img src={ArrowBack} />
            </div>
            <p className="text-[32px] font-bold">Manage Team</p>
          </div>

          <div className="flex justify-center">
            <div className="flex w-[60%] flex-col  justify-center gap-6 p-6 ">
              {/* Team members list */}
              {/* Admin */}
              {/* <div className="flex items-center justify-between w-full mb-4">
              <p className="text-base text-white"> 0x1998.....5a8f8b</p>
              <div>
                <Menu>
                  <MenuButton
                    disabled
                    className="inline-flex w-[105px] h-[40px] items-center gap-2 disabled:rounded-[40px]  py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 disabled:border disabled:border-white/50 focus:outline-none data-[focus]:outline-1 disabled:text-white/50 disabled:bg-black/50"
                  >
                    Admin
                    <IoMdArrowDropdown color="text-white/50" />
                  </MenuButton>
                </Menu>
              </div>
            </div> */}

              {/* {teamMembers.map((member, index) => (
              <div
                key={index}
                className="relative flex items-start justify-between w-full mb-2"
              >
                <p className="text-base text-white">{member.address}</p>

                <ManageTeamSelectBox
                  defaultValue={member.role}
                  options={options}
                  newWalet={true}
                  memberId={member.address}
                />
              </div>
            ))} */}
              {membersList.data?.data &&
                membersList.data.data.map((member, index) => (
                  <div
                    key={index}
                    className="relative flex items-center  justify-between w-full mb-2"
                  >
                    <p className="text-base text-white">
                      {`${member.provider_id?.slice(
                        0,
                        6
                      )}...${member.provider_id?.slice(-4)}`}{' '}
                    </p>
                    <ManageTeamSelectBox
                      defaultValue={{
                        role: member.__roles__[0]?.title ?? 'S-Admin',
                        roleId: member.__roles__[0]?.id,
                      }}
                      options={options}
                      newWalet={false}
                      memberId={member.id}
                      memberAddress={member.provider_id}
                      disabled={member.__roles__[0].id == 2}
                    />
                  </div>
                ))}
              <div className="w-full border-b border-gray-light-3"></div>
              {/* Input and Invite Button Section */}
              <div className="flex items-start justify-start gap-2 mb-4">
                <input
                  type="text"
                  value={newWallet}
                  onChange={(e) => setNewWallet(e.target.value)}
                  placeholder="Insert team member’s wallet address"
                  className="px-4 py-2 w-[307px] border placeholder-[#666666] border-white h-[46px] text-white outline-none bg-gray-dark-3/20 rounded-full"
                />
                {/* <div className="relative flex">
              <div></div>
            </div> */}
                <ManageTeamSelectBox
                  defaultValue={{ role: 'Member', roleId: 2 }}
                  options={[
                    { role: 'Member', roleId: 2 },
                    { role: 'Admin', roleId: 1 },
                  ]}
                  newWalet={true}
                />
                <button
                  disabled={!inviteNewMemberEnabled}
                  className={`text-white bg-primary-default disabled:opacity-70  w-[182px] h-[46px] rounded-full`}
                  onClick={() => {
                    addTeamMember.mutate();
                  }}
                >
                  Add team member
                </button>
              </div>

              {/* Role Descriptions */}
              <div className="mt-4 text-sm text-white">
                <p className="mb-2">
                  <span className="font-semibold">Admin:</span> Can create
                  onboarding apps; can add or remove team members
                </p>
                <p>
                  <span className="font-semibold">Member:</span> Can create
                  onboarding apps
                </p>
              </div>
            </div>
          </div>

          {/* Remove Member Modal */}
        </div>
      ) : (
        <div className="text-[24px] text-center w-full font-bod text-white">
          You don't have access to this page
        </div>
      )}
    </div>
  );
};

export default ManageTeam;
