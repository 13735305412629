import React, { createContext, useContext, useState, ReactNode } from "react";

interface OnboardingContextType {
  minAmount: string;
  setMinAmount: (value: string) => void;
  maxAmount: string;
  setMaxAmount: (value: string) => void;
  redirectUrl: string;
  setRedirectUrl: (value: string) => void;
}

const OnboardingContext = createContext<OnboardingContextType | undefined>(undefined);

export const useOnboarding = () => {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error("useOnboarding must be used within OnboardingProvider");
  }
  return context;
};

export const OnboardingProvider = ({ children }: { children: ReactNode }) => {
  const [minAmount, setMinAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");

  return (
    <OnboardingContext.Provider
      value={{ minAmount, setMinAmount, maxAmount, setMaxAmount, redirectUrl, setRedirectUrl }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};
