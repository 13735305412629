import { fetchOnboardingAppDetails } from "../api/project/route";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const handleFetchOnboardingAppDetails = createAsyncThunk(
  "user/fetchOnboardingApp",
  async ({ onBoardingId }: { onBoardingId: number }) => {
    const response = await fetchOnboardingAppDetails(onBoardingId);
    return response;
  }
);
