import React from "react";
import { useFetchAllOnboardingAppByProtocolId } from "../../hooks/projects";
import { useAppSelector } from "../../state/hooks";
import OnBoardingHeader from "../../components/OnBoardingHeader";
import { useNavigate } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";
import OnBoardingCard from "../../components/OnBoardingCard";
import { useFetchUserDetails } from "../../hooks/projects";
import { useAccount } from "wagmi";
import ArchiveCard from "../../components/ArchiveCard";

const Archive = () => {
  const protocolId = useAppSelector((state) => state.appState.protocolId);
  const onBoardingApps = useFetchAllOnboardingAppByProtocolId(protocolId);
  const navigate = useNavigate();
  const account = useAccount();
  const userDetails = useFetchUserDetails({ userAddress: account.address });
  return (
    <div className="flex flex-col min-h-screen">
      <OnBoardingHeader showProjectName={true} />
      <div
        className="flex flex-col flex-grow py-2 bg-black bg-no-repeat px-25"
        style={{
          backgroundImage: "url(/assets/manage.svg)",
        }}
      >
        {protocolId == userDetails.data?.__company__.__projects__[0]?.id ||
          (userDetails.data?.is_super_admin && (
            <div className="flex flex-col gap-8 py-10 mb-12">
              <div className="flex items-center gap-8">
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <GoArrowLeft className="text-white w-[40px] h-[40px] p-2 border border-gray-light-3 rounded-md" />
                </button>
                <h1 className="text-[32px] text-white font-bold">
                  Archived apps
                </h1>
              </div>
              <div className="flex gap-3">
                {onBoardingApps.data &&
                  onBoardingApps.data
                    ?.filter(
                      (onBoardingApp) => onBoardingApp.is_archived == true
                    )
                    .sort((a, b) => b.id - a.id)
                    .map((onBoardingApp) => (
                      <ArchiveCard
                        isActive={onBoardingApp.is_active}
                        title={onBoardingApp.title}
                        sourceAsset={onBoardingApp.source_asset}
                        source_chain={onBoardingApp.source_chain}
                        dest_chain={onBoardingApp.dest_chain}
                        onBoardingLink={onBoardingApp.generate_link}
                        onBoardingId={onBoardingApp.id}
                        projectId={onBoardingApp.project_id}
                        isArchived={true}
                        onboardingType={onBoardingApp.onboarding_type}
                        destAsset={onBoardingApp.dest_asset}
                        assets={onBoardingApp.asset_images}
                        name={onBoardingApp.name}
                        createdTime={onBoardingApp.__project__.created_at}
                        isProjectOwner={true}
                      />
                    ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Archive;
