import React, { useEffect, useMemo, useRef, useState } from "react";
import OnboardingConfig from "./OnboardingConfig";
import CustomizedStake from "./CustomizedStake";
import AbstractionFlow from "./AbstractionFlow";
import { GoArrowLeft } from "react-icons/go";
import { PiPencilSimpleBold } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import ActivateOnboardingApp from "./ActivateConfiguration";
import UserMenu from "../../components/UserButton";
import CheckCircle from "../../../public/assets/check_cricle.svg";
import {
  createOnboardingApp,
  addCustomization,
  updateAbstraction,
} from "../../api/project/route";
import { useAppSelector, useAppDispatch } from "../../state/hooks";
import { handleCreateOnBoardingRecord } from "../../state/onboarding/createOnboardingRecord";
import { OnBoardingType } from "../../state/types";
import { getSession, SessionKeys } from "../../utils/helper";
import {
  updateAppName,
  updateIsEditingOnboardingApp,
  updateOnboardingId,
  updateOnBoardingType,
  updateProjectName,
} from "../../state/onboarding/onboarding";
import { updateIsProjectClaim, updateProtocolId } from "../../state/app";
import { useQueryClient } from "@tanstack/react-query";
import { handleFetchOnboardingAppDetails } from "../../state/action";
import { isEqual } from "lodash";
import {
  Button,
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
} from "@headlessui/react";
import { IoMdClose } from "react-icons/io";

const CreateOnboardingApp: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isEditing, setIsEditing] = useState(false);
  const [appName, setAppName] = useState("Staking app 1");
  const [showLogoWarning, setShowLogoWarning] = useState(false);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [openSaveChangesDialog, setOpenSaveChangesDialog] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onBoardingRecord = useAppSelector(
    (state) => state.onBoardingState.onBoardingRecord
  );

  const abstractionFlow = useAppSelector(
    (state) => state.onBoardingState.abstractionFlow
  );

  const {
    isEditingOnboardingApp,
    onBoardingId,
    customization,
    selectedOnboardingType,
    onBoardingAppDetails,
    previousCustomization,
    protocolId,
    onBoardingType,
    bridgeTypeSourceAsset,
    bridgeTypeDestinationAsset,
    bridgeTypeDestinationChain,
    bridgeTypeSourceChain,
    swapTypeSourceAsset,
    swapTypeDestinationAsset,
    swapTypeSourceChain,
    swapTypeDestinationChain,
    stakeOnboardingChain,
    stakeOnboardingAsset,
    stakeTypeDestinationAsset,
    lendOnboardingChain,
    lendTypeOnboardingSourceAssets,
    lendTypeDestinationChain,
    lendTypeDestinationAsset,
  } = useAppSelector((state) => ({
    isEditingOnboardingApp: state.onBoardingState.isEditingOnboardingApp,
    onBoardingId: state.onBoardingState.onBoardingId,
    customization: state.onBoardingState.customization,
    selectedOnboardingType: state.onBoardingState.onBoardingType,
    onBoardingAppDetails: state.appState.onBoardingAppDetails,
    protocolId: state.appState.protocolId,
    onBoardingType: state.onBoardingState.onBoardingType,
    bridgeTypeSourceAsset:
      state.onBoardingState.onBoardingRecord.bridgeTypesourceAsset,
    bridgeTypeSourceChain:
      state.onBoardingState.onBoardingRecord.bridgeTypesourceChain,
    bridgeTypeDestinationChain:
      state.onBoardingState.onBoardingRecord.bridgeTypedestChain,
    bridgeTypeDestinationAsset:
      state.onBoardingState.onBoardingRecord.bridgeTypedestAsset,
    swapTypeSourceChain:
      state.onBoardingState.onBoardingRecord.swapTypesourceChain,
    swapTypeSourceAsset:
      state.onBoardingState.onBoardingRecord.swapTypesourceAsset,
    swapTypeDestinationChain:
      state.onBoardingState.onBoardingRecord.swapTypedestChain,
    swapTypeDestinationAsset:
      state.onBoardingState.onBoardingRecord.swapTypedestAsset,
    stakeOnboardingChain:
      state.onBoardingState.onBoardingRecord.stakeOnboarding,
    stakeOnboardingAsset:
      state.onBoardingState.onBoardingRecord.stakeOnboardingAssets,
    stakeTypeDestinationChain:
      state.onBoardingState.onBoardingRecord.stakeTypeDestChain,
    stakeTypeDestinationAsset:
      state.onBoardingState.onBoardingRecord.stakeTypeSourceAssets,
    lendOnboardingChain:
      state.onBoardingState.onBoardingRecord.lendOnboardingAssets,
    lendTypeOnboardingSourceAssets:
      state.onBoardingState.onBoardingRecord.lendTypeOnboardingSourceAssets,
    lendTypeDestinationChain:
      state.onBoardingState.onBoardingRecord.lendTypeDestChain,
    lendTypeDestinationAsset:
      state.onBoardingState.onBoardingRecord.lendTypeDestAsset,
    previousCustomization: state.appState.previousCustomization,
  }));
  const queryClient = useQueryClient();

  const spanRef = useRef<HTMLSpanElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // const handleCreateOnboarding = async () => {
  //   try {
  //     const result = await createOnboardingApp(
  //       selectedType,
  //       minAmount,
  //       maxAmount,
  //       redirectUrl
  //     );
  //     console.log("Onboarding created:", result);
  //     setAbstractionData(result.data)
  //     // navigate("/Abstraction", { state: { data: result.data } }); // Pass data to AbstractionFlow
  //   } catch (e) {
  //     console.error("Error creating onboarding:", e);
  //   }
  // };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAppName(e.target.value);
    dispatch(updateAppName(e.target.value));
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    // Compare previous and current props

    const hasChanged = isEqual(previousCustomization, customization);
    setIsSaveButtonEnabled(hasChanged);

    // Update the ref to the current data
    //previousDataRef.current = data;
  }, [customization]);

  const goToNextStep = async () => {
    if (currentStep == 3 && !customization?.file) {
      setShowLogoWarning(true);
      return;
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll
    });

    if (currentStep == 1) {
      dispatch(
        handleCreateOnBoardingRecord({
          appName,
          protocolId,
          type: onBoardingType,
          sourceAsset:
            bridgeTypeSourceAsset?.address ??
            swapTypeSourceAsset?.source_asset_address ??
            stakeOnboardingAsset?.onboarding_address ??
            lendTypeOnboardingSourceAssets?.source_address,
          sourceChain:
            bridgeTypeSourceChain?.name ??
            swapTypeSourceChain?.source_asset_network ??
            stakeOnboardingChain?.onboarding_network ??
            lendOnboardingChain?.onboarding_network,
          destAsset:
            bridgeTypeDestinationAsset?.address ??
            swapTypeDestinationAsset?.destination_asset_address ??
            stakeTypeDestinationAsset?.destinations.dest_address ??
            lendTypeDestinationAsset?.dest_address,
          destChain:
            bridgeTypeDestinationChain?.chain ??
            swapTypeDestinationChain?.destination_asset_network ??
            stakeTypeDestinationAsset?.destinations.dest_network ??
            lendTypeDestinationChain?.dest_network,
          sourceChainId:
            bridgeTypeSourceChain?.chainID ??
            swapTypeSourceChain?.chain ??
            stakeOnboardingChain?.onboarding_network_chainID ??
            lendOnboardingChain?.onboarding_network_chainID,
          destinationChainId:
            bridgeTypeDestinationChain?.chainID ??
            swapTypeDestinationChain?.destination_network_id ??
            stakeTypeDestinationAsset?.destinations.dest_network_chain ??
            lendTypeDestinationChain?.dest_network_chain,
        })
      );
    }

    if (currentStep == 3) {
      try {
        await addCustomization(customization);
      } catch (error) {
        console.error(error);
        return;
      }
    }

    if (currentStep == 2) {
      if (abstractionFlow) {
        try {
          await updateAbstraction(
            abstractionFlow?.data.id,
            abstractionFlow.data
          );
        } catch (error) {
          console.error(error);
          return;
        }
      }
    }

    if (currentStep < 4) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handleSaveChanges = async () => {
    try {
      await addCustomization(customization);
      queryClient.invalidateQueries({
        queryKey: ["getAllOnboardingFromProjectId", protocolId],
      });
      setOpenSaveChangesDialog(false);
      navigate("/");
      sessionStorage.setItem(
        SessionKeys.isEditingOnboardingApp,
        JSON.stringify(false)
      );
      sessionStorage.setItem(SessionKeys.onBoardingAppId, String(0));
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const goToPreviousStep = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll
    });

    if (currentStep > 1) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  useMemo(() => {
    if (selectedOnboardingType == OnBoardingType.SWAP) {
      setAppName("Swapping App ");
      dispatch(updateAppName("Swapping App "));
    }
    if (selectedOnboardingType == OnBoardingType.STAKE) {
      setAppName("Staking App ");
      dispatch(updateAppName("Staking App "));
    }
    if (selectedOnboardingType == OnBoardingType.LEND) {
      setAppName("Lending App ");
      dispatch(updateAppName("Lending App "));
    }
  }, [selectedOnboardingType]);

  useMemo(() => {
    if (isEditingOnboardingApp) {
      setCurrentStep(3);
    }
  }, [isEditingOnboardingApp]);

  useEffect(() => {
    if (spanRef.current && inputRef.current) {
      spanRef.current.innerText = appName || " ";
      inputRef.current.style.width = `${spanRef.current.offsetWidth}px`;
    }
    dispatch(updateAppName(appName));
  }, [appName]);

  useEffect(() => {
    if (customization?.file) {
      setShowLogoWarning(false);
    }
  }, [customization?.file]);

  useEffect(() => {
    const { protocolId, protocolName, isClaimedProtocol } = getSession();
    dispatch(updateProtocolId(protocolId));
    dispatch(updateProjectName(protocolName));
    dispatch(updateIsProjectClaim(isClaimedProtocol));
    const isEditing = JSON.parse(
      sessionStorage.getItem(SessionKeys.isEditingOnboardingApp) ?? "false"
    );
    dispatch(updateIsEditingOnboardingApp(isEditing));
    const onBoardingId = sessionStorage.getItem(SessionKeys.onBoardingAppId);
    dispatch(updateOnboardingId(Number(onBoardingId)));
    const onBoardingType = sessionStorage.getItem(SessionKeys.OnBoardingType);
    dispatch(dispatch(updateOnBoardingType(String(onBoardingType))));
    if (Boolean(isEditing)) {
      dispatch(
        handleFetchOnboardingAppDetails({ onBoardingId: Number(onBoardingId) })
      );
    }
  }, []);

  useMemo(() => {
    if (isEditingOnboardingApp) {
      setAppName(onBoardingAppDetails?.data.name || "");
      setCurrentStep(3);
      dispatch(
        updateOnBoardingType(
          onBoardingAppDetails?.data.onboarding_type ?? OnBoardingType.STAKE
        )
      );
    }
  }, [isEditingOnboardingApp, onBoardingAppDetails?.data]);

  const disabled =
    isEditingOnboardingApp && (currentStep == 1 || currentStep == 2);

  const handleBackButton = async () => {
    try {
      if (!isEditingOnboardingApp) {
        if (currentStep == 2) {
          if (abstractionFlow) {
            await updateAbstraction(
              abstractionFlow?.data.id,
              abstractionFlow.data
            );
          }
          await addCustomization(customization);
        }
        if (currentStep == 3) {
          await addCustomization(customization);
        }
        navigate(-1);
      }
      if (isEditingOnboardingApp) {
        if (!isSaveButtonEnabled) {
          setOpenSaveChangesDialog(true);
        } else navigate(-1);
      }
    } catch (error) {}
  };

  const closeSaveChangesDialog = () => {
    setOpenSaveChangesDialog(false);
  };

  const discardChanges = () => {
    navigate(-1);
    setOpenSaveChangesDialog(false);
  };
  const SaveChangesDialog = () => {
    return (
      <Dialog
        open={openSaveChangesDialog}
        as="div"
        className="relative z-10 focus:outline-none"
        onClose={closeSaveChangesDialog}
      >
        <DialogBackdrop className="fixed inset-0 bg-black/70" />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4">
            <DialogPanel
              transition
              className="w-[532px] h-[282px] pt-[28px] pb-[52px] rounded-xl bg-[#222] border border-gray-light-3 flex flex-col items-center data-[closed]:opacity-0"
            >
              <DialogTitle
                as="div"
                className="flex items-end justify-end w-full px-[36px]"
              >
                <IoMdClose
                  onClick={() => {
                    closeSaveChangesDialog();
                  }}
                  className="text-end"
                  size={32}
                  color="#fff"
                />
              </DialogTitle>

              <div className="flex mt-[24px] items-center flex-col">
                <h1 className="text-[20px] font-semibold text-white leading-[24px]">
                  {" "}
                  You have unsaved changes
                </h1>
                <p className="mt-[16px] mb-[32px] text-[16px] font-normal leading-[21px] text-center text-gray-light-1 ">
                  {" "}
                  You have made changes to the app. Click “Save” to save changes
                  or “Discard” to discard them
                </p>
                <div className="flex items-center gap-[24px]">
                  <button
                    onClick={() => {
                      discardChanges();
                    }}
                    className="w-[163px] h-[40px] text-[15px] font-medium text-center bg-white rounded-[32px] text-black disabled:opacity-50"
                  >
                    Discard changes
                  </button>
                  <button
                    onClick={() => {
                      handleSaveChanges();
                    }}
                    className="w-[150px] h-[40px] text-[15px] font-medium text-center bg-primary-default rounded-[32px] text-white active:bg-primary-active hover:bg-primary-hover disabled:opacity-50"
                  >
                    Save and exit
                  </button>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    );
  };

  return (
    <div className="flex flex-col min-h-screen">
      <header className="flex items-center justify-between w-full">
        <a href="https://www.townesquare.xyz/">
          <img
            src="/assets/ts_logo.svg"
            className="h-[30px] w-[160px]"
            alt="Townesquare Logo"
          />
        </a>
        <UserMenu />
      </header>
      <div className="relative flex flex-col flex-grow px-20 mt-18">
        <div className="flex items-center justify-between px-10 mb-12">
          <div className="flex items-center gap-8">
            <button
              onClick={() => {
                handleBackButton();
              }}
            >
              <GoArrowLeft className="text-white w-[40px] h-[40px] p-2 border border-gray-light-3 rounded-md" />
            </button>

            {/* Conditional rendering for editing */}

            {isEditing ? (
              <div
                onClick={handleEditClick}
                className="inline-flex items-center "
              >
                <input
                  type="text"
                  value={appName}
                  onChange={handleNameChange}
                  onBlur={handleBlur}
                  autoFocus
                  size={appName.length || 1}
                  className="text-[25px] font-semibold text-white bg-transparent  outline-none"
                />

                <img
                  src={"/assets/check_cricle.svg"}
                  className="w-[32px] h-[32px] cursor-pointer"
                />
              </div>
            ) : (
              <div
                onClick={handleEditClick}
                className="flex items-center gap-x-2"
              >
                <p className="text-[25px] font-semibold text-white cursor-pointer">
                  {appName}
                </p>
                <img
                  src={"/assets/pencil.svg"}
                  className="w-[32px] h-[32px] cursor-pointer"
                />
              </div>
            )}
          </div>
          {isEditingOnboardingApp && (
            <div className="flex justify-center gap-4">
              <button
                className="h-[46px] w-[180px] text-white outline-none rounded-[32px] disabled:opacity-40 hover:bg-primary-hover active:bg-primary-active font-medium bg-primary-default"
                onClick={handleSaveChanges}
                disabled={isSaveButtonEnabled}
              >
                Save Changes
              </button>
            </div>
          )}
        </div>

        <div className="flex flex-col items-center justify-center h-full px-20 mb-2 ">
          {/* Top Tab Navigation */}
          <div className="flex items-center w-full h-full py-5 justify-evenly">
            <div className="flex flex-col items-center justify-center gap-4">
              <button
                onClick={() => setCurrentStep(1)}
                className={`rounded-full w-[44px] h-[44px] outline-none cursor-pointer ${
                  currentStep === 1
                    ? "bg-white"
                    : currentStep > 1
                    ? "bg-primary-default"
                    : "bg-white/40"
                } flex justify-center items-center`}
              >
                {currentStep > 1 ? <img src="/assets/check.svg" /> : "1"}
              </button>
              <p
                className={`text-base font-semibold ${
                  currentStep === 1 ? "text-white" : "text-white/40"
                }`}
              >
                Onboarding app configuration
              </p>
            </div>

            <div className="flex flex-col items-center justify-center gap-4">
              <button
                onClick={() => setCurrentStep(2)}
                className={`rounded-full w-[44px] h-[44px] cursor-pointer ${
                  currentStep === 2
                    ? "bg-white"
                    : currentStep > 2
                    ? "bg-primary-default"
                    : "bg-white/40"
                }  flex justify-center items-center`}
              >
                {currentStep > 2 ? <img src="/assets/check.svg" /> : "2"}
              </button>
              <p
                className={`text-base font-semibold ${
                  currentStep === 2 ? "text-white" : "text-white/40"
                }`}
              >
                Abstraction flow
              </p>
            </div>

            <div className="flex flex-col items-center justify-center gap-4">
              <button
                onClick={() => setCurrentStep(3)}
                className={`rounded-full w-[44px] h-[44px] cursor-pointer ${
                  currentStep === 3
                    ? "bg-white"
                    : currentStep > 3
                    ? "bg-primary-default"
                    : "bg-white/40"
                }  flex justify-center items-center`}
              >
                {currentStep > 3 ? <img src="/assets/check.svg" /> : "3"}
              </button>
              <p
                className={`text-base font-semibold ${
                  currentStep === 3 ? "text-white" : "text-white/40"
                }`}
              >
                Customization
              </p>
            </div>

            <div className="flex flex-col items-center justify-center gap-4">
              <button
                onClick={() => setCurrentStep(4)}
                className={`rounded-full w-[44px] h-[44px] cursor-pointer ${
                  currentStep === 4
                    ? "bg-white"
                    : currentStep > 4
                    ? "bg-primary-default"
                    : "bg-white/40"
                }  flex justify-center items-center`}
              >
                {currentStep > 4 ? <img src="/assets/check.svg" /> : "4"}
              </button>
              <p
                className={`text-base font-semibold ${
                  currentStep === 4 ? "text-white" : "text-white/40"
                }`}
              >
                App status and link
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center flex-grow">
          {/* Form Content */}
          {currentStep === 1 && <OnboardingConfig />}
          {currentStep === 3 && (
            <CustomizedStake
              showLogoWarning={showLogoWarning}
              appName={appName}
            />
          )}
          {currentStep === 2 && <AbstractionFlow />}
          {currentStep === 4 && <ActivateOnboardingApp />}
          {/* Navigation Buttons */}
        </div>
        {currentStep <= 3 && !isEditingOnboardingApp && (
          <div className="flex justify-center gap-4 my-14">
            {currentStep > 1 && (
              <button
                className="h-[46px] w-[180px] bg-white hover:bg-link-secondary-hover active:bg-link-secondary-active text-black rounded-[32px]  font-medium"
                onClick={goToPreviousStep}
              >
                Back
              </button>
            )}
            <button
              disabled={disabled}
              className="h-[46px] w-[180px] text-white rounded-[32px] disabled:opacity-40 hover:bg-primary-hover active:bg-primary-active font-medium bg-primary-default"
              onClick={goToNextStep}
            >
              {currentStep < 4 ? "Continue" : "Preview"}{" "}
            </button>
          </div>
        )}
      </div>
      <SaveChangesDialog />
    </div>
  );
};

export default CreateOnboardingApp;
