import React, { useState, useRef, useMemo, useEffect } from 'react';
import UploadIcon from '../../assets/svg/UploadSimple.svg';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import PreviewComponent from './Previewtab';
import { ImCheckmark } from 'react-icons/im';
import { Switch } from '@headlessui/react';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  updateAccentColor,
  updateCustomization,
  updateLogoFile,
  updatePageColor,
  updateSubtitle,
  updateTheme,
  updateTitle,
  updateWebsite,
} from '../../state/onboarding/onboarding';
import { THEME } from '../../state/types';
import { OnBoardingType } from '../../state/types';
import CustomizedEdit from '../../components/CustomizedEdit';
import { fetchOnboardingAppFromProjectId } from '../../api/project/route';

// React Hook to calculate luminance of a color
// React Hook to calculate luminance of a color
const calculateLuminance = (r: number, g: number, b: number): number => {
  const [R, G, B] = [r, g, b].map((channel) => {
    channel /= 255;
    return channel <= 0.03928
      ? channel / 12.92
      : Math.pow((channel + 0.055) / 1.055, 2.4);
  });
  return 0.2126 * R + 0.7152 * G + 0.0722 * B;
};
// Convert Hex to RGB
const hexToRgb = (hex: string): [number, number, number] => {
  const sanitizedHex = hex?.replace('#', '');
  const bigint = parseInt(sanitizedHex, 16);

  return sanitizedHex?.length === 6
    ? [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255]
    : [0, 0, 0]; // Default if hex is invalid
};

const getFontColor = (backgroundColor: string): string => {
  const [r, g, b] = hexToRgb(backgroundColor);
  const luminance = calculateLuminance(r, g, b);

  // Return black or white based on luminance threshold
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

// React Hook to calculate luminance of a color

const CustomizedStake = ({
  appName,
  showLogoWarning,
}: {
  showLogoWarning?: boolean;
  appName: string;
}) => {
  const [theme, setTheme] = useState<THEME>(THEME.DARK);
  const [pageBgColor, setPageBgColor] = useState('#666666');
  const [accentColor, setAccentColor] = useState('#9264F8');
  const [title, setTitle] = useState('Stake');
  const [btnTextColor, setBtnTextColor] = useState('#FFF');
  const [subtitle, setSubtitle] = useState('');
  const [website, setWebsite] = useState('');
  const [logo, setLogo] = useState<string | null>(null);
  const [banner, setBanner] = useState<string | null>(null);
  const [titleEnabled, setTitleEnabled] = useState(true);
  const [subtitleEnabled, setSubtitleEnabled] = useState(false);
  const [websiteEnabled, setWebsiteEnabled] = useState(false);
  const [file, setFile] = useState<File | undefined>();

  const [bannerFile, setBannerFile] = useState<File | undefined>();
  const [isRndAccentColor, setIsRndAccentColor] = useState<boolean>(false);
  const [isRndPgColor, setIsRndPgColor] = useState<boolean>(false);
  const navigate = useNavigate();
  const fileInput = useRef<HTMLInputElement>(null);
  const pageColorRef = useRef<HTMLInputElement>(null);
  const accentColorRef = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();
  const onBoardingId = useAppSelector(
    (state) => state.onBoardingState.abstractionFlow?.data.id
  );

  const {
    isEditingOnboardingApp,
    customization,
    selectedOnboardingType,
    protocolId,
    logoFile,
  } = useAppSelector((state) => ({
    isEditingOnboardingApp: state.onBoardingState.isEditingOnboardingApp,
    customization: state.onBoardingState.customization,
    selectedOnboardingType: state.onBoardingState.onBoardingType,
    protocolId: state.appState.protocolId,
    logoFile: state.onBoardingState.customization.file,
  }));

  const handleLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const logoUrl = URL.createObjectURL(file);
      setLogo(logoUrl);
      dispatch(updateLogoFile(file));
    }
  };

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setLogo(e.target?.result as string);
      setFile(file);
      dispatch(updateLogoFile(file));
    };
    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    maxSize: 1048576, // 1 MB
  });

  const handlePreviewClick = () => {
    navigate('/PreviewConfiguration');
  };

  const handleSelectPageColor = () => {
    if (pageColorRef.current) {
      pageColorRef.current.click();
    }
  };

  const handleSelectAccentColor = () => {
    if (accentColorRef.current) {
      accentColorRef.current.click();
    }
  };

  const PAGE_BG = [
    {
      color: '#FFFFFF',
      style: 'bg-[#FFFFFF]', /// using tailwind color style
    },
    {
      color: '#666666',
      style: 'bg-[#666666]', /// using tailwind color style
    },
    {
      color: '#000000',
      style: 'bg-[#000000]', /// using tailwind color style
    },
  ];

  const ACCENT_COLOR = [
    {
      color: '#FFFFFF',
      style: 'bg-[#FFFFFF]', /// using tailwind color style
    },
    {
      color: '#000000',
      style: 'bg-[#000000]', /// using tailwind color style
    },
  ];
  // useEffect(() => {
  //   // call endpoint to get protocol last onboarding app customization
  //   (async () => {
  //     const projectOnboardings = await fetchOnboardingAppFromProjectId(
  //       protocolId
  //     );
  //     // console.dir(projectOnboardings, { depth: null });
  //     //use the updated at to get the last onboarding app
  //     if (projectOnboardings && projectOnboardings?.data.length > 0) {
  //       const recentOnboardingApp = projectOnboardings?.data.reduce(
  //         (latest, onboardingApp) => {
  //           return new Date(onboardingApp.updated_at) >
  //             new Date(latest.updated_at)
  //             ? onboardingApp
  //             : latest;
  //         },
  //         projectOnboardings?.data[0]
  //       );
  //       if (recentOnboardingApp.accent_color) {
  //         dispatch(updateAccentColor(recentOnboardingApp.accent_color));
  //       }
  //       if (recentOnboardingApp.page_color) {
  //         dispatch(updatePageColor(recentOnboardingApp.page_color));
  //       }
  //     }
  //   })();
  // }, []);
  useMemo(() => {
    const isExistAccent = ACCENT_COLOR.some(
      (color) => color.color == customization.accentColor
    );
    const isExistPage = PAGE_BG.some(
      (color) => color.color == customization.pageColor
    );
    setIsRndPgColor(isExistPage);
    setIsRndAccentColor(isExistAccent);
    const computedFontColor = getFontColor(customization?.accentColor);
    setBtnTextColor(computedFontColor);
    if (customization.file) {
      const logoUrl = URL.createObjectURL(customization?.file);
      setLogo(logoUrl);
    }
  }, [customization.accentColor, customization.pageColor, customization.file]);

  const handleSetAccentColor = (color: string) => {
    setAccentColor(color);
  };

  useMemo(() => {
    if (selectedOnboardingType == OnBoardingType.SWAP) {
      setTitle('Swap');

      //setSubtitle("Swap and get Reward");
    }
    if (selectedOnboardingType == OnBoardingType.BRIDGE) {
      setTitle('Bridge');

      //setSubtitle("Bridge and get Reward");
    }
    if (selectedOnboardingType == OnBoardingType.LEND) {
      setTitle('Lend');

      //setSubtitle("Lend and get Reward");
    }
  }, [selectedOnboardingType]);

  return (
    <>
      {!isEditingOnboardingApp ? (
        <div className="flex gap-5 mt-[60px]">
          {/* Left Form Panel */}
          <div className="flex flex-col gap-3 ">
            <div className="flex bg-gray-light-3/30 flex-col h-[159px] w-[517px] border border-gray-light-3 rounded-xl justify-evenly">
              <p className="pl-8 text-[16px] font-semibold text-left text-white">
                Component theme color
              </p>
              <div className="flex justify-center gap-3">
                <button
                  onClick={() => {
                    dispatch(updateTheme(THEME.DARK));
                  }}
                  className={`w-[220px] text-[15px] h-[50px]  rounded-[40px] font-semibold flex justify-center items-center gap-4 text-white ${
                    customization.theme == 'dark'
                      ? 'bg-primary-default'
                      : 'bg-gray-light-3'
                  }`}
                >
                  {customization.theme == 'dark' && (
                    <ImCheckmark className="text-white" />
                  )}{' '}
                  Dark
                </button>
                <button
                  onClick={() => {
                    dispatch(updateTheme(THEME.LIGHT));
                  }}
                  className={`w-[220px] h-[50px] rounded-[40px] font-semibold flex text-[15px] justify-center text-white gap-4 items-center  ${
                    customization.theme == THEME.LIGHT
                      ? 'bg-primary-default'
                      : 'bg-gray-light-3'
                  }`}
                >
                  {customization.theme == THEME.LIGHT && (
                    <ImCheckmark className="w-4 h-4 text-white" />
                  )}{' '}
                  Light
                </button>
              </div>
            </div>

            <div className="flex gap-4">
              {/* Background and Accent Color Pickers */}
              <div className="w-[250px] h-[133px] bg-gray-light-3/30 border border-gray-light-3 rounded-xl pl-10 flex flex-col justify-evenly">
                <label className="text-base font-semibold text-white">
                  Page Background
                </label>
                <div className="flex items-center gap-2">
                  {PAGE_BG.map((pageBg) => (
                    <button
                      onClick={() => {
                        dispatch(updatePageColor(pageBg.color));
                      }}
                      className={`${
                        pageBg.style
                      } rounded-full  w-[24px] h-[24px] ${
                        pageBg.color == customization.pageColor && 'border-2'
                      } ${
                        pageBg.color == '#FFFFFF'
                          ? 'border-[#CCCCCC]'
                          : 'border-[#FFFFFF]'
                      }`}
                    ></button>
                  ))}
                  <input
                    ref={pageColorRef}
                    className="w-[24px] h-[24px] rounded-full hidden"
                    type="color"
                    value={customization.pageColor}
                    onChange={(e) => {
                      dispatch(updatePageColor(e.target.value));
                    }}
                  />
                  <img
                    onClick={handleSelectPageColor}
                    src="/assets/color-scheme.svg"
                    className={`w-[24px] h-[24px] border-white ${
                      !isRndPgColor && 'border-2'
                    } rounded-full`}
                  />
                </div>
              </div>

              <div className="w-[250px] h-[133px] bg-gray-light-3/30 pl-10 border border-gray-light-3 rounded-xl flex flex-col justify-evenly">
                <label className="text-base font-semibold text-white">
                  Accent Color
                </label>
                <div className="flex items-center gap-1">
                  {ACCENT_COLOR.map((accent_color) => (
                    <button
                      onClick={() => {
                        dispatch(updateAccentColor(accent_color.color));
                      }}
                      className={`${
                        accent_color.style
                      } rounded-full  w-[24px] h-[24px] ${
                        accent_color.color == customization.accentColor &&
                        'border-2'
                      } ${
                        accent_color.color == '#FFFFFF'
                          ? 'border-[#CCCCCC]'
                          : 'border-[#FFFFFF]'
                      }`}
                    ></button>
                  ))}
                  <input
                    ref={accentColorRef}
                    className="h-[24px] w-[24px] rounded-full hidden"
                    type="color"
                    value={customization.accentColor}
                    onChange={(e) => {
                      dispatch(updateAccentColor(e.target.value));
                    }}
                  />
                  <img
                    onClick={handleSelectAccentColor}
                    src="/assets/color-scheme.svg"
                    className={`w-[24px] h-[24px] border-white ${
                      !isRndAccentColor && 'border-2'
                    } rounded-full`}
                  />
                </div>
              </div>
            </div>

            {/* Logo Upload */}
            <div
              {...getRootProps()}
              className={`flex flex-col justify-evenly h-[178px] ${
                showLogoWarning ? 'border-[#FF4471]' : 'border-gray-light-3'
              } border rounded-xl px-7 bg-gray-light-3/30`}
            >
              <label className="text-base font-semibold text-white">Logo</label>
              <div
                style={{
                  backgroundImage: 'url(/assets/upload.svg)',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                }}
                className="flex cursor-pointer flex-col items-center h-[60px] w-[453px] justify-center py-2 bg-cover bg-no-repeat"
              >
                <input
                  ref={fileInput}
                  type="file"
                  accept="image/*"
                  onChange={handleLogoUpload}
                  className="hidden"
                />
                {logo ? (
                  <div className="flex items-center justify-between w-full px-5">
                    <div className="flex items-center justify-center gap-2">
                      <img
                        src={logo}
                        className="object-contain w-[160px] h-[40px] "
                      />
                      <p className="text-[15px] text-[#CCC]">{file?.name}</p>
                    </div>
                    <button
                      onClick={() => {
                        setLogo('');
                        dispatch(updateLogoFile(undefined));
                      }}
                      className="outline-none text-[#FF4471] text-[14px] font-semibold"
                    >
                      Remove
                    </button>
                  </div>
                ) : (
                  <div
                    onClick={() =>
                      fileInput.current && fileInput.current.click()
                    }
                    className="flex flex-col items-center justify-center"
                  >
                    <div className="flex gap-2 text-white text-[14px]">
                      <img src={UploadIcon} alt="Upload Icon" /> Upload or drop
                      image here
                    </div>
                    <p className="text-[14px] text-[#CCC]">
                      Supported formats: png, jpg Max size: 1 mb
                    </p>
                  </div>
                )}
              </div>
              {showLogoWarning && (
                <p className="text-[14px] text-[#FF4471]">
                  Please upload a logo
                </p>
              )}
            </div>

            {/* Toggleable Title */}
            <div className="flex flex-col justify-evenly pl-10 h-[375px] border-gray-light-3 border bg-gray-light-3/30 rounded-xl">
              <p className="text-base font-semibold text-white"> Text</p>

              <div className="flex flex-row items-center gap-2">
                <div>
                  <Switch
                    checked={titleEnabled}
                    onChange={setTitleEnabled}
                    className="group relative flex h-[24px] mt-7 w-[44px] cursor-pointer items-center rounded-full bg-white/10 transition-colors duration-200 ease-in-out focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-primary-default"
                  >
                    <span
                      aria-hidden="true"
                      className="pointer-events-none inline-block size-3 translate-x-1 rounded-full bg-white ring-0 shadow-lg transition duration-200 ease-in-out group-data-[checked]:translate-x-6 group-data-[checked]:bg-white"
                    />
                  </Switch>
                </div>
                <div className="flex flex-col w-full gap-2">
                  <label className="text-base font-normal text-white">
                    Title
                  </label>
                  <input
                    type="text"
                    value={customization.title}
                    onChange={(e) => {
                      dispatch(updateTitle(e.target.value));
                    }}
                    placeholder="Insert title"
                    disabled={!titleEnabled}
                    className="rounded-[40px] leading-[24px]  text-white text-[15px] outline-none placeholder:text-gray-light-3 h-[46px] w-[393px] bg-gray-dark-3 border border-gray-light-3 py-[12px] px-[16px]"
                  />
                </div>
              </div>

              {/* Toggleable Subtitle */}

              <div className="flex flex-row items-center gap-2">
                <div>
                  <Switch
                    checked={subtitleEnabled}
                    onChange={() => setSubtitleEnabled(!subtitleEnabled)}
                    className="group relative flex h-[24px] mt-7 w-[44px] cursor-pointer items-center rounded-full bg-white/10 transition-colors duration-200 ease-in-out focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-primary-default"
                  >
                    <span
                      aria-hidden="true"
                      className="pointer-events-none inline-block size-3 translate-x-1 rounded-full bg-white ring-0 shadow-lg transition duration-200 ease-in-out group-data-[checked]:translate-x-6 group-data-[checked]:bg-white"
                    />
                  </Switch>
                </div>
                <div className="flex flex-col w-full gap-2">
                  <label className="text-base font-normal text-white">
                    Subtitle
                  </label>
                  <input
                    type="text"
                    value={customization.subtitle}
                    onChange={(e) => {
                      dispatch(updateSubtitle(e.target.value));
                    }}
                    placeholder="Insert subtitle"
                    disabled={!subtitleEnabled}
                    className="rounded-[40px] text-white leading-[24px]  text-[15px] outline-none h-[46px] placeholder:text-gray-light-3 w-[393px] bg-gray-dark-3 border border-gray-light-3 px-[16px] py-[12px]"
                  />
                </div>
              </div>

              {/* Toggleable Website */}

              <div className="flex flex-row items-center gap-2">
                <div>
                  <Switch
                    checked={websiteEnabled}
                    onChange={() => setWebsiteEnabled(!websiteEnabled)}
                    className="group relative flex h-[24px] mt-7 w-[44px] cursor-pointer items-center rounded-full bg-white/10 transition-colors duration-200 ease-in-out focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-primary-default"
                  >
                    <span
                      aria-hidden="true"
                      className="pointer-events-none inline-block size-3 translate-x-1 rounded-full bg-white ring-0 shadow-lg transition duration-200 ease-in-out group-data-[checked]:translate-x-6 group-data-[checked]:bg-white"
                    />
                  </Switch>
                </div>
                <div className="flex flex-col w-full gap-2">
                  <label className="text-base font-normal text-white">
                    Website
                  </label>
                  <input
                    type="text"
                    value={customization.website}
                    onChange={(e) => {
                      dispatch(updateWebsite(e.target.value));
                    }}
                    placeholder="Insert platform website"
                    disabled={!websiteEnabled}
                    className="rounded-[40px] leading-[24px] text-[15px] outline-none placeholder:text-gray-light-3 text-white h-[46px] w-[393px] bg-gray-dark-3 border border-gray-light-3 px-[16px] py-[12px]"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Preview Panel */}
          <div className="">
            {/* Preview Panel */}
            <PreviewComponent
              pageBgColor={customization.pageColor}
              accentColor={customization.accentColor}
              logo={logo || ''}
              title={titleEnabled ? customization.title : ''}
              subtitle={subtitleEnabled ? customization.subtitle : ''}
              website={websiteEnabled ? customization.website : ''}
              theme={customization.theme}
              btnTextColor={btnTextColor}
            />
          </div>
        </div>
      ) : (
        <CustomizedEdit appName={appName} />
      )}
    </>
  );
};

export default CustomizedStake;
