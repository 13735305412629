import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import OnBoardingHeader from "../../components/OnBoardingHeader";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
} from "@headlessui/react";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import {
  clearOnBoardingRecord,
  updateIsEditingOnboardingApp,
  updateLogoFile,
  updateProjectName,
} from "../../state/onboarding/onboarding";
import OnBoardingAction from "../../components/OnBoardingAction";
import {
  toggleOnBoardingAction,
  toggleLoginOrSignupDialog,
  toggleSelectProtocolDialog,
} from "../../state/dialog";
import { HiMiniArrowRight } from "react-icons/hi2";
import { convertImageUrlTofile, getProjects } from "../../api/project/route";
import { useAccount } from "wagmi";
import Tslogin from "../Tsconnect";
import {
  useFetchAllOnboardingAppByProtocolId,
  useFetchUserDetails,
} from "../../hooks/projects";
import OnBoardingCard from "../../components/OnBoardingCard";
import { FaPlus } from "react-icons/fa6";
import { getSession, SessionKeys, setSession } from "../../utils/helper";
import { error } from "console";

interface Project {
  id: number;
  title: string;
  is_claimed: boolean;
  company_id: number | null;
  created_at: string;
  updated_at: string;
}

export default function Dashboard() {
  let [isOpen, setIsOpen] = useState(false); // For the project selection modal
  let [isClaimModalOpen, setIsClaimModalOpen] = useState(false); // Claim space modal state
  let [userClaimedProject, setUserClaimedProject] = useState<number>(0);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const projectName = useAppSelector(
    (state) => state.onBoardingState.projectName
  );
  const protocolId = useAppSelector((state) => state.appState.protocolId);

  const isClaimedProject = useAppSelector(
    (state) => state.appState.isProjectClaim
  );
  const account = useAccount();
  const onBoardingApps = useFetchAllOnboardingAppByProtocolId(protocolId);
  const userDetails = useFetchUserDetails({ userAddress: account.address });

  console.log(onBoardingApps.data, "onboarding Apps");

  // const handleProjectSelect = (project: Project) => {
  //   console.log(`Project selected: ${project}`);
  //   dispatch(updateProjectName(project.title));
  //   setSession({ protocolId: String(project.id), protocolName: project.title });
  //   setIsClaimedProject(project.is_claimed);
  //   dispatch(updateProjectId(project.id)); // uncomment when testing phase is done
  //   setIsOpen(false); // Close the prject selection modal when a project is selected
  //   setSession({ protocolId: String(project.id), protocolName: project.title });
  // };

  const handleAction = () => {
    dispatch(toggleOnBoardingAction(true));
    dispatch(updateIsEditingOnboardingApp(false));
    dispatch(clearOnBoardingRecord());
  };

  useMemo(() => {
    if (
      userDetails.data &&
      userDetails.data.__company__?.__projects__.length > 0
    ) {
      const id =
        userDetails.data.__company__.__projects__[
          userDetails.data.__company__.__projects__.length - 1
        ].id;
      setUserClaimedProject(id);
    }
  }, [userDetails.data, protocolId]);

  useMemo(() => {
    const latestOnboardingAppWithLogo = onBoardingApps.data?.find(
      (onboarding) => onboarding.logo
    );
  
    if (latestOnboardingAppWithLogo?.logo) {
      convertImageUrlTofile(
        `${latestOnboardingAppWithLogo.logo}`,
        `${projectName}.jpg`
      )
        .then((file) => {
          dispatch(updateLogoFile(file));
        })
        .catch((error) => console.error("Error:", error));
    }
  }, [protocolId]);

  // Claim Space Modal component
  const ClaimSpaceModal = () => {
    return (
      <Dialog
        open={isClaimModalOpen} // Ensure the modal opens when isClaimModalOpen is true
        onClose={() => setIsClaimModalOpen(false)} // Close the modal when needed
        className="relative z-50"
      >
        <DialogBackdrop className="fixed inset-0 bg-black/80" />
        <div className="fixed inset-0 flex items-center justify-center w-screen p-4">
          <DialogPanel className="w-[481px] max-h-[267px] flex flex-col items-center p-[32px]  border-white/40 rounded-2xl bg-[#222] border">
            <DialogTitle className="flex items-start font-bold mb-[24px]">
              <div className="flex flex-col px-18">
                <p className="text-[20px]  text-center font-bold leading-6 ">
                  Claim {projectName} space to
                </p>
                <p className="text-[20px]  text-center font-bold leading-6 ">
                  create onboarding apps
                </p>
              </div>
              <button
                onClick={() => setIsClaimModalOpen(false)}
                className="items-end text-white"
              >
                <img
                  src="/assets/close.svg"
                  alt="close"
                  className="h-[24px] w-[24px]"
                />
              </button>
            </DialogTitle>
            <div className="mb-[32px]">
              <div className="px-7">
                <p className="text-base text-center text-white leading-[21px]">
                  In order to create onboarding apps, please contact TownSquare
                  team member{" "}
                  <span className="text-base font-semibold text-primary-light">
                    @realjcz{" "}
                  </span>
                  on Telegram to claim {projectName} space.
                </p>
              </div>
              <div className="flex justify-center">
                <button
                  className="bg-primary-default text-white rounded-[32px] hover:bg-primary-hover active:bg-primary-active w-[139px] h-[40px] mt-6"
                  onClick={() => window.open("https://t.me/realjcz", "_blank")}
                >
                  Open Telegram
                </button>
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    );
  };

  return (
    <div className="flex flex-col min-h-screen">
      <OnBoardingHeader showProjectName={true} />
      <div
        className="flex flex-col flex-grow py-2 bg-black bg-no-repeat px-25"
        style={{
          backgroundImage: "url(/assets/manage.svg)",
          backgroundSize: "contain",
        }}
      >
        {/* Convert div to button */}
        <div>
          {protocolId > 0 &&
            account.isConnected &&
            isClaimedProject == false && (
              <button
                className="flex items-center justify-center w-full gap-3 py-4 mb-[24px] text-white border rounded-lg bg-white/10 border-primary-light"
                onClick={() => setIsClaimModalOpen(true)} // Open the claim modal on click
              >
                Claim {`${projectName ? projectName : "My"}`} Space
                <HiMiniArrowRight color="#b882ff" />
              </button>
            )}

          <div className="flex items-center justify-between ">
            {protocolId && account.isConnected ? (
              <h1 className="text-white text-[32px] font-bold">
                {/* {`${projectName ? projectName : "My"}`} Onboarding Apps */}
                {projectName} onboarding apps
              </h1>
            ) : (
              <h1 className="text-white text-[32px] font-bold">
                {/* {`${projectName ? projectName : "My"}`} Onboarding Apps */}
                Onboarding apps
              </h1>
            )}
            {protocolId &&
              account.isConnected &&
              (protocolId == userClaimedProject ||
                userDetails.data?.is_super_admin) && (
                <div className="flex items-center justify-between gap-16">
                  <button
                    onClick={() => {
                      navigate("/archive");
                    }}
                    className="text-base font-semibold text-white outline-none"
                  >
                    Archived apps
                  </button>
                  {onBoardingApps.data && onBoardingApps.data.length > 0 && (
                    <button
                      onClick={handleAction}
                      className="text-white flex items-center justify-center gap-2 bg-primary-default h-[46px] hover:bg-primary-hover active:bg-primary-active outline-none text-base font-medium rounded-[32px] w-[242px]"
                    >
                      <FaPlus /> Create onboarding App
                    </button>
                  )}
                </div>
              )}
          </div>
        </div>
        {account.isConnected ? (
          <>
            {!protocolId ? (
              <div className="flex flex-col items-center justify-center h-full gap-4 m-auto">
                <p className="text-xl font-normal text-white">
                  Select a protocol to create onboarding apps
                </p>
                <button
                  onClick={() => dispatch(toggleSelectProtocolDialog(true))}
                  className="text-white flex items-center justify-center gap-2 bg-primary-default h-[46px] outline-none text-base font-medium rounded-[32px] hover:bg-primary-hover active:bg-primary-active w-[152px]"
                >
                  Select Protocol
                </button>
              </div>
            ) : (
              <>
                {onBoardingApps.data && onBoardingApps.data.length > 0 ? (
                  <div className="flex flex-wrap gap-3 mt-10">
                    {onBoardingApps.data
                      ?.filter(
                        (onBoardingApp) => onBoardingApp.is_archived == false
                      )
                      .sort((a, b) => b.id - a.id)
                      .map((onBoardingApp) => (
                        <OnBoardingCard
                          key={onBoardingApp.id}
                          isActive={onBoardingApp.is_active}
                          title={onBoardingApp.title}
                          sourceAsset={onBoardingApp.source_asset}
                          onBoardingLink={onBoardingApp.generate_link}
                          onBoardingId={onBoardingApp.id}
                          projectId={onBoardingApp.project_id}
                          isArchived={false}
                          onboardingType={onBoardingApp.onboarding_type}
                          destAsset={onBoardingApp.dest_asset}
                          source_chain={onBoardingApp.source_chain}
                          dest_chain={onBoardingApp.dest_chain}
                          assets={onBoardingApp.asset_images}
                          name={onBoardingApp.name}
                          createdTime={onBoardingApp.created_at}
                          isProjectOwner={
                            protocolId == userClaimedProject ||
                            userDetails.data?.is_super_admin
                          }
                          isSuperAdmin={userDetails.data?.is_super_admin}
                          bonusPercentage={onBoardingApp.bonus_percentage}
                        />
                      ))}
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center gap-4 m-auto">
                    <p className="text-xl font-normal text-white">
                      When you create onboarding apps, they will show up here
                    </p>
                    {(protocolId && protocolId == userClaimedProject) ||
                      (userDetails.data?.is_super_admin && (
                        <button
                          onClick={handleAction}
                          className="text-white flex items-center justify-center gap-2 bg-primary-default h-[46px] outline-none text-base font-medium rounded-[32px] hover:bg-primary-hover active:bg-primary-active w-[242px]"
                        >
                          <FaPlus /> Create onboarding app
                        </button>
                      ))}
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <div className="flex flex-col items-center justify-center gap-4 m-auto">
            <p className="text-xl font-normal text-white">
              When you create onbaording apps, they will show up here
            </p>
            <button
              onClick={() => dispatch(toggleLoginOrSignupDialog(true))}
              className="text-white bg-primary-default h-[46px] hover:bg-primary-hover active:bg-primary-active outline-none font-medium text-base rounded-[32px] w-[180px]"
            >
              Connect Wallet
            </button>
          </div>
        )}
        <ClaimSpaceModal /> {/* Render Claim Space Modal */}
        <OnBoardingAction />
      </div>
    </div>
  );
}
