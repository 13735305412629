import React, { useState } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
} from "@headlessui/react";
import { useFetchAllProject } from "../hooks/projects";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import {
  updateProjectName,
  updateProtocolActions,
} from "../state/onboarding/onboarding";
import { setSession } from "../utils/helper";
import { updateIsProjectClaim, updateProtocolId } from "../state/app";
import { useAccount } from "wagmi";
import { toggleSelectProtocolDialog } from "../state/dialog";
import { Protocol } from "../api/types";

const SelectProtocolModal = ({
  showProjectName = true,
}: {
  showProjectName: boolean;
}) => {
  const dispatch = useAppDispatch();
  const account = useAccount();
  const { projectName, selectProtocolDialog, protocolId } = useAppSelector(
    (state) => ({
      projectName: state.onBoardingState.projectName,
      selectProtocolDialog: state.dialogState.selectProtocolDialog,
      protocolId: state.appState.protocolId,
    })
  );
  const ALL_PROJECT = useFetchAllProject();
  let [isOpen, setIsOpen] = useState(false); // For the project selection modal
  const handleProjectSelect = (protocol: Protocol) => {
    console.log(`Project selected: ${protocol}`);
    dispatch(updateProjectName(protocol.title));
    setSession({
      protocolId: String(protocol.id),
      protocolName: protocol.title,
      isClaimedProtocol: protocol.is_claimed,
    });
    //setIsClaimedProject(project.is_claimed);
    dispatch(updateProtocolActions(protocol.actions));
    dispatch(updateIsProjectClaim(protocol.is_claimed));
    dispatch(updateProtocolId(protocol.id)); // uncomment when testing phase is done
    dispatch(toggleSelectProtocolDialog(false)); // Close the prject selection modal when a project is selected
    dispatch(updateProtocolActions(protocol.actions));
  };

  return (
    <>
      {showProjectName && account.isConnected && protocolId && (
        <button
          onClick={() => dispatch(toggleSelectProtocolDialog(true))}
          className="h-[46px] min-w-max px-4 py-[12px] text-white flex justify-center items-center rounded-[32px] hover:bg-tertiary-hover active:bg-tertiary-active text-center border border-white"
        >
          <span className="flex items-center justify-center gap-2 ">
            <span className="border-2 w-[30px] h-[30px] rounded-full border-[#404040]">
              <img
                className="rounded-full "
                src={`https://townesquare-media-new.s3.eu-west-2.amazonaws.com/${projectName}-rounded.svg`}
              />
            </span>
            <span>{projectName}</span>
            <img src="/assets/reload.svg" alt="Reload Icon" />
          </span>
        </button>
      )}
      <Dialog
        open={selectProtocolDialog}
        onClose={() => dispatch(toggleSelectProtocolDialog(false))}
        className="relative z-50 overflow-y-auto"
      >
        <DialogBackdrop className="fixed inset-0 bg-black/80" />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex items-center justify-center p-4">
            <DialogPanel className="w-[746px] flex justify-center flex-col gap-y-11 pt-8 pb-14 px-12 border-white/40 rounded-2xl bg-[#222] border">
              <DialogTitle className="flex items-center justify-between font-bold">
                <p className="text-[26px] text-white">Select your protocol</p>
                <button
                  onClick={() => dispatch(toggleSelectProtocolDialog(false))}
                  className="text-white"
                >
                  <img
                    src="/assets/close.svg"
                    alt="close"
                    className="h-[24px] w-[24px]"
                  />
                </button>
              </DialogTitle>
              <div className="flex justify-center">
                {ALL_PROJECT.data && (
                  <div className="grid gap-[15px] md:grid-cols-4">
                    {ALL_PROJECT.data.map((project) => (
                      <button
                        disabled={project.actions.every(
                          (action) => action.isLive == false
                        )}
                        key={project.id}
                        className={`bg-black ${
                          project.actions.every(
                            (action) => action.isLive == false
                          )
                            ? ""
                            : "hover:bg-tertiary-hover active:bg-tertiary-active"
                        } relative flex-col  h-[150px] w-[150px] box-border border-white/40 border rounded-xl flex justify-center items-center`}
                        onClick={() => handleProjectSelect(project)}
                      >
                        <img src={`/assets/projects/${project.title}.svg`} />
                        {project.actions.every(
                          (action) => action.isLive == false
                        ) && (
                          <p className="text-gray-light2 absolute bottom-[23px] font-medium text-[12px]">
                            COMING SOON
                          </p>
                        )}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default SelectProtocolModal;
