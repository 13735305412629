import axios from "axios";
import { BACKEND_URL } from "../env";
import {
  FetchAllOnboardingAppByProjectIdResponse,
  FetchOnbaordingAppResponse,
  FetchProjectRespomseData,
  FetchSingleOnbaordingAppResponse,
  GetAllChainResponse,
  GetAssetsResponse,
  GetMembersListResponse,
} from "../types";
import { AbstractionData } from "../../state/onboarding/onboarding";
import { getSession } from "../../utils/helper";
import { THEME } from "../../state/types";

// Function to get projects
export const getProjects =
  async (): Promise<FetchProjectRespomseData | null> => {
    const token = localStorage.getItem("token");
    //console.log(token, ':token');
    try {
      const response = await axios.get(`${BACKEND_URL}api/projects`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        console.error("Authorization failed: Invalid or expired token.");
      } else {
        console.error("Error fetching projects:", error);
      }
      return null;
    }
  };

// Function to create onboarding
export const createOnboardingApp = async (
  productId: number,
  type: string,
  appName: string,
  sourceChain?: string,
  sourceAsset?: string,
  destAsset?: string,
  destChain?: string,
  sourceChainId?: string,
  destinationChainId?: string

  // redirectUrl?: string
) => {
  try {
    const token = localStorage.getItem("token");
    const payload = {
      name: appName,
      project_id: productId,
      onboarding_type: type,
      source_chain: sourceChain,
      source_asset: sourceAsset,
      dest_chain: destChain,
      dest_asset: destAsset,
      source_chain_id: sourceChainId,
      destination_chain_id: destinationChainId,
      // redirect_url: "http://test.dev/",
    };

    const response = await fetch(
      "https://action-link-stage.townesquare.xyz/api/onboardings",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );

    // if (!response.ok) {
    //   const errorResponse = await response.json();
    //   console.error("Backend Error:", errorResponse);
    //   throw new Error("Failed to create onboarding");
    // }

    const result = await response.json();
    // console.log("Onboarding created:", result);
    return result; // Return the result only once
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Return the actual error message from the server
      throw error.response?.data || error.message;
    } else {
      // Handle unexpected errors
      throw error;
    }
  }
};

export const getAllChains = async (): Promise<GetAllChainResponse | null> => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `${BACKEND_URL}api/onboardings/get-chains`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getAssets = async (
  protocolId: number,
  onboardingType: string
): Promise<GetAssetsResponse | null> => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `${BACKEND_URL}api/onboardings/get-assets`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          protocolId: protocolId,
          action: onboardingType,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const addCustomization = async ({
  onboardingId,
  file,
  theme,
  pageColor,
  accentColor,
  title,
  subtitle,
  website,
  bannerFile,
  name,
}: {
  onboardingId?: string;
  file?: File;
  theme: string;
  pageColor: string;
  accentColor: string;
  title: string;
  subtitle: string;
  website: string;
  bannerFile?: File;
  name: string;
}) => {
  const formData = new FormData();
  if (file) {
    formData.append("file", file);
  }

  if (bannerFile) {
    formData.append("banner", bannerFile);
  }

  formData.append("theme", theme);
  formData.append("page_color", pageColor);
  formData.append("accent_color", accentColor);
  formData.append("title", title);
  formData.append("subtitle", subtitle);
  formData.append("website", website);
  formData.append("name", name);
  try {
    const token = localStorage.getItem("token");
    const response = await axios.put(
      `${BACKEND_URL}api/onboardings/update-onboarding/${onboardingId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {}
};

export const generateOnboardingLink = async (onBoardingId?: string) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.get(
      `${BACKEND_URL}api/onboardings/${onBoardingId}/generate-link`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchSingleOnboardingApp = async (
  onBoardingId: string
): Promise<FetchSingleOnbaordingAppResponse> => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.get(
      `${BACKEND_URL}api/onboardings/${onBoardingId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.error(error);
    return {
      data: {
        id: null,
        project_id: null,
        onboarding_type: "",
        onboarding_link: null,
        apr: null,
        min_amount: null,
        max_amount: null,
        redirect_url: "",
        source_chain: "",
        source_asset: "",
        dest_chain: "",
        dest_asset: "",
        theme: "",
        page_color: "",
        accent_color: "",
        logo: "",
        title: "",
        subtitle: "",
        website: "",
        poster: null,
        is_active: false,
        flow: null,
        banner: "",
        company_id: "",
        abstraction_flow: null,
        source_asset_address: undefined,
      },
    };
  }
};

export const toggleActiveOnBoarding = async (onBoardingId?: string) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.post(
      `${BACKEND_URL}api/onboardings/${onBoardingId}/toggle-active`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.error(error);
  }
};

export const fetchOnboardingAppFromProjectId = async (
  projectId: number
): Promise<FetchAllOnboardingAppByProjectIdResponse | null> => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.get(`${BACKEND_URL}api/onboardings`, {
      params: {
        project_id: projectId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateAbstraction = async (
  onBoardingId: string,
  abstractionFlow: AbstractionData
) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.post(
      `${BACKEND_URL}api/onboardings/${onBoardingId}/abstraction-flow`,
      {
        abstraction_flow: abstractionFlow,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const toggleArchiveOnBoarding = async (onBoardingId?: string) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.post(
      `${BACKEND_URL}api/onboardings/${onBoardingId}/toggle-archive`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.error(error);
  }
};

export const addBonusToOnBoardingApp = async (
  bonusPercentage: number,
  onBoardingId?: string
) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.put(
      `${BACKEND_URL}api/onboardings/${onBoardingId}/bonus`,
      {
        bonus_percentage: bonusPercentage,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Return the actual error message from the server
      throw error.response?.data || error.message;
    } else {
      // Handle unexpected errors
      throw error;
    }
  }
};

export const inviteMembers = async (
  wallet: string,
  roleId: number,
  projectId: number
) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.post(
      `${BACKEND_URL}api/clients/invite-member`,
      {
        wallet,
        role_id: roleId,
        project_id: projectId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Return the actual error message from the server
      throw error.response?.data || error.message;
    } else {
      // Handle unexpected errors
      throw error;
    }
  }
};

export const getAllMembersList = async (
  isSuperAdmin: boolean,
  projectId: number
): Promise<GetMembersListResponse | null> => {
  try {
    const token = localStorage.getItem("token");

    if (isSuperAdmin) {
      const res = await axios.get(`${BACKEND_URL}api/clients/members`, {
        params: {
          project_id: projectId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data;
    } else {
      const res = await axios.get(`${BACKEND_URL}api/clients/members`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const removeMember = async (memberId?: string) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.delete(
      `${BACKEND_URL}api/clients/${memberId}/remove-member/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Return the actual error message from the server
      throw error.response?.data || error.message;
    } else {
      // Handle unexpected errors
      throw error;
    }
  }
};

export const updateMemberRole = async (roleId: number, memberId?: string) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.put(
      `${BACKEND_URL}api/clients/${memberId}/update-member`,
      {
        role_id: roleId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Return the actual error message from the server
      throw error.response?.data || error.message;
    } else {
      // Handle unexpected errors
      throw error;
    }
  }
};

export const fetchOnboardingAppDetails = async (
  onboardingId: number
): Promise<FetchOnbaordingAppResponse> => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.get(
      `${BACKEND_URL}api/onboardings/${onboardingId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.error(error, "fetch Error");
    return {
      data: {
        id: null,
        project_id: null,
        onboarding_type: "",
        generate_link: null,
        apr: null,
        min_amount: null,
        max_amount: null,
        redirect_url: "",
        source_chain: "",
        source_asset: "",
        dest_chain: "",
        dest_asset: "",
        theme: THEME.DARK,
        page_color: "",
        accent_color: "",
        logo: "",
        title: "",
        subtitle: "",
        website: "",
        poster: null,
        is_active: false,
        name: "",
        banner: "",
        company_id: "",
        abstraction_flow: null,
        source_asset_address: undefined,
        asset_images: null,
      },
    };
  }
};

export const convertImageUrlTofile = async (url: string, fileName: string) => {
  const response = await axios.get(url, { responseType: "blob" });

  return new File([response.data], fileName, { type: response.data.type });
};

// export const createBannerFile = async (
//   title: string,
//   protocol: string,
//   subtitle: string,
//   action: string,
//   logo: string
// ): Promise<string> => {
//   return new Promise((resolve, reject) => {
//     let canvas = document.createElement("canvas");
//     const ctx = canvas.getContext("2d");
//     const img = new Image();
//     img.src = "/assets/banner.svg";

//     img.onload = async () => {
//       canvas.width = 600;
//       canvas.height = 400;

//       if (ctx) {
//         ctx.drawImage(img, 0, 0, 600, 400);
//         //ctx.font = "bold 40px myFont";
//         ctx.fillStyle = "white";
//         const textWidth = ctx.measureText(title).width;
//         const x = canvas.width / 2 - textWidth / 2;
//         ctx.fillText(title, x, 230);
//       }
//       const imageData = canvas.toDataURL();
//       console.log(imageData, "ddd");
//     };
//   });
// };
