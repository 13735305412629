import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserDetails } from "./types";

interface userState {
  userAddress: string;
  userDetails: UserDetails | null;
}

const initialState: userState = {
  userAddress: "",
  userDetails: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserAddress: (state, action: PayloadAction<string>) => {
      state.userAddress = action.payload;
    },
    
  },
  extraReducers: (builder) => {
      // builder.addCase(, (state, action) => {
        
      // });
    },
});

export const { updateUserAddress } = userSlice.actions;
export default userSlice.reducer;
