import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import stakeIcon from "../../assets/svg/Sw.svg";
import swapIcon from "../../assets/svg/Swap.svg";
import bridgeIcon from "../../assets/svg/Bridge.svg";
import lendIcon from "../../assets/svg/LendIcon.svg";
import OnBoardingHeader from "../../components/OnBoardingHeader";
import { GoArrowLeft, GoDotFill } from "react-icons/go";
import AddFeatureModal from "./Addfeature";
import {  useParams } from 'react-router-dom';
import { BACKEND_URL } from "../../api/env";
import { Protocol } from "../../api/types";
import { ModularFeatureItem } from "../../state/types";

const ModularView = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  let { dappId } = useParams();
  const [featureList, setFeatureList] = useState<ModularFeatureItem[][]>([]);
  const token = localStorage.getItem("token");
  const location = useLocation();
  const { state } = location;
  
  const { protocol } = state as {
    protocol: Protocol;
  };
  useEffect(() => {
    getModular();
  }, [dappId])

  const getModular = async () => {
    const response = await fetch(
      `${BACKEND_URL}api/modular?project_id=${dappId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    if(result.data?.length > 0) {
      const grouped: ModularFeatureItem[][] = Object.values(
        result.data.reduce((acc: { [key: string]: ModularFeatureItem[] }, item: ModularFeatureItem) => {
          if (!acc[item.category]) {
            acc[item.category] = [];
          }
          acc[item.category].push(item);
          return acc;
        }, {})
      );
      setFeatureList(grouped);
    }
  }

  const closeModal = async () => {
    getModular();
    setIsModalOpen(false);
  }

  return (
    <div className="min-h-screen px-12 text-white bg-black">
      {/* Header */}
      <OnBoardingHeader isModularPage={true} showProjectName={false} />
      <div className="py-12 mx-36">
        <div className="flex items-center gap-4 mb-8">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <GoArrowLeft className="text-white w-[40px] h-[40px] p-2  border border-gray-light-3 rounded-md" />
          </button>
          <p className="text-base font-medium">Back</p>
        </div>

        {/* DApp Overview */}
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-6">
            <img
              src={protocol.logo}
              alt={protocol.title}
              className="w-[60px] h-[60px]"
            />
            <div>
              <p className="text-[29px]">{protocol.title}</p>
              <div className="flex flex-row justify-between">
                {
                  protocol.networks?.map((network, i) => (
                    <div className="flex items-center justify-start gap-1 border rounded-[30px] px-2 py-1 border-gray-light-3 mr-2">
                      <img src={network.logo} className="w-[14px] h-[14px]" />
                      <p className="text-white text-[12px]">{network.chain}</p>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          <p className="text-gray-light-1 text-[14px]">
            {protocol.description}
          </p>
        </div>

        {/* Supported Features */}
        <div className="flex flex-col gap-4 mt-8">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-2xl font-bold ">Supported features</h2>
           
            {/* <button onClick={() => setIsModalOpen(true)} className="px-6 py-3 font-semibold text-white rounded-full bg-primary-default">
              Add a feature
            </button> */}
          </div>
          <div className="flex flex-col gap-6">
            {featureList?.map((feature: ModularFeatureItem[], index) => (
              <div
                key={index}
                className="grid p-6 border rounded-md border-gray-light-3 bg-gray-light-3/20 grid-cols-modular-view"
              >
                <div>
                  <div className="flex items-center gap-4">
                    <img
                      src={
                        feature[0].category === "Stake"
                          ? stakeIcon
                          : feature[0].category === "Swap"
                          ? swapIcon
                          : feature[0].category === "Lend"
                          ? lendIcon
                          : bridgeIcon
                      }
                      alt={feature[0].category}
                      className="w-[40px] h-[40px]"
                    />
                    <h3 className="text-xl font-semibold">{feature[0].category}</h3>
                  </div>
                </div>
                <div className="f">
                  <h1 className="mb-4 text-base font-normal">
                    Supported features
                  </h1>
                  <div className="flex flex-col gap-2">
                    {
                      feature.map((item: ModularFeatureItem, index) => (
                        <div className="flex justify-between mb-3">
                          <div className="flex items-center gap-1.5">
                            <div className="flex items-center gap-1">
                              <img src={item.from_asset_logo} className="w-[25px] h-[25px] rounded-full mr-1" />
                              <p className="text-[15px] font-medium">
                                {item.from_asset}{" "}
                              </p>
                            </div>
                            <span className="text-[15px] text-gray-light mr-[3px]">
                              ({item.from_network})
                            </span>{" "}
                            {
                              item.category !== "Lend" && (
                                <div className="flex items-center gap-1.5">
                                  <span className="font-medium text-[15px] text-[#cccccc]">into </span>
                                  <div className="flex items-center gap-1 ml-[3px]">
                                    <img src={item.to_asset_logo} className="w-[25px] h-[25px] rounded-full mr-1" />
                                    <p className="text-[15px] font-medium">
                                      {item.to_asset}{" "}
                                    </p>
                                  </div>
                                  <span className="text-[15px] text-gray-light">
                                    ({item.to_network})
                                  </span>
                                </div>
                              )
                            }
                          </div>
                          {/* {item.status ? (
                            <p className="text-[#2AB576] flex items-center text-[13px]"> <GoDotFill /> Live</p>
                          ) : (
                          <p className="text-[#FFAD33] flex items-center text-[13px]"> <GoDotFill /> Integration in progress</p>
                          )} */}
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <AddFeatureModal projectId={dappId} isOpen={isModalOpen} onClose={() => closeModal()} />
      </div>
    </div>
  );
};

export default ModularView;
