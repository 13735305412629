import React, { useState, useRef, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { useDropzone } from "react-dropzone";
import { BsUpload } from "react-icons/bs";
import { updateBannerFile } from "../../state/onboarding/onboarding";
import { OnBoardingType, THEME } from "../../state/types";
import { compose } from "@reduxjs/toolkit";


const ThumbnailUpload = ({
  logo,
  title,
  subtile,
}: {
  logo: string;
  title: string;
  subtile: string;
}) => {
  const [posterFile, setPosterFile] = useState<File | undefined>();
  const [poster, setPoster] = useState<string>();
  const dispatch = useAppDispatch();

  // const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       setPoster(e.target?.result as string);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const {
    onboardingType,
    abstractionFlow,
    onBoardingAppDetails,
    isEditingOnboardingApp,
    projectName,
    customization,
  } = useAppSelector((state) => ({
    onboardingType: state.onBoardingState.onBoardingType,
    abstractionFlow: state.onBoardingState.abstractionFlow,
    onBoardingRecord: state.onBoardingState.onBoardingRecord,
    isEditingOnboardingApp: state.onBoardingState.isEditingOnboardingApp,
    onBoardingAppDetails: state.appState.onBoardingAppDetails,
    projectName: state.onBoardingState.projectName,
    customization: state.onBoardingState.customization,
  }));

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    dispatch(updateBannerFile(file));
    if (file) {
      const logoUrl = URL.createObjectURL(file);
      setPoster(logoUrl);
    }
  };

  const fileInput = useRef<any>(null);

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const logoUrl = URL.createObjectURL(file);
      setPoster(logoUrl);
      dispatch(updateBannerFile(file));
      // setPosterFile(file);
    };
    reader.readAsDataURL(file);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    maxSize: 1048576, // 1 MB
  });

  useMemo(() => {
    if (customization.bannerFile) {
      const logoUrl = URL.createObjectURL(customization.bannerFile);
      setPoster(logoUrl);
    }
  }, [customization.bannerFile]);

  useMemo(() => {
    if(onBoardingAppDetails?.data.poster && isEditingOnboardingApp){
      setPoster(onBoardingAppDetails?.data.poster);
    }
  }, [isEditingOnboardingApp]);


  return (
    <div>
      <div className={``}>
        {poster ? (
          <img
            src={poster}
            className="h-[323px] w-[549] object-contain rounded-lg"
          />
        ) : (
          <div className="w-[459px] bg-[#26262E] p-4 rounded-lg">
            <div>
              <p className="text-lg font-semibold text-white">{projectName}</p>
              <p className="text-lg text-white">
                {onboardingType == OnBoardingType.STAKE &&
                  `Stake and get Rewards`}
                {onboardingType == OnBoardingType.SWAP &&
                  `Swap and get Rewards`}
                {onboardingType == OnBoardingType.LEND &&
                  `Lend and get Rewards`}
              </p>
            </div>
            <div className="bg-[#12101B] pt-[32px]">
              <div className="flex justify-center">
                {logo ? (
                  <img src={logo} className="w-[80px]" />
                ) : (
                  <div className="w-[187px] flex justify-center items-center h-[60px]  border border-dashed border-spacing-1 rounded-lg">
                    <p className="text-xl font-semibold">Logo</p>
                  </div>
                )}
              </div>
              <div className="flex flex-col items-center justify-center gap-2 py-6">
                <p className="text-[28px] font-semibold text-white">{title}</p>
                <p className="text-lg text-white">{subtile}</p>
                {/* <p className="text-lg text-white">{title}</p>
                <p className="text-lg text-white">{subtile}</p> */}
              </div>
            </div>
            <div className="flex items-center justify-center w-full bg-white">
              <img
                src="/assets/logo_black.svg"
                className="w-[145px] h-[21px]"
                alt="powered by townesquare"
              />
            </div>
          </div>
        )}
        <div {...getRootProps()} className="flex flex-col gap-4 mt-4">
          <p className="text-[#FFFFFF] text-[20px] font-semibold">
            OR upload your poster
          </p>
          <div className="flex items-center justify-center gap-2">
            {poster ? (
              <div className="flex items-center justify-between w-full px-5">
                <div className="flex items-center justify-center gap-2">
                  <img src={poster} className="w-[102px] h-[32]" />
                  {/* <p className="text-[15px] text-[#CCC]">{posterFile?.name}</p> */}
                </div>
                <button
                  onClick={() => {
                    setPoster("");
                    dispatch(updateBannerFile(undefined))
                  }}
                  className="outline-none text-[#FF4471] text-[14px] font-semibold"
                >
                  Remove
                </button>
              </div>
            ) : (
              <img
                onClick={() => fileInput.current.click()}
                className="h-[60px] w-[549px]"
                src="/assets/upload-poster.svg"
              />
            )}
            <input
              ref={fileInput}
              type="file"
              accept="image/*"
              onChange={handleUpload}
              className="hidden"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThumbnailUpload;
