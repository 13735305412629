import React, { useEffect, useState } from "react";
import { useAppSelector } from "../state/hooks";
import UserMenu from "./UserButton";
import { useNavigate, NavLink } from "react-router-dom";
import { useAccount } from "wagmi";
import SelectProtocolModal from "./SelectProtocolModal";
import { getSession } from "../utils/helper";
import { useDispatch } from "react-redux";
import {
  updateProjectName,
} from "../state/onboarding/onboarding";
import { updateIsProjectClaim, updateProtocolId } from "../state/app";

const OnBoardingHeader = ({
  showProjectName = true,
  isModularPage
}: {
  showProjectName: boolean;
  isModularPage?: boolean
}) => {
  const projectName = useAppSelector(
    (state) => state.onBoardingState.projectName
  );
  const protocolId = useAppSelector(
    (state) => state.appState.protocolId
  );


  const navigate = useNavigate();
  const handleTabClick = () => {
    navigate("/Explore");
  };

  const account = useAccount();
  const dispatch = useDispatch();

  useEffect(() => {
    const { protocolId, protocolName, isClaimedProtocol } = getSession();
    dispatch(updateProtocolId(protocolId));
    dispatch(updateProjectName(protocolName));
    dispatch(updateIsProjectClaim(isClaimedProtocol));
  }, [protocolId]);

  return (
    <div className="z-40 flex items-center justify-between py-4 px-[24px] mb-2">
      <a href="https://www.townesquare.xyz/">
        <img
          src="/assets/ts_logo.svg"
          className="h-[30px] w-[160px]"
          alt="Townesquare Logo"
        />
      </a>
      <div className="flex gap-6">
        <NavLink
          to="/"
          className={({ isActive, isPending }) =>
            isActive
              ? "text-primary-light cursor-pointer hover:text-link-hover active:text-link-active text-base font-Regular"
              : isPending
              ? "text-white cursor-pointer text-base font-semibold"
              : "text-white text-base hover:text-link-secondary-hover active:text-link-secondary-active"
          }
        >
          DApp Abstraction
        </NavLink>
        <NavLink
          to="/modular"
          className={({ isActive, isPending }) =>
            isActive
              ? "text-primary-light cursor-pointer text-base hover:text-link-hover active:text-link-active  font-Regular"
              : isPending
              ? "text-white cursor-pointer text-base font-semibold"
              : "text-white text-base font-Regular hover:text-link-secondary-hover active:text-link-secondary-active"
          }
        >
          Modular Features Library
        </NavLink>
        {/* <NavLink
          to="/Explore"
          className={({ isActive, isPending }) =>
            isActive
              ? "text-primary-light cursor-pointer text-base font-Regular"
              : isPending
              ? "text-white cursor-pointer text-base font-semibold"
              : "text-white text-base font-Regular"
          }
        >
          Explore protocols
        </NavLink> */}
      </div>
      <div className="relative flex items-center justify-center gap-4">
        <div className="absolute right-44" >
          <SelectProtocolModal showProjectName={showProjectName} />
        </div>
        <UserMenu isModularPage={isModularPage} />
      </div>
    </div>
  );
};

export default OnBoardingHeader;
