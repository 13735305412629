import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { toggleAddModularFeature } from "../../api/modular/route";

interface AddFeatureModalProps {
  isOpen: boolean;
  onClose: () => void;
  projectId?: string;
}

const AddFeatureModal: React.FC<AddFeatureModalProps> = ({
  isOpen,
  onClose,
  projectId,
}) => {
  const [selectedFeature, setSelectedFeature] = useState<string | null>(null);
  const [blockchain, setBlockchain] = useState(""); // New field for Blockchain
  const [protocolName, setProtocolName] = useState(""); // New field for Protocol Name
  const [fromAsset, setFromAsset] = useState("");
  const [fromAssetAddress, setFromAssetAddress] = useState("");
  const [fromAssetLogo, setFromAssetLogo] = useState("");
  const [fromAssetDecimal, setFromAssetDecimal] = useState("");
  const [toAsset, setToAsset] = useState("");
  const [toAssetAddress, setToAssetAddress] = useState("");
  const [toAssetDecimal, setToAssetDecimal] = useState("");
  const [toAssetLogo, setToAssetLogo] = useState("");
  const [fromBlockchain, setFromBlockchain] = useState("");
  const [fromBlockchainId, setFromBlockchainId] = useState("");
  const [toBlockchain, setToBlockchain] = useState("");
  const [toBlockchainId, setToBlockchainId] = useState("");
  const [smartContractAddress, setSmartContractAddress] = useState("");
  const [asset, setAsset] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleFeatureChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFeature(e.target.value);
  };

  const handleListFeature = async () => {
    // Simulate listing the feature, trigger the success modal
    await toggleAddModularFeature({
      project_id: projectId as string,
      category: selectedFeature as string,
      chain: fromBlockchainId,
      from_asset: fromAsset,
      from_asset_logo: fromAssetLogo,
      from_asset_address: fromAssetAddress,
      from_asset_decimal: fromAssetDecimal,
      from_network: fromBlockchain,
      to_asset: toAsset,
      to_asset_logo: toAssetLogo,
      to_asset_address: toAssetAddress,
      to_asset_decimal: toAssetDecimal,
      to_network: toBlockchain,
      to_network_id: toBlockchainId,
      contract: smartContractAddress
    });

    setShowSuccessModal(true);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    onClose(); // Close the main modal after success
  };

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-75 ${
        isOpen ? "flex" : "hidden"
      } items-center justify-center`}
    >
      <div className="border-gray-light-3 flex flex-col gap-2 bg-[#222222] border text-white rounded-lg p-6 max-w-[598px] w-full max-h-[800px] overflow-y-auto overflow-hidden">
        <div className="flex items-center justify-between">
          <h2 className="text-white text-[15px] font-semibold uppercase">
            {projectId} Feature
          </h2>
          <button onClick={onClose} className="text-xl">
            <IoMdClose size={24} />
          </button>
        </div>
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-bold">Add a Feature </h2>
        </div>

        <div className="flex items-center justify-between">
          {/* Blockchain Field */}
          <div className="flex flex-col gap-2">
            <label className="block text-[15px]">Blockchain network</label>
            <input
              disabled
              type="text"
              className="h-[46px] border px-4 bg-gray-dark-3 border-gray-light-3 w-[254px] disabled:opacity-40 rounded-[40px]"
              value={"Monad"}
              onChange={(e) => setBlockchain(e.target.value)}
            />
          </div>

          {/* Protocol Name Field */}
          <div className="flex flex-col gap-2">
            <label className="block text-[15px]">Protocol name</label>
            <input
              disabled
              type="text"
              className="h-[46px] border px-4 bg-gray-dark-3 border-gray-light-3 w-[254px] disabled:opacity-40 rounded-[40px]"
              value={projectId}
              onChange={(e) => setProtocolName(e.target.value)}
            />
          </div>
        </div>

        {/* Feature selection */}
        <div className="flex flex-col gap-2">
          <label className="block text-[15px]">Feature</label>
          <select
            className="w-full px-4 h-[46px] bg-gray-dark-3 appearance-none outline-none border-gray-light-3 rounded-[40px] border"
            onChange={handleFeatureChange}
            value={selectedFeature || ""}
          >
            <option value="">Choose feature</option>
            <option value="Swap">Swap</option>
            <option value="Stake">Stake</option>
            <option value="Lend">Lend</option>
            <option value="Bridge">Bridge</option>
          </select>
        </div>

        {/* Render additional fields based on feature selection */}
        {selectedFeature && selectedFeature !== "Bridge" && selectedFeature && selectedFeature !== "Swap" && (
          <div className="mb-4">
            <label className="block mb-2">
              {selectedFeature === "Stake" || selectedFeature === "Lend"
                ? `${selectedFeature} Asset`
                : "Swap Asset"}
            </label>
            <input
              type="text"
              placeholder="Insert Asset"
              className="w-full h-[46px] placeholder:text-gray-light-3 px-4 bg-gray-dark-3 appearance-none outline-none border-gray-light-3 rounded-[40px] border"
              value={asset}
              onChange={(e) => setAsset(e.target.value)}
            />
          </div>
        )}

        {(selectedFeature === "Bridge" || selectedFeature === "Swap") && (
          <>
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block mb-2">From Asset</label>
                <input
                  type="text"
                  className="w-full p-2 bg-gray-800 rounded"
                  value={fromAsset}
                  placeholder="USDC"
                  onChange={(e) => setFromAsset(e.target.value)}
                />
              </div>
              <div>
                <label className="block mb-2">From Asset Address</label>
                <input
                  type="text"
                  className="w-full p-2 bg-gray-800 rounded"
                  value={fromAssetAddress}
                  placeholder="0xa0b...b48"
                  onChange={(e) => setFromAssetAddress(e.target.value)}
                />
              </div>
              <div>
                <label className="block mb-2">From Asset Logo</label>
                <input
                  type="text"
                  className="w-full p-2 bg-gray-800 rounded"
                  value={fromAssetLogo}
                  placeholder="URL"
                  onChange={(e) => setFromAssetLogo(e.target.value)}
                />
              </div>
              <div>
                <label className="block mb-2">From Asset Decimal</label>
                <input
                  type="text"
                  className="w-full p-2 bg-gray-800 rounded"
                  value={fromAssetDecimal}
                  placeholder="6"
                  onChange={(e) => setFromAssetDecimal(e.target.value)}
                />
              </div>
              <div>
                <label className="block mb-2">From Blockchain</label>
                <input
                  type="text"
                  className="w-full p-2 bg-gray-800 rounded"
                  value={fromBlockchain}
                  placeholder="ETH"
                  onChange={(e) => setFromBlockchain(e.target.value)}
                />
              </div>
              <div>
                <label className="block mb-2">From Blockchain ID</label>
                <input
                  type="text"
                  className="w-full p-2 bg-gray-800 rounded"
                  value={fromBlockchainId}
                  placeholder="1"
                  onChange={(e) => setFromBlockchainId(e.target.value)}
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block mb-2">To Asset</label>
                <input
                  type="text"
                  className="w-full p-2 bg-gray-800 rounded"
                  value={toAsset}
                  placeholder="USDT"
                  onChange={(e) => setToAsset(e.target.value)}
                />
              </div>
              <div>
                <label className="block mb-2">To Asset Address</label>
                <input
                  type="text"
                  className="w-full p-2 bg-gray-800 rounded"
                  value={toAssetAddress}
                  placeholder="0xdac...ec7"
                  onChange={(e) => setToAssetAddress(e.target.value)}
                />
              </div>
              <div>
                <label className="block mb-2">To Asset Logo</label>
                <input
                  type="text"
                  className="w-full p-2 bg-gray-800 rounded"
                  value={toAssetLogo}
                  placeholder="URL"
                  onChange={(e) => setToAssetLogo(e.target.value)}
                />
              </div>
              <div>
                <label className="block mb-2">To Asset Decimal</label>
                <input
                  type="text"
                  className="w-full p-2 bg-gray-800 rounded"
                  value={toAssetDecimal}
                  placeholder="6"
                  onChange={(e) => setToAssetDecimal(e.target.value)}
                />
              </div>
              <div>
                <label className="block mb-2">To Blockchain</label>
                <input
                  type="text"
                  className="w-full p-2 bg-gray-800 rounded"
                  value={toBlockchain}
                  placeholder="ETH"
                  onChange={(e) => setToBlockchain(e.target.value)}
                />
              </div>
              <div>
                <label className="block mb-2">To Blockchain ID</label>
                <input
                  type="text"
                  className="w-full p-2 bg-gray-800 rounded"
                  value={toBlockchainId}
                  placeholder="1"
                  onChange={(e) => setToBlockchainId(e.target.value)}
                />
              </div>
            </div>
          </>
        )}

        {selectedFeature && (
          <div className="mb-4">
            <label className="block mb-2">
              <h1 className="text-[15px] text-white">Smart Contract Address</h1>
              <p className="text-[15px] text-white">Insert all relevant smart contract addresses</p>
            </label>
            <textarea
              className="w-full h-[126px] placeholder:text-gray-light-3 py-2 px-4 border-gray-light-3 border outline-none bg-gray-dark-3 rounded-lg"
              placeholder="Insert smart contract addresses"
              value={smartContractAddress}
              onChange={(e) => setSmartContractAddress(e.target.value)}
            />
          </div>
        )}

        {/* List Feature Button */}
        <div className="flex justify-center">
          <button
            onClick={handleListFeature}
            className="w-[125px] text-[15px] font-medium h-[36px] rounded-[40px] outline-none text-white bg-primary-default"
          >
            Add a Feature
          </button>
        </div>

        {/* Success modal */}
        {showSuccessModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75">
            <div className="bg-gray-900 text-white rounded-lg w-[400px] p-6 text-center">
              <div className="mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-12 h-12 mx-auto mb-2 text-purple-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 12l2 2 4-4M7 12l2-2m4 2l4 4m0 4l-4-4M2 12l4-4m0 4l4-4"
                  />
                </svg>
                <h2 className="text-xl font-bold">
                  DApp abstraction in progress
                </h2>
                <p>
                  Integration of the new feature is in progress. It should show
                  up under the respective protocol in a few days. Stay tuned.
                </p>
              </div>
              <button
                className="w-full p-3 text-white bg-purple-600 rounded"
                onClick={handleCloseSuccessModal}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddFeatureModal;
