import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchOnbaordingAppResponse } from "../api/types";
import { handleFetchOnboardingAppDetails } from "./action";
import { Customization } from "./onboarding/onboarding";
import { THEME } from "./types";

interface AppState {
  memberRole: {
    role: string;
    roleId: number;
  };
  isProjectClaim: boolean;
  onBoardingAppDetails: FetchOnbaordingAppResponse | null;
  protocolId: number;
  previousCustomization: Customization
}

const initialState: AppState = {
  memberRole: {
    role: "Menber",
    roleId: 2,
  },
  isProjectClaim: false,
  onBoardingAppDetails: null,
  protocolId: 0,
  previousCustomization:  {
      file: undefined,
      onboardingId: "",
      theme: THEME.DARK,
      pageColor: "",
      title: "",
      subtitle: "",
      website: "",
      accentColor: "",
      bannerFile: undefined,
      name: "",
    },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setMemberRole: (
      state,
      action: PayloadAction<{
        role: string;
        roleId: number;
      }>
    ) => {
      state.memberRole = action.payload;
    },
    updateIsProjectClaim: (state, action: PayloadAction<boolean>) => {
      state.isProjectClaim = action.payload;
    },
    updateProtocolId: (state, action: PayloadAction<number>) => {
      state.protocolId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      handleFetchOnboardingAppDetails.fulfilled,
      (state, action) => {
        state.onBoardingAppDetails = action.payload;
        state.previousCustomization = {
          file: undefined,
          pageColor: action.payload.data.page_color,
          accentColor: action.payload.data.accent_color,
          onboardingId: String(action.payload.data.id),
          theme: action.payload.data.theme,
          title: action.payload.data.title,
          subtitle: action.payload.data.subtitle,
          website: action.payload.data.website,
          bannerFile: undefined,
          name: action.payload.data.name,
        };
      }
    );
  },
});

export const { setMemberRole, updateIsProjectClaim, updateProtocolId } =
  appSlice.actions;
export default appSlice.reducer;
