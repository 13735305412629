import { OnboardingAction } from "../constants/actions";
import { AbstractionData } from "../state/onboarding/onboarding";
import { onboardingAction, THEME } from "../state/types";

interface userData {
  id: string;
  company_id: string;
  email: string;
  auth_provider: string;
  provider_id: string;
  created_at: string;
  updated_at: string;
}

export interface checkUserResponse {
  statusCode: number;
  data: userData;
}

export enum walletType {
  Metamask = "metamask",
  Phantom = "phantom",
  BackPack = "backpack",
}

export interface signUpResponse {
  statusCode: number;
  message?: string;
  data: {
    message?: string;
    token: string;
  };
  error?: any;
}

export interface signInResponse {
  statusCode: number;
  message?: string;
  data: {
    message?: string;
    token: string;
  };
  error?: any;
}

export type GetAllChainResponse = {
  statusCode: string;
  data: listOfChain[];
};

export type GetAssetsResponse = {
  statusCode: string;
  data: {
    bridge: BridgeResponseInterface[];
    swap: SwapResponseInterface[];
    stake: StakeResponseInterface[];
    lend: LendResponseInterface[];
  };
};

export type listOfChain = {
  name: string;
  symbol: string;
  icon: string;
  chainID: number;
  slug: string;
  nativeAsset: string;
  addressType: string;
  isBorrowStatusActive: boolean;
  isLendStatusActive: boolean;
  isStakeStatusActive: boolean;
  isSwapStatusActive: boolean;
  assets: Asset[];
};

export type Asset = {
  name: string;
  symbol: string;
  slug: string;
  icon: string;
  decimal: number;
  address: string;
  isNativeasset: boolean;
  chain: string;
  destinations: Destination[];
};

export type Pairs = {
  destination_asset: string;
  destination_asset_logo: string;
  destination_asset_address: string;
  destination_asset_network: string;
  destination_asset_chain: string;
  destination_asset_network_logo: string;
};

export type SwapPairs = {
  destination_asset_network: string;
  destination_network_logo:string
  destination_network_id:string
  assets: SwapAssets[];
};

export type SwapAssets = {
  destination_asset: string;
  destination_asset_logo: string;
  destination_asset_address: string;
  destination_asset_network: string;
  destination_asset_chain: string;
};

export type SourceAssets = {
  source_name: string;
  source_symbol: string;
  source_slug: string;
  source_icon: string;
  source_decimal: string;
  source_address: string;
  isNativeasset: boolean;
  destinations: StakeDestination;
};

export type StakeSource = {
  source_network: string;
  source_network_icon: string;
  source_asset: SourceAssets[];
};

export type StakeDestination = {
  dest_network: string;
  dest_network_icon: string;
  dest_name: string;
  dest_network_chain: string;
  dest_symbol: string;
  dest_slug: string;
  dest_icon: string;
  dest_decimal: string;
  dest_address: string;
};

export type LendDestination = {
  dest_network: string;
  dest_network_icon: string;
  dest_network_chain: string;
  dest_assets: LendDestAsset[];
};

export type LendDestAsset = {
  dest_name: string;
  dest_symbol: string;
  dest_slug: string;
  dest_icon: string;
  dest_decimal: string;
  dest_address: string;
};

export type BasePair = {
  chain: string;
  source_asset_network: string;
  source_asset_network_logo: string;
  source_asset: string;
  source_asset_logo: string;
  source_asset_address: string;
  pairs: SwapPairs[];
};

export type StakeAssets = {
  onboarding_name: string;
  onboardingsource_symbol: string;
  onboarding_slug: string;
  onboarding_icon: string;
  onboarding_decimal: string;
  onboarding_address: string;
  source: StakeSource[];
};

export type LendAssets = {
  source_name: string;
  source_symbol: string;
  source_slug: string;
  source_icon: string;
  source_decimal: string;
  source_address: string;
  isNativeasset: false;
  destinations: LendDestination[];
};
export type Destination = {
  chain: string;
  network_logo_url: string;
  chainID: string;
  assets: Token[];
};

export type Token = {
  name: string;
  symbol?: string;
  slug: string;
  icon: string;
  decimal: number;
  address: string;
  chain: string;
};

export type FetchProjectRespomseData = {
  statusCode: number;
  data: Protocol[];
};

// interface Protocol {
//   actions: {
//     id: number;
//     project_id: number;
//     action: string;
//   }[];
//   id: number;
//   title: string;
//   is_claimed: boolean;
//   company_id: number | null;
//   created_at: string;
//   updated_at: string;
// }

export type FetchAllOnboardingAppByProjectIdResponse = {
  statusCode: number;
  data: FetchAllOnboardingAppByProjectIdData[];
};

export type FetchAllOnboardingAppByProjectIdData = {
  id: number;
  name: string;
  project_id: number;
  onboarding_type: string;
  onboarding_link: string | null;
  apr: string | null;
  min_amount: number | null;
  max_amount: number | null;
  redirect_url: string | null;
  source_chain: string | null;
  source_asset: string | null;
  dest_chain: string | null;
  dest_asset: string | null;
  theme: string | null;
  page_color: string | null;
  accent_color: string | null;
  logo: string | null;
  title: string | null;
  subtitle: string | null;
  website: string | null;
  poster: string | null;
  is_active: boolean;
  is_archived: boolean;
  flow: null;
  banner: string | null;
  company_id: string | null;
  abstraction_flow: AbstractionData | null;
  created_at: string;
  updated_at: string;
  generate_link: string;
  bonus_percentage:number
  __project__: {
    id: number;
    title: string;
    is_claimed: boolean;
    company_id: string | null;
    description: string;
    logo: string;
    created_at: string;
    updated_at: string;
  };
  asset_images: {
    source_asset_icon: string;
    dest_asset_icon: string;
    source_asset_symbol: string;
    dest_asset_symbol: string;
  } | null;
};
export interface ApiResponse<T> {
  statusCode: number;
  data: T;
  message: string;
  error: any;
}

export interface Protocol {
  id: number;
  title: string;
  description: string;
  logo: string;
  icon: string;
  company_id: string;
  __onboarding__: Onboarding[];
  onboardingCount: number;
  is_claimed: boolean;
  created_at: string;
  updated_at: string;
  actions: ProtocolAction[];
  networks: ProtocolNetwork[];
}

export interface Onboarding {
  id: number;
  protocol_id: number;
  project_id: number;
  onboarding_type: string;
  onboarding_link: string | null;
  apr: number | null;
  min_amount: number;
  max_amount: number;
  redirect_url: string;
  source_chain: string;
  source_asset: string;
  dest_chain: string;
  dest_asset: string;
  theme: string | null;
  page_color: string | null;
  accent_color: string | null;
  logo: string | null;
  title: string | null;
  subtitle: string | null;
  website: string | null;
  poster: string | null;
  is_active: boolean;
  flow: number[] | null;
  banner: string | null;
  company_id: string;
  abstraction_flow: string | null;
  source_asset_address: string;
}

interface ProtocolNetwork {
  id: number;
  protocol_id: number;
  chain: string;
  logo: string;
}

export interface ProtocolAction {
  id: number;
  protocol_id: number;
  action: onboardingAction;
  isLive: boolean;
}

export interface ProtocolActionByProtocolIdResponse {
  data: ProtocolAction[];
}

interface Network {
  id: number;
  name: string;
  icon: string;
  chain_id: string;
  chain_symbol: string;
  is_mainnet: boolean;
}

export type FetchSingleOnbaordingAppResponse = {
  data: {
    id: number | null;
    project_id: number | null;
    onboarding_type: string;
    onboarding_link: string | null;
    apr: string | null;
    min_amount: number | null;
    max_amount: number | null;
    redirect_url: string;
    source_chain: string;
    source_asset: string;
    dest_chain: string;
    dest_asset: string;
    theme: string;
    page_color: string;
    accent_color: string;
    logo: string;
    title: string;
    subtitle: string;
    website: string;
    poster: null;
    is_active: boolean;
    flow: null;
    banner: string;
    company_id: string;
    abstraction_flow: null;
    source_asset_address: `0x${string}` | undefined;
  };
};
export interface OnboardingApp {
  id: number;
  project_id: number;
  onboarding_type: string;
  onboarding_link: string | null;
  apr: number | null;
  min_amount: number;
  max_amount: number;
  redirect_url: string;
  source_chain: string;
  source_asset: string;
  dest_chain: string;
  dest_asset: string;
  theme: string | null;
  page_color: string | null;
  accent_color: string | null;
  logo: string | null;
  title: string | null;
  subtitle: string | null;
  website: string | null;
  poster: string | null;
  is_active: boolean;
  flow: number[] | null;
  banner: string | null;
  company_id: string;
  abstraction_flow: string | null;
  __project__: OnboardingProject;
  __has_project__: boolean;
  generate_link: string;
  source_asset_address: string;
  asset_images: AssetImages;
}

interface OnboardingProject {
  id: number;
  title: string;
  is_claimed: boolean;
  company_id: string;
  description: string;
  logo: string;
  created_at: string;
  updated_at: string;
}

interface AssetImages {
  source_asset_icon: string;
  dest_asset_icon: string;
}

export enum RoleTitle {
  NO_ROLE = "",
  ADMIN = "Admin",
  MEMBER = "Member",
}

export enum RoleId {
  NO_ROLE = 0,
  ADMIN = 1,
  MEMBER = 2,
}

export type Roles = {
  id: RoleId;
  title: RoleTitle;
  created_at: string;
  updated_at: string;
};

export type GetMembersListResponse = {
  statusCode: number;
  data: [
    {
      id: string;
      company_id: string;
      email: string;
      auth_provider: string;
      provider_id: string;
      created_at: string;
      updated_at: string;
      is_super_admin: boolean;
      __roles__: Roles[];
    }
  ];
};

export type UserDetailsResponse = {
  statusCode: 200;
  message: "Request successful";
  data: {
    id: string;
    company_id: string;
    email: null;
    auth_provider: walletType;
    provider_id: string;
    created_at: string;
    updated_at: string;
    is_super_admin: boolean;
    __company__: {
      id: string;
      title: null;
      admin_id: string;
      created_at: string;
      updated_at: string;
      __projects__: {
        id: number;
        title: string;
        is_claimed: string;
        description: string;
        logo: "";
        created_at: string;
        updated_at: string;
      }[];
    };
    __roles__: Roles[];
  };
  error: null;
};

export type FetchOnbaordingAppResponse = {
  data: {
    id: number | null;
    project_id: number | null;
    onboarding_type: string;
    generate_link: string | null;
    apr: string | null;
    min_amount: number | null;
    max_amount: number | null;
    redirect_url: string;
    source_chain: string;
    source_asset: string;
    dest_chain: string;
    dest_asset: string;
    theme: THEME.DARK;
    page_color: string;
    accent_color: string;
    logo: string;
    title: string;
    subtitle: string;
    website: string;
    poster: null;
    is_active: boolean;
    name: string;
    banner: string;
    company_id: string;
    abstraction_flow: AbstractionData | null;
    source_asset_address: `0x${string}` | undefined;
    asset_images: {
      source_asset_icon: string;
      dest_asset_icon: string;
      source_asset_symbol: string;
      dest_asset_symbol: string;
    } | null;
  };
};

export type BridgeResponseInterface = {
  name: string;
  symbol: string;
  icon: string;
  chainID: string;
  slug: string;
  nativeAsset: string;
  addressType: string;
  isBorrowStatusActive: boolean;
  isLendStatusActive: boolean;
  isStakeStatusActive: boolean;
  isSwapStatusActive: boolean;
  assets: Asset[];
};

export interface SwapResponseInterface {
  chain: string;
  source_asset_network: string;
  source_asset_network_logo: string;
  basePair: BasePair[];
}

export interface StakeResponseInterface {
  onboarding_network: string;
  onboarding_network_icon: string;
  onboarding_network_chainID: string;
  onboarding_network_slug: string;
  assets: StakeAssets[];
}

export interface LendResponseInterface {
  onboarding_network: string;
  onboarding_network_icon: string;
  onboarding_network_chainID: string;
  onboarding_network_slug: string;
  assets: LendAssets[];
}
