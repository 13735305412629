import React from "react";
import { AiFillThunderbolt, AiOutlineArrowRight } from "react-icons/ai";
import { MdOutlineStar } from "react-icons/md";

interface LoginButtonProps {
  title: string;
  logo: string;
  onClick: () => void;
  showBoost?: boolean;
  boost?: number;
  recommend?:boolean
}

const LoginButton: React.FC<LoginButtonProps> = ({
  title,
  logo,
  onClick,
  showBoost,
  boost,
  recommend = false
}) => {
  return (
    <button
      onClick={() => {
        onClick()
      }}
      className="flex h-[58px] items-center px-5 justify-between w-[493px] outline-none"
    >
      <div className="flex items-center gap-4">
        <img src={logo} className="rounded-full w-[34px] h-[34px]" alt={title} />
        <p className="text-base font-medium text-white"> {title}</p>
        {showBoost && (
          <div className="border flex border-[#00EEFD] items-center justify-center gap-1 rounded-[30px] h-[24px] w-[116px]">
            <div className="flex items-center font-bold ">
              <AiFillThunderbolt size={12} color="#00EEFD" />
              <p className="text-[#00EEFD] font-bold text-[11px]">+{boost}%</p>
            </div>
            <p className="text-[11px] text-white font-normal">point boost</p>
          </div>
        )}
         {recommend && (
          <div className="border flex border-[#FFFFFF] items-center justify-center gap-1 rounded-[30px] h-[24px] w-[136px]">
            <div className="flex items-center font-bold ">
              <MdOutlineStar size={12} color="#FFF" />
            </div>
            <p className="text-[11px] text-white font-normal">Recommended wallet</p>
          </div>
        )}
      </div>
      <div>
       <AiOutlineArrowRight color="#B882FF"/>
      </div>
    </button>
  );
};

export default LoginButton;
