import React, { useState, useMemo } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
} from "@headlessui/react";
import { IoCloseOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import {
  useFetchSingleOnboardingApp,
  useGetOnBoardingLink,
} from "../../hooks/projects";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { useMutation } from "@tanstack/react-query";
import { toggleActiveOnBoarding } from "../../api/project/route";
import { useQueryClient } from "@tanstack/react-query";
import { OnBoardingType } from "../../state/types";
import { clearOnBoardingRecord } from "../../state/onboarding/onboarding";

const ActivateOnboardingApp: React.FC = () => {
  const [isActive, setIsActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLinkedCopied, setLinkedIsCopied] = useState(false);
  const queryClient = useQueryClient();
  const protocolId = useAppSelector(
    (state) => state.appState.protocolId
  );
  const dispatch = useAppDispatch()

  const handleActivate = () => {
    setIsActive(!isActive);
    setShowModal(true);
  };

  const handleDeactivate = () => {
    setIsActive(false);
  };

  const onBoardingId = useAppSelector(
    (state) => state.onBoardingState.abstractionFlow?.data.id
  );

  const {
    onboardingType,
    abstractionFlow,
    onBoardingAppDetails,
    isEditingOnboardingApp,
    projectName,
  } = useAppSelector((state) => ({
    onboardingType: state.onBoardingState.onBoardingType,
    abstractionFlow: state.onBoardingState.abstractionFlow,
    onBoardingRecord: state.onBoardingState.onBoardingRecord,
    isEditingOnboardingApp: state.onBoardingState.isEditingOnboardingApp,
    onBoardingAppDetails: state.appState.onBoardingAppDetails,
    projectName: state.onBoardingState.projectName,
  }));

  const navigate = useNavigate();

  const confirmActivate = () => {
    setIsActive(true); // Activate the app
    setShowModal(false);
  };

  const mutation = useMutation({
    mutationFn: async () => {
      if (isEditingOnboardingApp) {
        return await toggleActiveOnBoarding(
          String(onBoardingAppDetails?.data.id)
        );
      } else {
        return await toggleActiveOnBoarding(onBoardingId);
      }
    },
    onSuccess: () => {
      setIsActive(!isActive); // Activate the app
      setShowModal(false);
      queryClient.invalidateQueries({
        queryKey: ["getAllOnboardingFromProjectId", protocolId],
      });
      queryClient.invalidateQueries({
        queryKey: ["fetchSingleOnboardingApp", onBoardingId],
      });
    },
  });

  const close = () => {
    setShowModal(false);
  };

  const handleCopyLink = () => {
    if (isActive) {
      navigator.clipboard.writeText(
        isEditingOnboardingApp
          ? onBoardingAppDetails?.data.generate_link
          : onBoardingLink
      );
      setLinkedIsCopied(true);
      // setShowModal(true);
      setTimeout(() => {
        setLinkedIsCopied(false);
      }, 2000);
    }

    if (!isActive) {
      setShowModal(true);
    }
  };

  const onBoardingLink = useGetOnBoardingLink(onBoardingId).data?.data;
  const onBoardingAppInfo = useFetchSingleOnboardingApp(onBoardingId).data;

  useMemo(() => {
    if (onBoardingAppInfo) {
      setIsActive(onBoardingAppInfo?.is_active);
    }
    if (isEditingOnboardingApp && onBoardingAppDetails?.data.is_active) {
      setIsActive(onBoardingAppDetails?.data.is_active);
    }
  }, [onBoardingAppInfo, onBoardingAppDetails?.data]);

  const ActivateAppDialog = () => {
    return (
      <Dialog
        open={showModal}
        as="div"
        className="relative z-10 focus:outline-none"
        onClose={close}
      >
        <DialogBackdrop className="fixed inset-0 bg-black/70" />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4">
            <DialogPanel
              transition
              className="w-[532px] h-[282px] rounded-xl border border-gray-light-3 bg-dark-gray-2 p-6 py-4 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
            >
              <DialogTitle
                as="div"
                className="flex justify-end mt-2 font-medium text-white text-base/7"
              >
                <button onClick={close}>
                  {" "}
                  <IoCloseOutline className="text-white w-[24px] h-[24px]" />
                </button>
              </DialogTitle>
              <div className="flex flex-col items-center gap-6 mt-2 mb-4">
                <p className="mt-2 text-lg font-semibold text-white">
                  Activate onboarding app for user access
                </p>
                <p className="text-[#CCCCCC] text-base text-center">
                  In order for your users to use the Onboarding app through the
                  Onboarding link, you first need to activate the app.
                </p>
                <div className="flex gap-4 mt-2">
                  <button className="font-semibold outline-none text-primary-light">
                    Continue without activating app
                  </button>
                  {mutation.isPending ? (
                    <>
                      <p>Loading</p>
                    </>
                  ) : (
                    <button
                      onClick={() => {
                        mutation.mutate();
                      }}
                      className="w-[150px] h-[40px] text-[15px] font-medium text-white bg-primary-default rounded-[32px]"
                    >
                      Activate app
                    </button>
                  )}
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    );
  };

  return (
    <div className="relative flex flex-col items-center gap-4 pt-4 pb-5">
      <div className="w-[774px] h-[108px] border bg-gray-light-3/20 border-[#D9D9D9] rounded-2xl flex flex-col gap-1 justify-center px-[32px]">
        <div className="flex justify-between w-full">
          <div className="flex items-center gap-3">
            <p className="text-xl font-semibold text-white">
              Onboarding app is currently
            </p>
            {!isActive ? (
              <p className="flex justify-center items-center rounded-[38px] border-2 text-white bg-gray-light-1/20 h-[38px] w-[93px] border-gray-light-1">
                Inactive
              </p>
            ) : (
              <p className="flex justify-center items-center rounded-[38px] border-2 text-white bg-gray-light-1/20 h-[38px] w-[93px] border-[#2AB576]">
                Active
              </p>
            )}
          </div>
          {!isActive ? (
            <button
              onClick={() => {
                mutation.mutate();
              }}
              className="w-[121px] h-[36px] font-medium text-white bg-primary-default rounded-[40px]"
            >
              Activate app
            </button>
          ) : (
            <button
              onClick={() => {
                mutation.mutate();
              }}
              className=" text-[#FF4471] font-medium"
            >
              Deactivate app
            </button>
          )}
        </div>
        {onboardingType == OnBoardingType.STAKE && (
          <p className="text-[16px] text-gray-light-1">
            Users are able to stake their assets on this onboarding app{" "}
          </p>
        )}
        {onboardingType == OnBoardingType.SWAP && (
          <p className="text-[16px] text-gray-light-1">
            Users are able to swap their assets on this onboarding app{" "}
          </p>
        )}
        {onboardingType == OnBoardingType.LEND && (
          <p className="text-[16px] text-gray-light-1">
            Users are able to lend their assets on this Onboarding app
          </p>
        )}
      </div>
      <div className="w-[774px] h-[322px] bg-gray-light-3/20 border-[#D9D9D9] flex flex-col gap-8 items-center rounded-2xl border px-[32px] py-[40px]">
        <div className="flex flex-col gap-4">
          <p className="text-xl font-semibold text-center text-white">
            Onboarding app sharable link
          </p>
          <p className="text-base text-[#CCCCCC] text-center">
            {" "}
            Share this link on social media or other platforms. When users click
            on it, they will be directed to this onboarding app to interact with
            your protocol.
          </p>
        </div>
        <input
          disabled
          type="text"
          value={
            isEditingOnboardingApp
              ? onBoardingAppDetails?.data.generate_link
              : onBoardingLink
          }
          className="w-[516px] h-[44px] text-white px-[16px] bg-gray-dark-3 rounded-[40px] border"
        />
        <button
          onClick={handleCopyLink}
          className="w-[183px] h-[36px] text-[15px] font-medium text-white bg-primary-default rounded-[40px]"
        >
          Copy onboarding link
        </button>
      </div>
      <div>
        <button
          onClick={() => {
            navigate("/");
            dispatch(clearOnBoardingRecord())
          }}
          className="font-semibold outline-none text-primary-light"
        >
          Go to all onboarding apps
        </button>
      </div>
      <ActivateAppDialog />
      {isLinkedCopied && (
        <div className="absolute bottom-1">
          <img src="/assets/link_copied.svg" />
        </div>
      )}
    </div>
  );
};

export default ActivateOnboardingApp;
