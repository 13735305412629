import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createOnboardingApp } from "../../api/project/route";
import { OnBoardingRecord } from "./onboarding";

export const handleCreateOnBoardingRecord = createAsyncThunk(
  "user/createOnboardingRecord",
  async ({
    protocolId,
    type,
    sourceChain,
    sourceAsset,
    destAsset,
    destChain,
    sourceChainId,
    destinationChainId,
    appName,
  }: {
    protocolId: number;
    type: string;
    appName: string;
    sourceChain?: string;
    sourceAsset?: string;
    destAsset?: string;
    destChain?: string;
    sourceChainId?: string;
    destinationChainId?: string;
  }) => {
    try {
      const response = await createOnboardingApp(
      protocolId,
      type,
      appName,
      sourceChain,
      sourceAsset,
      destAsset,
      destChain,
      sourceChainId,
      destinationChainId
    );

    return response;
    } catch (error) {
      console.log(error, "handle creatttttt")
    }
  }
);
