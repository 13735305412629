import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import OnBoardingHeader from "../../components/OnBoardingHeader";
import { useGetAllProtocols } from "../../hooks/projects";

const ModularFeatures = () => {
  const navigate = useNavigate();

  const protocols = useGetAllProtocols();
  const dapps = protocols.data || [];
  
  const handleNavigation = (dappId: string | number) => {
    navigate(`/modular/${dappId}`, {
      state: {
        protocol: dapps.find((dapp) => dapp.id === dappId),
      },
    }); 
  }

  return (
    <div className="">
      <OnBoardingHeader isModularPage={true} showProjectName={false} />
      <div className="py-2 pb-20 text-white bg-black px-25">
        <h1 className="mb-12 text-[32px] font-bold">Modular Features Library</h1>
        
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:auto-cols-auto">
          {dapps?.map((dapp, index) => (
            <div
              key={index}
              className="flex flex-col justify-between gap-4 h-[200px] px-6 py-8 border cursor-pointer bg-gray-light-3/20 rounded-xl border-gray-light-3"
              onClick={() => handleNavigation(dapp.id)}
            >
              <div className="flex items-center gap-4">
                <img
                  src={dapp.logo}
                  alt={dapp.title}
                  className="w-[47px] h-[47px] object-cover rounded-full"
                />
                <div>
                  <h2 className="text-xl font-semibold">{dapp.title}</h2>
                  <div className="flex grid-col space-x-2 mt-[6px]">
                  {
                    dapp.networks?.map((network, i) => (
                      <div key={i} className="w-[20px] h-[20px] rounded-full border-[1px] border-[#666666] flex items-center justify-center">
                        <img
                          className="w-[12px] h-[12px]"
                          src={network.logo}
                          alt={dapp.title}
                        />
                      </div>
                      
                  ))}
                  </div>
                </div>
              </div>
              <div className="flex justify-start space-x-4">
                {dapp.actions?.map((action, i) => (
                  <button
                    key={i}
                    className="flex-col text-[14px] px-3 py-1 border bg-[#9264F833]/20  border-primary-default text-white rounded-full flex items-center justify-center"
                  >
                    {action.action}
                  </button>
                ))}
                {
                  dapp.actions.length == 0 && (
                    <button
                      className="text-white text-center text-[14px] px-3 py-1 rounded-4xl  border border-white  focus:outline-none"
                    >
                      Coming Soon
                    </button>
                )}  
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ModularFeatures;
