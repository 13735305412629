import React, { useState, useMemo, useEffect } from "react";


import OnBoarding from "../../components/OnBoardingStake";

const OnboardingConfig: React.FC = () => {
  return (
    <>
      <OnBoarding />
    </>
  );
};

export default OnboardingConfig;
