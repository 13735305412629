import axios from 'axios';
import { BACKEND_URL } from '../env';
import { ApiResponse, OnboardingApp, Protocol } from '../types';

export const toggleAddModularFeature = async ({
  project_id,
  category,
  chain,
  from_asset,
  from_asset_logo,
  from_asset_address,
  from_asset_decimal,
  from_network,
  to_asset,
  to_asset_logo,
  to_asset_address,
  to_asset_decimal,
  to_network,
  to_network_id,
  contract
}: {
  project_id: string;
  category: string;
  chain: string;
  from_asset: string;
  from_asset_logo: string;
  from_asset_address: string;
  from_asset_decimal: string;
  from_network: string;
  to_asset: string;
  to_asset_logo: string;
  to_asset_address: string;
  to_asset_decimal: string;
  to_network: string;
  to_network_id: string;
  contract: string;
}) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.post(
      `${BACKEND_URL}api/modular`,
      {
        project_id,
        category,
        chain,
        from_asset,
        from_asset_logo,
        from_asset_address,
        from_asset_decimal,
        from_network,
        to_asset,
        to_asset_logo,
        to_asset_address,
        to_asset_decimal,
        to_network,
        to_network_id,
        contract
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.error(error);
  }
};
