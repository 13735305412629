import axios, { AxiosError } from "axios";
import { BACKEND_URL } from "../env";
import { signInResponse } from "../types";

export const signInWithPassword = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  try {
    return await axios.post("", {
      email,
      auth_provider: "password",
      password,
    });
  } catch (error) {
    console.error(error, "signUp");
  }
};

export const signInithGoogle = async ({
  email,
  provider_id,
}: {
  email: string;
  provider_id: string;
}) => {
  try {
    return await axios.post("", {
      email,
      auth_provider: "google",
      provider_id,
    });
  } catch (error) {
    console.error(error, "signUp");
  }
};

export const signInWithGithub = async ({
  email,
  provider_id,
}: {
  email: string;
  provider_id: string;
}) => {
  try {
    return await axios.post("", {
      email,
      auth_provider: "github",
      provider_id,
    });
  } catch (error) {
    console.error(error, "signUp");
  }
};

export const signInWithWallet = async ({
  accountAddress,
  walletType,
}: {
  accountAddress: string;
  walletType: string;
}): Promise<signInResponse> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL}api/clients/sign-in`,
      {
        auth_provider: walletType,
        provider_id: accountAddress,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return {
      statusCode: response.data.statusCode,
      data: response.data.data,
    };
  } catch (error) {
    return {
      statusCode: 400,
      data: {
        token: "",
      },
      error: error,
    };
  }
};
