import React from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
} from "@headlessui/react";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { toggleOnBoardingAction } from "../state/dialog";
import { useNavigate } from "react-router-dom";
import { ONBOARDING_ACTIONS } from "../constants/actions"; // Import actions here
import { createOnboardingApp } from "../api/project/route";
import {
  updateIsEditingOnboardingApp,
  updateOnBoardingType,
  updateProtocolActions,
  updateSubtitle,
  updateTitle,
} from "../state/onboarding/onboarding";
import { onboardingAction, OnBoardingType } from "../state/types";
import { SessionKeys } from "../utils/helper";
import { useFetchProtocolActionsById } from "../hooks/projects";

const OnBoardingAction: React.FC = () => {
  const { isOpen, projectName, protocolId } = useAppSelector((state) => ({
    isOpen: state.dialogState.onBoardingActionDialog,
    projectName: state.onBoardingState.projectName,
    protocolActions: state.onBoardingState.protocolActions,
    protocolId: state.appState.protocolId,
  }));

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const protocolActions = useFetchProtocolActionsById({
    protocolId: String(protocolId),
  });



  const handleSelectAction = async (action: onboardingAction) => {
    try {
      //navigate( '/CreateOnboardingApp', { state: { selectedType: type } }); // Navigate with the action type as state
      navigate("/CreateOnboardingApp");
      dispatch(toggleOnBoardingAction(false));
      dispatch(updateIsEditingOnboardingApp(false));
      sessionStorage.setItem(
        SessionKeys.isEditingOnboardingApp,
        JSON.stringify(false)
      );
      dispatch(updateSubtitle(""));
      if (action == onboardingAction.STAKE) {
        dispatch(updateOnBoardingType(OnBoardingType.STAKE));
        dispatch(updateTitle("Stake"));
        sessionStorage.setItem(
          SessionKeys.OnBoardingType,
          OnBoardingType.STAKE
        );
      }
      if (action == onboardingAction.SWAP) {
        dispatch(updateOnBoardingType(OnBoardingType.SWAP));
        dispatch(updateTitle("Swap"));
        sessionStorage.setItem(SessionKeys.OnBoardingType, OnBoardingType.SWAP);
      }
      if (action == onboardingAction.LEND) {
        dispatch(updateOnBoardingType(OnBoardingType.LEND));
        dispatch(updateTitle("Lend"));
        sessionStorage.setItem(SessionKeys.OnBoardingType, OnBoardingType.LEND);
      }
      if (action == onboardingAction.BRIDGE) {
        dispatch(updateOnBoardingType(OnBoardingType.SWAP));
        dispatch(updateTitle("Swap"));
        sessionStorage.setItem(SessionKeys.OnBoardingType, OnBoardingType.SWAP);
      }
    } catch (error) {
      console.error("Failed to handle action:", error);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => dispatch(toggleOnBoardingAction(false))}
      className="relative z-50"
    >
      <DialogBackdrop className="fixed inset-0 bg-black/80" />

      <div className="fixed inset-0 flex items-center justify-center w-screen p-4">
        <DialogPanel className="px-9 py-[28px] border rounded-xl border-gray-light-3 w-fit bg-dark-gray-2">
          <DialogTitle
            as="div"
            className="flex items-center justify-between font-bold"
          >
            <p className="text-white text-[15px] font-semibold">
              CREATE NEW ONBOARDING APP
            </p>
            <button
              onClick={() => dispatch(toggleOnBoardingAction(false))}
              className="text-white"
            >
              <img
                src="/assets/close.svg"
                alt="close"
                className="h-[24px] w-[24px]"
              />
            </button>
          </DialogTitle>

          <p className="text-[26px] mt-[12px] font-bold text-white">
            Choose action for{" "}
            <span className="text-primary-default  text-[26px] font-bold">
              {projectName}
            </span>{" "}
            onboarding app
          </p>

          <div className="flex justify-center  gap-4 mt-[48px] mb-[23px]">
            {/* {ONBOARDING_ACTIONS.map((action) => (
              <button
                key={action.type}
                onClick={() => handleSelectAction(action.path, action.type)}
                className="bg-[#121212] rounded-xl w-[162px] border border-gray-light-3 h-[187px] flex flex-col justify-center gap-[26px] items-center"
              >
                <img
                  src={action.logo}
                  className="w-[44px] h-[44px]"
                  alt={`${action.name} icon`}
                />
                <p className="text-base font-semibold text-white">
                  {action.name}
                </p>
              </button>
            ))} */}
            {protocolActions.data &&
              protocolActions.data.map((action) => (
                <button
                  key={action.id}
                  disabled={action.isLive == false}
                  onClick={() => handleSelectAction(action.action)}
                  className="bg-[#121212] relative rounded-xl w-[162px] border border-gray-light-3 h-[187px] flex flex-col justify-center gap-[8px] items-center"
                >
                  <div
                    className={`flex flex-col h-full justify-center ${
                      action.isLive == false && " opacity-50"
                    } gap-[26px]`}
                  >
                    <img
                      src={`/assets/${action.action.toLowerCase()}.svg`}
                      className="w-[44px] h-[44px]"
                      alt={`${action.action} icon`}
                    />
                    <p className="text-base font-semibold text-white">
                      {action.action == onboardingAction.BRIDGE
                        ? "Swap"
                        : `${action.action}`}
                    </p>
                  </div>
                  {action.isLive == false && (
                    <p className="text-[#FFFFFF] absolute bottom-[25px] font-medium text-[13px]">
                      COMING SOON
                    </p>
                  )}
                </button>
              ))}
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default OnBoardingAction;
