import {
  useFetchAssets,
  useFetchOnboardingAppInfo,
  useGetAllChains,
} from "../../hooks/projects";
import { useAccount } from "wagmi";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import {
  Asset,
  BridgeResponseInterface,
  Destination,
  listOfChain,
  SwapResponseInterface,
  Token,
} from "../../api/types";
import { Description, Field, Input, Label } from "@headlessui/react";
import clsx from "clsx";
import { useAppDispatch, useAppSelector } from "../../state/hooks";

import { useState, useEffect, useMemo } from "react";
import {
  updateBridgeTypeDestAsset,
  updateBridgeTypeDestChain,
  updateBridgeTypeSourceAsset,
  updateBridgeTypeSourceChain,
  updateLendOnboardingAssets,
  updateLendTypeDestinationAsset,
  updateLendTypeDestinationChain,
  updateLendTypeOnboardingSourceAssets,
  updateStakeOnboarding,
  updateStakeOnboardingAssets,
  updateStakeTypeDestChain,
  updateStakeTypeSourceAssets,
  updateStakeTypeSourceChain,
  updateSwapTypeDestAsset,
  updateSwapTypeDestChain,
  updateSwapTypeSourceAsset,
  updateSwapTypeSourceChain,
} from "../../state/onboarding/onboarding";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { OnBoardingType } from "../../state/types";
import {
  isBridgeResponse,
  isBridgeSourceAsset,
  isLendResponse,
  isStakeResponse,
  isSwapResponse,
  isSwapSourceAsset,
  protocolResponse,
  trimSpaces,
} from "../../utils/helper";
import { capitalize } from "lodash";
const OnBoarding = () => {
  const [error, setError] = useState<string | null>(null);
  const listOfChain = useGetAllChains();

  const [sourceAsestisOpen, setSourceAssetIsOpen] = useState<boolean>(false);
  const [sourceNetworkisOpen, setSourceNetworkIsOpen] =
    useState<boolean>(false);
  const [destAssetisOpen, setDestAssetIsOpen] = useState<boolean>(false);
  const [destNetworkisOpen, setDestNetworkIsOpen] = useState<boolean>(false);
  const [protocolAssets, setProtocolAssets] = useState<
    BridgeResponseInterface[] | SwapResponseInterface[]
  >();

  const dispatch = useAppDispatch();
  const {
    onBoardingType,
    bridgeTypeSourceAsset,
    bridgeTypeSourceChain,
    bridgeTypeDestinationChain,
    bridgeTypeDestinationAsset,
    isEditingOnboardingApp,
    onboardingId,
    onBoardingAppDetails,
    protocolId,
    swapTypeSourceChain,
    swapTypeSourceAsset,
    swapTypeDestinationChain,
    swapTypeDestinationAsset,
    stakeOnboarding,
    stakeOnboardingAsset,
    stakeTypeSourceChain,
    stakeTypeDestChain,
    stakeTypeSourceAssets,
    lendOnboarding,
    lendTypeOnboardingSourceAssets,
    lendTypeDestChain,
    lendTypeDestAsset,
  } = useAppSelector((state) => ({
    onBoardingType: state.onBoardingState.onBoardingType,
    bridgeTypeSourceAsset:
      state.onBoardingState.onBoardingRecord.bridgeTypesourceAsset,
    bridgeTypeSourceChain:
      state.onBoardingState.onBoardingRecord.bridgeTypesourceChain,
    bridgeTypeDestinationChain:
      state.onBoardingState.onBoardingRecord.bridgeTypedestChain,
    bridgeTypeDestinationAsset:
      state.onBoardingState.onBoardingRecord.bridgeTypedestAsset,
    swapTypeSourceChain:
      state.onBoardingState.onBoardingRecord.swapTypesourceChain,
    swapTypeSourceAsset:
      state.onBoardingState.onBoardingRecord.swapTypesourceAsset,
    swapTypeDestinationChain:
      state.onBoardingState.onBoardingRecord.swapTypedestChain,
    swapTypeDestinationAsset:
      state.onBoardingState.onBoardingRecord.swapTypedestAsset,
    isEditingOnboardingApp: state.onBoardingState.isEditingOnboardingApp,
    onboardingId: state.onBoardingState.onBoardingId,
    onBoardingAppDetails: state.appState.onBoardingAppDetails,
    protocolId: state.appState.protocolId,
    stakeOnboarding: state.onBoardingState.onBoardingRecord.stakeOnboarding,
    stakeOnboardingAsset:
      state.onBoardingState.onBoardingRecord.stakeOnboardingAssets,
    stakeTypeSourceChain:
      state.onBoardingState.onBoardingRecord.stakeTypeSourceChain,
    stakeTypeDestChain:
      state.onBoardingState.onBoardingRecord.stakeTypeDestChain,
    stakeTypeSourceAssets:
      state.onBoardingState.onBoardingRecord.stakeTypeSourceAssets,
    lendOnboarding: state.onBoardingState.onBoardingRecord.lendOnboardingAssets,
    lendTypeOnboardingSourceAssets:
      state.onBoardingState.onBoardingRecord.lendTypeOnboardingSourceAssets,
    lendTypeDestChain: state.onBoardingState.onBoardingRecord.lendTypeDestChain,
    lendTypeDestAsset: state.onBoardingState.onBoardingRecord.lendTypeDestAsset,
  }));

  // const onBoardingAppDetails = useFetchOnboardingAppInfo({
  //   onboardingId: onboardingId,
  // });

  const assets = useFetchAssets({
    protocolId,
    onboardingType: onBoardingType,
  });

  // useMemo(() => {
  //   if (assets.data && onBoardingType == OnBoardingType.SWAP) {
  //     const protocolAssetsResponse = protocolResponse(
  //       protocolId,
  //       assets.data.data
  //     );
  //     setProtocolAssets(protocolAssetsResponse)
  //   }
  // }, [assets.data]);

  return (
    <>
      {isEditingOnboardingApp && onBoardingAppDetails ? (
        <div className="flex flex-col items-center gap-5 mt-10">
          <div className="w-[604px] border border-gray-light-3 rounded-xl justify-evenly flex flex-col px-6 shadow-lg h-[231px] bg-gray-light-3/20">
            <div>
              <p className="text-xl font-semibold text-white">
                Select the onboarding asset
              </p>
              <p className="text-base font-normal text-gray-light-1">
                Choose the asset that users will send
              </p>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex flex-col gap-2">
                <label className="text-white">Blockchain</label>
                <div className="relative">
                  <Listbox
                    as="button"
                    onClick={() => {
                      setSourceNetworkIsOpen(!sourceNetworkisOpen);
                    }}
                    // onChange={setSelectedSourceChain}
                  >
                    <ListboxButton
                      disabled
                      onClick={() => {
                        setSourceNetworkIsOpen(!sourceNetworkisOpen);
                      }}
                      className={`w-[254px] h-[46px] disabled:opacity-40
                        text-white flex items-center gap-2 rounded-[40px] text-left bg-gray-dark-3 border-gray-light-1  border px-5 outline-none `}
                    >
                      {sourceNetworkisOpen ? (
                        <TiArrowSortedUp className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                      ) : (
                        <TiArrowSortedDown className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                      )}
                      {/* {onBoardingAppDetails.data?.source_chain && (
                        <img src={bridgeTypeSourceChain?.icon} className="w-6 h-6" />
                      )} */}
                      {onBoardingAppDetails.data?.source_chain &&
                        capitalize(onBoardingAppDetails.data?.source_chain)}
                    </ListboxButton>
                    {/* <ListboxOptions
                      className="w-[var(--button-width)] mt-1 flex flex-col gap-y-3 bg-black px-4 border rounded-2xl border-white py-3"
                      anchor="bottom end"
                    >
                      {listOfChain.data?.data.map((chain, index) => (
                        <ListboxOption
                          key={index}
                          value={chain}
                          className="data-[focus]:text-primary-light flex items-center gap-x-2 px-1 py-1 cursor-pointer"
                        >
                          <img src={chain.icon} className="w-6 h-6" />
                          <p className="w-full text-white hover:text-primary-light">
                            {chain.name}
                          </p>
                        </ListboxOption>
                      ))}
                    </ListboxOptions> */}
                  </Listbox>
                </div>
              </div>

              {/* Source ASSet */}
              <div className="flex flex-col gap-2">
                <label className="text-white">Asset</label>
                <div className="relative">
                  <Listbox
                    as="button"
                    onClick={() => {
                      setSourceAssetIsOpen(!sourceAsestisOpen);
                    }}
                    // onChange={setSelectedSourceToken}
                  >
                    <ListboxButton
                      onClick={() => {
                        setSourceAssetIsOpen(!sourceAsestisOpen);
                      }}
                      disabled
                      className={`w-[254px] h-[46px] flex items-center gap-2 rounded-[40px]
                      text-white text-left bg-gray-dark-3 border-gray-light-1 disabled:opacity-40  border px-5 outline-none `}
                    >
                      {sourceAsestisOpen ? (
                        <TiArrowSortedUp className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                      ) : (
                        <TiArrowSortedDown className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                      )}

                      {onBoardingAppDetails.data?.asset_images
                        ?.source_asset_icon && (
                        <img
                          className="w-6 h-6 rounded-full"
                          src={
                            onBoardingAppDetails.data?.asset_images
                              ?.source_asset_icon
                          }
                        />
                      )}
                      {onBoardingAppDetails.data?.asset_images
                        ?.source_asset_symbol &&
                        onBoardingAppDetails.data?.asset_images
                          .source_asset_symbol}
                    </ListboxButton>
                    {/* <ListboxOptions
                      className="w-[var(--button-width)] mt-1 flex flex-col gap-y-3 bg-black px-4 border rounded-2xl border-white py-3"
                      anchor="bottom end"
                    >
                      {bridgeTypeSourceChain?.assets &&
                        bridgeTypeSourceChain.assets.map((token, index) => (
                          <ListboxOption
                            key={index}
                            value={token}
                            onClick={() => {
                              dispatch(updateSourceAsset(token));
                            }}
                            className="data-[focus]:text-primary-light flex items-center gap-x-2 px-1 py-1 cursor-pointer"
                          >
                            <img src={token.icon} className="w-6 h-6" />
                            <p className="text-white hover:text-primary-light w-full data-[focus]:text-primary-default">
                              {token.name}
                            </p>
                          </ListboxOption>
                        ))}
                    </ListboxOptions> */}
                  </Listbox>
                </div>
              </div>
            </div>

            {/* <div className="flex items-center justify-between">
          <Field>
            {onBoardingType == OnBoardingType.SWAP && (
              <Label className="font-medium text-white">
                Minium swapping amount
              </Label>
            )}

            {onBoardingType == OnBoardingType.STAKE && (
              <Label className="font-medium text-white">
                Minumum staking amount
              </Label>
            )}

            <Input
              placeholder="Enter Amount"
              type="number"
              value={minAmount}
              onChange={(e) => setMinAmount(e.target.value)}
              className={clsx(
                "mt-3 block w-[254px] appearance-none h-[46px] rounded-[40px] bg-gray-dark-3 border-gray-light-1 border py-1.5 px-3 text-sm/6 outline-none text-white"
              )}
            />
          </Field>
          <Field>
            {onBoardingType == OnBoardingType.STAKE && (
              <Label className="font-medium text-white">
                Maximum staking amount
              </Label>
            )}

            {onBoardingType == OnBoardingType.SWAP && (
              <Label className="font-medium text-white">
                Maximum swapping amount
              </Label>
            )}

            <Input
              placeholder="Enter Amount"
              type="number"
              value={maxAmount}
              onChange={(e) => setMaxAmount(e.target.value)}
              className={clsx(
                "mt-3 block w-[254px] appearance-none h-[46px] rounded-[40px] bg-gray-dark-3 border-gray-light-1 border py-1.5 px-3 text-sm/6 outline-none text-white"
              )}
            />
          </Field>
        </div> */}
            {/* {error && <p className="text-sm font-semibold text-red-500">{error}</p>} */}
          </div>
          <div className="w-[604px] border border-gray-light-3 rounded-xl flex flex-col px-6 shadow-lg justify-evenly h-[252px] bg-gray-light-3/20">
            {onBoardingType === OnBoardingType.STAKE && (
              <div>
                <p className="text-xl font-semibold text-white">
                  Select the staking asset
                </p>
                <p className="text-base font-normal">
                  This is the asset that your protocol accepts as a staking
                  asset. It needs to be the asset that your smart contract
                  accepts:
                </p>
              </div>
            )}
            {onBoardingType === OnBoardingType.SWAP && (
              <div>
                <p className="text-xl font-semibold text-white">
                  Select the swapping asset
                </p>
                <p className="text-base font-normal">
                  This is the asset that your protocol accepts as a swap asset.
                  It needs to be the asset that your smart contract accepts:
                </p>
              </div>
            )}

            {onBoardingType === OnBoardingType.LEND && (
              <div>
                <p className="text-xl font-semibold text-white">
                  Select the swapping asset
                </p>
                <p className="text-base font-normal">
                  This is the asset that your protocol accepts as a Lend asset.
                  It needs to be the asset that your smart contract accepts:
                </p>
              </div>
            )}

            {/* Destination Chain */}
            <div className="flex items-center justify-between">
              <div className="flex flex-col gap-2">
                <label className="text-white">Blockchain</label>
                <div className="relative">
                  {/* <TiArrowSortedDown className="absolute text-white right-5 top-4" /> */}
                  <Listbox
                    as="button"
                    onClick={() => {
                      setDestNetworkIsOpen(!destNetworkisOpen);
                    }}
                    //onChange={setSelectedDestChain}
                  >
                    <ListboxButton
                      disabled
                      onClick={() => {
                        setDestNetworkIsOpen(!destNetworkisOpen);
                      }}
                      className="w-[254px] h-[46px] flex items-center gap-2 rounded-[40px] text-white text-left bg-gray-dark-3 border-gray-light-1 disabled:opacity-40  border px-5 outline-none "
                    >
                      {destNetworkisOpen ? (
                        <TiArrowSortedUp className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                      ) : (
                        <TiArrowSortedDown className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                      )}
                      {/* {onBoardingAppDetails.data?.dest_chain && (
                        <img src={bridgeTypeSourceChain?.icon} className="w-6 h-6" />
                      )} */}
                      {onBoardingAppDetails.data?.dest_chain &&
                        capitalize(onBoardingAppDetails.data?.dest_chain)}
                    </ListboxButton>
                    {/* <ListboxOptions
                      className="w-[var(--button-width)] mt-1 flex flex-col gap-y-3 bg-black px-4 border rounded-2xl border-white py-3"
                      anchor="bottom end"
                    >
                      {bridgeTypeSourceAsset?.destinations.map((destChain, index) => (
                        <ListboxOption
                          key={index}
                          value={destChain}
                          onClick={() => {
                            dispatch(updateDestChain(destChain));
                          }}
                          className="data-[focus]:text-primary-light flex items-center gap-x-2 px-1 py-1 cursor-pointer"
                        >
                          <img src={destChain.icon} className="w-6 h-6" />
                          <p className="w-full text-white hover:text-primary-light">
                            {destChain.chain}
                          </p>
                        </ListboxOption>
                      ))}
                    </ListboxOptions> */}
                  </Listbox>
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <label className="text-white">Asset</label>
                <div className="relative">
                  <Listbox
                    as="button"
                    onClick={() => {
                      setDestAssetIsOpen(!destAssetisOpen);
                    }}
                    //onChange={setSelectedDestAsset}
                  >
                    <ListboxButton
                      disabled
                      onClick={() => {
                        setDestAssetIsOpen(!destAssetisOpen);
                      }}
                      className="w-[254px] h-[46px] flex gap-2 items-center disabled:opacity-40 rounded-[40px] text-white text-left bg-gray-dark-3 border-gray-light-1  border px-5 outline-none "
                    >
                      {destAssetisOpen ? (
                        <TiArrowSortedUp className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                      ) : (
                        <TiArrowSortedDown className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                      )}
                      {onBoardingAppDetails.data?.asset_images
                        ?.dest_asset_icon && (
                        <img
                          className="w-6 h-6 rounded-full"
                          src={
                            onBoardingAppDetails.data?.asset_images
                              ?.dest_asset_icon
                          }
                        />
                      )}
                      {onBoardingAppDetails.data?.asset_images
                        ?.dest_asset_symbol &&
                        onBoardingAppDetails.data?.asset_images
                          .dest_asset_symbol}
                    </ListboxButton>
                    {/* <ListboxOptions
                      className="w-[var(--button-width)] mt-1 flex flex-col gap-y-3 bg-black px-4 border rounded-2xl border-white py-3"
                      anchor="bottom end"
                    >
                      {bridgeTypeDestinationChain?.assets.map((destAsset, index) => (
                        <ListboxOption
                          key={index}
                          value={destAsset}
                          onClick={() => {
                            dispatch(updateDestAsset(destAsset));
                          }}
                          className="data-[focus]:text-primary-default flex items-center gap-x-2 px-1 py-1 cursor-pointer"
                        >
                          <img src={destAsset.icon} className="w-6 h-6" />
                          <p className="w-full text-white hover:text-primary-light">
                            {destAsset.name}
                          </p>
                        </ListboxOption>
                      ))}
                    </ListboxOptions> */}
                  </Listbox>
                </div>
              </div>
            </div>
            {/* <div>
          <p className="text-base text-center">
            Asset you need is not supported yet?{" "}
            <a className="font-normal cursor-pointer text-primary-light">
              {" "}
              Add a feature
            </a>
          </p>
        </div> */}
          </div>
          {/* <div className="w-[604px] border border-gray-light-3 rounded-xl flex flex-col px-6 shadow-lg justify-evenly h-[231px] bg-gray-light-3/20">
        <div>
          <p className="text-xl font-semibold text-white">Redirect</p>
          <p className="text-base font-normal">
            Enter the URL of the page where users will be directed after
            completing staking
          </p>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-2">
            <label className="text-white">Redirect URL</label>
            <input
              type="text"
              value={redirectUrl}
              onChange={(e) => setRedirectUrl(e.target.value)}
              className="w-[540px] h-[46px] text-white text-base border rounded-[40px] px-3 outline-none bg-gray-dark-3 placeholder:text-gray-light-3"
              placeholder="Insert redirect URL"
            />
          </div>
        </div>
      </div> */}
          {/* <button
          onClick={handleSubmit}
          className="h-[46px] w-[180px] text-white rounded-3xl font-medium bg-primary-default"
        >
          Submit
        </button> */}
        </div>
      ) : (
        <>
          {isBridgeResponse(assets.data?.data) && (
            <div className="flex flex-col items-center gap-5 mt-10">
              <div className="w-[604px] border border-gray-light-3 rounded-xl justify-evenly flex flex-col px-6 shadow-lg h-[231px] bg-gray-light-3/20">
                <div>
                  <p className="text-xl font-semibold text-white">
                    Select the onboarding asset
                  </p>
                  <p className="text-base font-normal text-gray-light-1">
                    Choose the asset that users will swap
                  </p>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex flex-col gap-2">
                    <label className="text-white">Blockchain</label>
                    <div className="relative">
                      <Listbox
                        as="button"
                        onClick={() => {
                          setSourceNetworkIsOpen(!sourceNetworkisOpen);
                        }}
                        // onChange={setSelectedSourceChain}
                      >
                        <ListboxButton
                          onClick={() => {
                            setSourceNetworkIsOpen(!sourceNetworkisOpen);
                          }}
                          className={`w-[254px] h-[46px] ${
                            bridgeTypeSourceChain?.name
                              ? "text-white"
                              : "text-gray-light-3"
                          } flex items-center gap-2 rounded-[40px] text-left bg-gray-dark-3 border-gray-light-1  border px-5 outline-none `}
                        >
                          {sourceNetworkisOpen ? (
                            <TiArrowSortedUp className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          ) : (
                            <TiArrowSortedDown className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          )}
                          {bridgeTypeSourceChain && (
                            <img
                              src={bridgeTypeSourceChain?.icon}
                              className="w-6 h-6 rounded-full"
                            />
                          )}
                          {bridgeTypeSourceChain?.name
                            ? bridgeTypeSourceChain?.name
                            : "Choose Network"}
                        </ListboxButton>
                        <ListboxOptions
                          className="w-[var(--button-width)] mt-1 flex flex-col gap-y-3 bg-black px-4 border rounded-2xl border-white py-3"
                          anchor="bottom end"
                        >
                          {assets.data.data.bridge.map((chain, index) => (
                            <ListboxOption
                              key={index}
                              value={chain}
                              onClick={() => {
                                dispatch(updateBridgeTypeSourceChain(chain));
                                dispatch(updateBridgeTypeSourceAsset(null));
                                dispatch(updateBridgeTypeDestChain(null));
                                dispatch(updateBridgeTypeDestAsset(null));
                              }}
                              className="data-[focus]:text-primary-light flex items-center gap-x-2 px-1 py-1 cursor-pointer"
                            >
                              <img src={chain.icon} className="w-6 h-6 rounded-full" />
                              <p className="w-full text-white hover:text-primary-light">
                                {chain.name}
                              </p>
                            </ListboxOption>
                          ))}
                        </ListboxOptions>
                      </Listbox>
                    </div>
                  </div>

                  {/* Source ASSet */}
                  <div className="flex flex-col gap-2">
                    <label className="text-white">Asset</label>
                    <div className="relative">
                      <Listbox
                        as="button"
                        onClick={() => {
                          setSourceAssetIsOpen(!sourceAsestisOpen);
                        }}
                        // onChange={setSelectedSourceToken}
                      >
                        <ListboxButton
                          onClick={() => {
                            setSourceAssetIsOpen(!sourceAsestisOpen);
                          }}
                          className={`w-[254px] h-[46px] flex items-center gap-2 rounded-[40px] ${
                            bridgeTypeSourceAsset?.name
                              ? "text-white"
                              : "text-gray-light-3"
                          }  text-left bg-gray-dark-3 border-gray-light-1  border px-5 outline-none `}
                        >
                          {sourceAsestisOpen ? (
                            <TiArrowSortedUp className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          ) : (
                            <TiArrowSortedDown className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          )}

                          {bridgeTypeSourceAsset?.icon && (
                            <img
                              className="w-6 h-6 rounded-full"
                              src={bridgeTypeSourceAsset.icon}
                            />
                          )}
                          {bridgeTypeSourceAsset?.name
                            ? bridgeTypeSourceAsset.name
                            : "Choose Asset"}
                        </ListboxButton>
                        <ListboxOptions
                          className="w-[var(--button-width)] mt-1 flex flex-col gap-y-3 bg-black px-4 border rounded-2xl border-white py-3"
                          anchor="bottom end"
                        >
                          {bridgeTypeSourceChain?.assets &&
                            bridgeTypeSourceChain.assets.map((token, index) => (
                              <ListboxOption
                                key={index}
                                value={token}
                                onClick={() => {
                                  dispatch(updateBridgeTypeSourceAsset(token));

                                  dispatch(updateBridgeTypeDestChain(null));
                                  dispatch(updateBridgeTypeDestAsset(null));
                                }}
                                className="data-[focus]:text-primary-light flex items-center gap-x-2 px-1 py-1 cursor-pointer"
                              >
                                <img src={token.icon} className="w-6 h-6 rounded-full" />
                                <p className="text-white hover:text-primary-light w-full data-[focus]:text-primary-default">
                                  {token.name}
                                </p>
                              </ListboxOption>
                            ))}
                        </ListboxOptions>
                      </Listbox>
                    </div>
                  </div>
                </div>

                {/* <div className="flex items-center justify-between">
          <Field>
            {onBoardingType == OnBoardingType.SWAP && (
              <Label className="font-medium text-white">
                Minium swapping amount
              </Label>
            )}

            {onBoardingType == OnBoardingType.STAKE && (
              <Label className="font-medium text-white">
                Minumum staking amount
              </Label>
            )}

            <Input
              placeholder="Enter Amount"
              type="number"
              value={minAmount}
              onChange={(e) => setMinAmount(e.target.value)}
              className={clsx(
                "mt-3 block w-[254px] appearance-none h-[46px] rounded-[40px] bg-gray-dark-3 border-gray-light-1 border py-1.5 px-3 text-sm/6 outline-none text-white"
              )}
            />
          </Field>
          <Field>
            {onBoardingType == OnBoardingType.STAKE && (
              <Label className="font-medium text-white">
                Maximum staking amount
              </Label>
            )}

            {onBoardingType == OnBoardingType.SWAP && (
              <Label className="font-medium text-white">
                Maximum swapping amount
              </Label>
            )}

            <Input
              placeholder="Enter Amount"
              type="number"
              value={maxAmount}
              onChange={(e) => setMaxAmount(e.target.value)}
              className={clsx(
                "mt-3 block w-[254px] appearance-none h-[46px] rounded-[40px] bg-gray-dark-3 border-gray-light-1 border py-1.5 px-3 text-sm/6 outline-none text-white"
              )}
            />
          </Field>
        </div> */}
                {/* {error && <p className="text-sm font-semibold text-red-500">{error}</p>} */}
              </div>
              <div className="w-[604px] border border-gray-light-3 rounded-xl flex flex-col px-6 shadow-lg justify-evenly h-[252px] bg-gray-light-3/20">
                {onBoardingType === OnBoardingType.STAKE && (
                  <div>
                    <p className="text-xl font-semibold text-white">
                      Select the staking asset
                    </p>
                    <p className="text-base font-normal">
                      This is the asset that your protocol accepts as a staking
                      asset. It needs to be the asset that your smart contract
                      accepts:
                    </p>
                  </div>
                )}
                {onBoardingType === OnBoardingType.SWAP && (
                  <div>
                    <p className="text-xl font-semibold text-white">
                      Select the swapping asset
                    </p>
                    <p className="text-base font-normal">
                      This is the asset that your protocol accepts as a swap
                      asset. It needs to be the asset that your smart contract
                      accepts:
                    </p>
                  </div>
                )}

                {onBoardingType === OnBoardingType.LEND && (
                  <div>
                    <p className="text-xl font-semibold text-white">
                      Select the swapping asset
                    </p>
                    <p className="text-base font-normal">
                      This is the asset that your protocol accepts as a Lend
                      asset. It needs to be the asset that your smart contract
                      accepts:
                    </p>
                  </div>
                )}

                {/* Destination Chain */}
                <div className="flex items-center justify-between">
                  <div className="flex flex-col gap-2">
                    <label className="text-white">Blockchain</label>
                    <div className="relative">
                      {/* <TiArrowSortedDown className="absolute text-white right-5 top-4" /> */}
                      <Listbox
                        as="button"
                        onClick={() => {
                          setDestNetworkIsOpen(!destNetworkisOpen);
                        }}
                        //onChange={setSelectedDestChain}
                      >
                        <ListboxButton
                          onClick={() => {
                            setDestNetworkIsOpen(!destNetworkisOpen);
                          }}
                          className="w-[254px] h-[46px] flex items-center gap-2 rounded-[40px] text-white text-left bg-gray-dark-3 border-gray-light-1  border px-5 outline-none "
                        >
                          {destNetworkisOpen ? (
                            <TiArrowSortedUp className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          ) : (
                            <TiArrowSortedDown className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          )}
                          {bridgeTypeDestinationChain?.network_logo_url && (
                            <img
                              src={bridgeTypeDestinationChain?.network_logo_url}
                              className="w-6 h-6 rounded-full"
                            />
                          )}
                          {bridgeTypeDestinationChain?.chain
                            ? bridgeTypeDestinationChain.chain
                            : "-"}
                        </ListboxButton>
                        <ListboxOptions
                          className="w-[var(--button-width)] mt-1 flex flex-col gap-y-3 bg-black px-4 border rounded-2xl border-white py-3"
                          anchor="bottom end"
                        >
                          {bridgeTypeSourceAsset?.destinations.map(
                            (destChain, index) => (
                              <ListboxOption
                                key={index}
                                value={destChain}
                                onClick={() => {
                                  dispatch(
                                    updateBridgeTypeDestChain(destChain)
                                  );

                                  dispatch(updateBridgeTypeDestAsset(null));
                                }}
                                className="data-[focus]:text-primary-light flex items-center gap-x-2 px-1 py-1 cursor-pointer"
                              >
                                <img
                                  src={destChain.network_logo_url}
                                  className="w-6 h-6 rounded-full"
                                />
                                <p className="w-full text-white hover:text-primary-light">
                                  {destChain.chain}
                                </p>
                              </ListboxOption>
                            )
                          )}
                        </ListboxOptions>
                      </Listbox>
                    </div>
                  </div>

                  <div className="flex flex-col gap-2">
                    <label className="text-white">Asset</label>
                    <div className="relative">
                      <Listbox
                        as="button"
                        onClick={() => {
                          setDestAssetIsOpen(!destAssetisOpen);
                        }}
                        //onChange={setSelectedDestAsset}
                      >
                        <ListboxButton
                          onClick={() => {
                            setDestAssetIsOpen(!destAssetisOpen);
                          }}
                          className="w-[254px] h-[46px] flex gap-2 items-center rounded-[40px] text-white text-left bg-gray-dark-3 border-gray-light-1  border px-5 outline-none "
                        >
                          {destAssetisOpen ? (
                            <TiArrowSortedUp className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          ) : (
                            <TiArrowSortedDown className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          )}
                          {bridgeTypeDestinationAsset?.icon && (
                            <img
                              src={bridgeTypeDestinationAsset?.icon}
                              className="w-6 h-6 rounded-full"
                            />
                          )}
                          {bridgeTypeDestinationAsset?.name
                            ? bridgeTypeDestinationAsset?.name
                            : "-"}
                        </ListboxButton>
                        <ListboxOptions
                          className="w-[var(--button-width)] mt-1 flex flex-col gap-y-3 bg-black px-4 border rounded-2xl border-white py-3"
                          anchor="bottom end"
                        >
                          {bridgeTypeDestinationChain?.assets.map(
                            (destAsset, index) => (
                              <ListboxOption
                                key={index}
                                value={destAsset}
                                onClick={() => {
                                  dispatch(
                                    updateBridgeTypeDestAsset(destAsset)
                                  );
                                }}
                                className="data-[focus]:text-primary-default flex items-center gap-x-2 px-1 py-1 cursor-pointer"
                              >
                                <img src={destAsset.icon} className="w-6 h-6 rounded-full" />
                                <p className="w-full text-white hover:text-primary-light">
                                  {destAsset.name}
                                </p>
                              </ListboxOption>
                            )
                          )}
                        </ListboxOptions>
                      </Listbox>
                    </div>
                  </div>
                </div>
                {/* <div>
          <p className="text-base text-center">
            Asset you need is not supported yet?{" "}
            <a className="font-normal cursor-pointer text-primary-light">
              {" "}
              Add a feature
            </a>
          </p>
        </div> */}
              </div>
              {/* <div className="w-[604px] border border-gray-light-3 rounded-xl flex flex-col px-6 shadow-lg justify-evenly h-[231px] bg-gray-light-3/20">
        <div>
          <p className="text-xl font-semibold text-white">Redirect</p>
          <p className="text-base font-normal">
            Enter the URL of the page where users will be directed after
            completing staking
          </p>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-2">
            <label className="text-white">Redirect URL</label>
            <input
              type="text"
              value={redirectUrl}
              onChange={(e) => setRedirectUrl(e.target.value)}
              className="w-[540px] h-[46px] text-white text-base border rounded-[40px] px-3 outline-none bg-gray-dark-3 placeholder:text-gray-light-3"
              placeholder="Insert redirect URL"
            />
          </div>
        </div>
      </div> */}
              {/* <button
          onClick={handleSubmit}
          className="h-[46px] w-[180px] text-white rounded-3xl font-medium bg-primary-default"
        >
          Submit
        </button> */}
            </div>
          )}
          {isSwapResponse(assets.data?.data) && (
            <div className="flex flex-col items-center gap-5 mt-10">
              <div className="w-[604px] border border-gray-light-3 rounded-xl justify-evenly flex flex-col px-6 shadow-lg h-[231px] bg-gray-light-3/20">
                <div>
                  <p className="text-xl font-semibold text-white">
                    Select the onboarding asset
                  </p>
                  <p className="text-base font-normal text-gray-light-1">
                    Choose the asset that users will swap
                  </p>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex flex-col gap-2">
                    <label className="text-white">Blockchain</label>
                    <div className="relative">
                      <Listbox
                        as="button"
                        onClick={() => {
                          setSourceNetworkIsOpen(!sourceNetworkisOpen);
                        }}
                        // onChange={setSelectedSourceChain}
                      >
                        <ListboxButton
                          onClick={() => {
                            setSourceNetworkIsOpen(!sourceNetworkisOpen);
                          }}
                          className={`w-[254px] h-[46px] ${
                            swapTypeSourceChain?.source_asset_network
                              ? "text-white"
                              : "text-gray-light-3"
                          } flex items-center gap-2 rounded-[40px] text-left bg-gray-dark-3 border-gray-light-1  border px-5 outline-none `}
                        >
                          {sourceNetworkisOpen ? (
                            <TiArrowSortedUp className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          ) : (
                            <TiArrowSortedDown className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          )}
                          {swapTypeSourceChain && (
                            <img
                              src={
                                swapTypeSourceChain?.source_asset_network_logo
                              }
                              className="w-6 h-6 rounded-full"
                            />
                          )}
                          {swapTypeSourceChain?.source_asset_network
                            ? swapTypeSourceChain?.source_asset_network
                            : "Choose Network"}
                        </ListboxButton>
                        <ListboxOptions
                          className="w-[var(--button-width)] mt-1 flex flex-col gap-y-3 bg-black px-4 border rounded-2xl border-white py-3"
                          anchor="bottom end"
                        >
                          {assets.data.data.swap.map((chain, index) => (
                            <ListboxOption
                              key={index}
                              value={chain}
                              onClick={() => {
                                dispatch(updateSwapTypeSourceChain(chain));
                                dispatch(updateSwapTypeSourceAsset(null));
                                dispatch(updateSwapTypeDestChain(null));
                                dispatch(updateSwapTypeDestAsset(null));
                              }}
                              className="data-[focus]:text-primary-light flex items-center gap-x-2 px-1 py-1 cursor-pointer"
                            >
                              <img
                                src={chain.source_asset_network_logo}
                                className="w-6 h-6 rounded-full"
                              />
                              <p className="w-full text-white hover:text-primary-light">
                                {chain.source_asset_network}
                              </p>
                            </ListboxOption>
                          ))}
                        </ListboxOptions>
                      </Listbox>
                    </div>
                  </div>

                  {/* Source ASSet */}
                  <div className="flex flex-col gap-2">
                    <label className="text-white">Asset</label>
                    <div className="relative">
                      <Listbox
                        as="button"
                        onClick={() => {
                          setSourceAssetIsOpen(!sourceAsestisOpen);
                        }}
                        // onChange={setSelectedSourceToken}
                      >
                        <ListboxButton
                          onClick={() => {
                            setSourceAssetIsOpen(!sourceAsestisOpen);
                          }}
                          className={`w-[254px] h-[46px] flex items-center gap-2 rounded-[40px] ${
                            swapTypeSourceAsset?.source_asset
                              ? "text-white"
                              : "text-gray-light-3"
                          }  text-left bg-gray-dark-3 border-gray-light-1  border px-5 outline-none `}
                        >
                          {sourceAsestisOpen ? (
                            <TiArrowSortedUp className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          ) : (
                            <TiArrowSortedDown className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          )}

                          {swapTypeSourceAsset?.source_asset_logo && (
                            <img
                              className="w-6 h-6 rounded-full"
                              src={swapTypeSourceAsset?.source_asset_logo}
                            />
                          )}
                          {swapTypeSourceAsset?.source_asset
                            ? swapTypeSourceAsset.source_asset
                            : "Choose Asset"}
                        </ListboxButton>
                        <ListboxOptions
                          className="w-[var(--button-width)] mt-1 flex flex-col gap-y-3 bg-black px-4 border rounded-2xl border-white py-3"
                          anchor="bottom end"
                        >
                          {swapTypeSourceChain?.basePair &&
                            swapTypeSourceChain.basePair.map((token, index) => (
                              <ListboxOption
                                key={index}
                                value={token}
                                onClick={() => {
                                  dispatch(updateSwapTypeSourceAsset(token));
                                  dispatch(updateSwapTypeDestChain(null));
                                  dispatch(updateSwapTypeDestAsset(null));
                                }}
                                className="data-[focus]:text-primary-light flex items-center gap-x-2 px-1 py-1 cursor-pointer"
                              >
                                <img
                                  src={token.source_asset_logo}
                                  className="w-6 h-6 rounded-full"
                                />
                                <p className="text-white hover:text-primary-light w-full data-[focus]:text-primary-default">
                                  {token.source_asset}
                                </p>
                              </ListboxOption>
                            ))}
                        </ListboxOptions>
                      </Listbox>
                    </div>
                  </div>
                </div>

                {/* <div className="flex items-center justify-between">
          <Field>
            {onBoardingType == OnBoardingType.SWAP && (
              <Label className="font-medium text-white">
                Minium swapping amount
              </Label>
            )}

            {onBoardingType == OnBoardingType.STAKE && (
              <Label className="font-medium text-white">
                Minumum staking amount
              </Label>
            )}

            <Input
              placeholder="Enter Amount"
              type="number"
              value={minAmount}
              onChange={(e) => setMinAmount(e.target.value)}
              className={clsx(
                "mt-3 block w-[254px] appearance-none h-[46px] rounded-[40px] bg-gray-dark-3 border-gray-light-1 border py-1.5 px-3 text-sm/6 outline-none text-white"
              )}
            />
          </Field>
          <Field>
            {onBoardingType == OnBoardingType.STAKE && (
              <Label className="font-medium text-white">
                Maximum staking amount
              </Label>
            )}

            {onBoardingType == OnBoardingType.SWAP && (
              <Label className="font-medium text-white">
                Maximum swapping amount
              </Label>
            )}

            <Input
              placeholder="Enter Amount"
              type="number"
              value={maxAmount}
              onChange={(e) => setMaxAmount(e.target.value)}
              className={clsx(
                "mt-3 block w-[254px] appearance-none h-[46px] rounded-[40px] bg-gray-dark-3 border-gray-light-1 border py-1.5 px-3 text-sm/6 outline-none text-white"
              )}
            />
          </Field>
        </div> */}
                {/* {error && <p className="text-sm font-semibold text-red-500">{error}</p>} */}
              </div>
              <div className="w-[604px] border border-gray-light-3 rounded-xl flex flex-col px-6 shadow-lg justify-evenly h-[252px] bg-gray-light-3/20">
                {onBoardingType === OnBoardingType.STAKE && (
                  <div>
                    <p className="text-xl font-semibold text-white">
                      Select the staking asset
                    </p>
                    <p className="text-base font-normal">
                      This is the asset that your protocol accepts as a staking
                      asset. It needs to be the asset that your smart contract
                      accepts:
                    </p>
                  </div>
                )}
                {onBoardingType === OnBoardingType.SWAP && (
                  <div>
                    <p className="text-xl font-semibold text-white">
                      Select the swapping asset
                    </p>
                    <p className="text-base font-normal">
                      This is the asset that your protocol accepts as a swap
                      asset. It needs to be the asset that your smart contract
                      accepts:
                    </p>
                  </div>
                )}

                {onBoardingType === OnBoardingType.LEND && (
                  <div>
                    <p className="text-xl font-semibold text-white">
                      Select the swapping asset
                    </p>
                    <p className="text-base font-normal">
                      This is the asset that your protocol accepts as a Lend
                      asset. It needs to be the asset that your smart contract
                      accepts:
                    </p>
                  </div>
                )}

                {/* Destination Chain */}
                <div className="flex items-center justify-between">
                  <div className="flex flex-col gap-2">
                    <label className="text-white">Blockchain</label>
                    <div className="relative">
                      {/* <TiArrowSortedDown className="absolute text-white right-5 top-4" /> */}
                      <Listbox
                        as="button"
                        onClick={() => {
                          setDestNetworkIsOpen(!destNetworkisOpen);
                        }}
                        //onChange={setSelectedDestChain}
                      >
                        <ListboxButton
                          onClick={() => {
                            setDestNetworkIsOpen(!destNetworkisOpen);
                          }}
                          className="w-[254px] h-[46px] flex items-center gap-2 rounded-[40px] text-white text-left bg-gray-dark-3 border-gray-light-1  border px-5 outline-none "
                        >
                          {destNetworkisOpen ? (
                            <TiArrowSortedUp className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          ) : (
                            <TiArrowSortedDown className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          )}
                          {swapTypeDestinationChain?.destination_asset_network && (
                            <img
                              src={
                                swapTypeDestinationChain?.destination_network_logo
                              }
                              className="w-6 h-6 rounded-full"
                            />
                          )}
                          {swapTypeDestinationChain?.destination_asset_network
                            ? swapTypeDestinationChain.destination_asset_network
                            : "-"}
                        </ListboxButton>
                        <ListboxOptions
                          className="w-[var(--button-width)] mt-1 flex flex-col gap-y-3 bg-black px-4 border rounded-2xl border-white py-3"
                          anchor="bottom end"
                        >
                          {swapTypeSourceAsset?.pairs.map(
                            (destChain, index) => (
                              <ListboxOption
                                key={index}
                                value={destChain}
                                onClick={() => {
                                  dispatch(updateSwapTypeDestChain(destChain));
                                  dispatch(updateSwapTypeDestAsset(null));
                                }}
                                className="data-[focus]:text-primary-light flex items-center gap-x-2 px-1 py-1 cursor-pointer"
                              >
                                <img
                                  src={destChain.destination_network_logo}
                                  className="w-6 h-6 rounded-full" 
                                />
                                <p className="w-full text-white hover:text-primary-light">
                                  {destChain.destination_asset_network}
                                </p>
                              </ListboxOption>
                            )
                          )}
                        </ListboxOptions>
                      </Listbox>
                    </div>
                  </div>

                  <div className="flex flex-col gap-2">
                    <label className="text-white">Asset</label>
                    <div className="relative">
                      <Listbox
                        as="button"
                        onClick={() => {
                          setDestAssetIsOpen(!destAssetisOpen);
                        }}
                        //onChange={setSelectedDestAsset}
                      >
                        <ListboxButton
                          onClick={() => {
                            setDestAssetIsOpen(!destAssetisOpen);
                          }}
                          className="w-[254px] h-[46px] flex gap-2 items-center rounded-[40px] text-white text-left bg-gray-dark-3 border-gray-light-1  border px-5 outline-none "
                        >
                          {destAssetisOpen ? (
                            <TiArrowSortedUp className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          ) : (
                            <TiArrowSortedDown className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          )}
                          {swapTypeDestinationAsset?.destination_asset_logo && (
                            <img
                              src={
                                swapTypeDestinationAsset?.destination_asset_logo
                              }
                              className="w-6 h-6 rounded-full"
                            />
                          )}
                          {swapTypeDestinationAsset?.destination_asset
                            ? swapTypeDestinationAsset?.destination_asset
                            : "-"}
                        </ListboxButton>
                        <ListboxOptions
                          className="w-[var(--button-width)] mt-1 flex flex-col gap-y-3 bg-black px-4 border rounded-2xl border-white py-3"
                          anchor="bottom end"
                        >
                          {swapTypeDestinationChain?.assets.map(
                            (destAsset, index) => (
                              <ListboxOption
                                key={index}
                                value={destAsset}
                                onClick={() => {
                                  dispatch(updateSwapTypeDestAsset(destAsset));
                                }}
                                className="data-[focus]:text-primary-default flex items-center gap-x-2 px-1 py-1 cursor-pointer"
                              >
                                <img
                                  src={destAsset.destination_asset_logo}
                                  className="w-6 h-6 rounded-full"
                                />
                                <p className="w-full text-white hover:text-primary-light">
                                  {destAsset.destination_asset}
                                </p>
                              </ListboxOption>
                            )
                          )}
                        </ListboxOptions>
                      </Listbox>
                    </div>
                  </div>
                </div>
                {/* <div>
          <p className="text-base text-center">
            Asset you need is not supported yet?{" "}
            <a className="font-normal cursor-pointer text-primary-light">
              {" "}
              Add a feature
            </a>
          </p>
        </div> */}
              </div>
              {/* <div className="w-[604px] border border-gray-light-3 rounded-xl flex flex-col px-6 shadow-lg justify-evenly h-[231px] bg-gray-light-3/20">
        <div>
          <p className="text-xl font-semibold text-white">Redirect</p>
          <p className="text-base font-normal">
            Enter the URL of the page where users will be directed after
            completing staking
          </p>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-2">
            <label className="text-white">Redirect URL</label>
            <input
              type="text"
              value={redirectUrl}
              onChange={(e) => setRedirectUrl(e.target.value)}
              className="w-[540px] h-[46px] text-white text-base border rounded-[40px] px-3 outline-none bg-gray-dark-3 placeholder:text-gray-light-3"
              placeholder="Insert redirect URL"
            />
          </div>
        </div>
      </div> */}
              {/* <button
          onClick={handleSubmit}
          className="h-[46px] w-[180px] text-white rounded-3xl font-medium bg-primary-default"
        >
          Submit
        </button> */}
            </div>
          )}
          {isStakeResponse(assets.data?.data) && (
            <div className="flex flex-col items-center gap-5 mt-10">
              <div className="w-[604px] border border-gray-light-3 rounded-xl justify-evenly flex flex-col px-6 shadow-lg h-[231px] bg-gray-light-3/20">
                <div>
                  <p className="text-xl font-semibold text-white">
                    Select the onboarding asset
                  </p>
                  <p className="text-base font-normal text-gray-light-1">
                    Choose the asset that users will stake
                  </p>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex flex-col gap-2">
                    <label className="text-white">Blockchain</label>
                    <div className="relative">
                      <Listbox
                        as="button"
                        onClick={() => {
                          setSourceNetworkIsOpen(!sourceNetworkisOpen);
                        }}
                        // onChange={setSelectedSourceChain}
                      >
                        <ListboxButton
                          onClick={() => {
                            setSourceNetworkIsOpen(!sourceNetworkisOpen);
                          }}
                          className={`w-[254px] h-[46px] ${
                            stakeOnboarding?.onboarding_network
                              ? "text-white"
                              : "text-gray-light-3"
                          } flex items-center gap-2 rounded-[40px] text-left bg-gray-dark-3 border-gray-light-1  border px-5 outline-none `}
                        >
                          {sourceNetworkisOpen ? (
                            <TiArrowSortedUp className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          ) : (
                            <TiArrowSortedDown className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          )}
                          {stakeOnboarding?.onboarding_network_icon && (
                            <img
                              src={stakeOnboarding?.onboarding_network_icon}
                              className="w-6 h-6 rounded-full"
                            />
                          )}
                          {stakeOnboarding?.onboarding_network
                            ? stakeOnboarding?.onboarding_network
                            : "Choose Network"}
                        </ListboxButton>
                        <ListboxOptions
                          className="w-[var(--button-width)] mt-1 flex flex-col gap-y-3 bg-black px-4 border rounded-2xl border-white py-3"
                          anchor="bottom end"
                        >
                          {assets.data.data.stake.map(
                            (onboardingDetails, index) => (
                              <ListboxOption
                                key={index}
                                value={onboardingDetails}
                                onClick={() => {
                                  dispatch(
                                    updateStakeOnboarding(onboardingDetails)
                                  );
                                }}
                                className="data-[focus]:text-primary-light flex items-center gap-x-2 px-1 py-1 cursor-pointer"
                              >
                                <img
                                  src={
                                    onboardingDetails.onboarding_network_icon
                                  }
                                  className="w-6 h-6 rounded-full"
                                />
                                <p className="w-full text-white hover:text-primary-light">
                                  {onboardingDetails.onboarding_network}
                                </p>
                              </ListboxOption>
                            )
                          )}
                        </ListboxOptions>
                      </Listbox>
                    </div>
                  </div>

                  {/* Source ASSet */}
                  <div className="flex flex-col gap-2">
                    <label className="text-white">Asset</label>
                    <div className="relative">
                      <Listbox
                        as="button"
                        onClick={() => {
                          setSourceAssetIsOpen(!sourceAsestisOpen);
                        }}
                        // onChange={setSelectedSourceToken}
                      >
                        <ListboxButton
                          onClick={() => {
                            setSourceAssetIsOpen(!sourceAsestisOpen);
                          }}
                          className={`w-[254px] h-[46px] flex items-center gap-2 rounded-[40px] ${
                            stakeOnboardingAsset?.onboarding_name
                              ? "text-white"
                              : "text-gray-light-3"
                          }  text-left bg-gray-dark-3 border-gray-light-1  border px-5 outline-none `}
                        >
                          {sourceAsestisOpen ? (
                            <TiArrowSortedUp className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          ) : (
                            <TiArrowSortedDown className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          )}

                          {stakeOnboardingAsset?.onboarding_icon && (
                            <img
                              className="w-6 h-6 rounded-full"
                              src={stakeOnboardingAsset?.onboarding_icon}
                            />
                          )}
                          {stakeOnboardingAsset?.onboarding_name
                            ? stakeOnboardingAsset.onboarding_name
                            : "Choose Asset"}
                        </ListboxButton>
                        <ListboxOptions
                          className="w-[var(--button-width)] mt-1 mb-1 flex flex-col gap-y-3 bg-black px-4 border rounded-2xl border-white py-3"
                          anchor="bottom end"
                        >
                          {stakeOnboarding?.assets &&
                            stakeOnboarding.assets.map((token, index) => (
                              <ListboxOption
                                key={index}
                                value={token}
                                onClick={() => {
                                  dispatch(updateStakeOnboardingAssets(token));
                                }}
                                className="data-[focus]:text-primary-light flex items-center gap-x-2 px-1 py-1 cursor-pointer"
                              >
                                <img
                                  src={token.onboarding_icon}
                                  className="w-6 h-6 rounded-full"
                                />
                                <p className="text-white hover:text-primary-light w-full data-[focus]:text-primary-default">
                                  {token.onboarding_name}
                                </p>
                              </ListboxOption>
                            ))}
                        </ListboxOptions>
                      </Listbox>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[604px] border border-gray-light-3 rounded-xl flex flex-col px-6 shadow-lg justify-evenly h-[252px] bg-gray-light-3/20">
                {onBoardingType === OnBoardingType.STAKE && (
                  <div>
                    <p className="text-xl font-semibold text-white">
                      Select the staking asset
                    </p>
                    <p className="text-base font-normal">
                      This is the asset that your protocol accepts as a staking
                      asset. It needs to be the asset that your smart contract
                      accepts:
                    </p>
                  </div>
                )}

                {/* Source Chain */}
                <div className="flex items-center justify-between">
                  <div className="flex flex-col gap-2">
                    <label className="text-white">Blockchain</label>
                    <div className="relative">
                      {/* <TiArrowSortedDown className="absolute text-white right-5 top-4" /> */}
                      <Listbox
                        as="button"
                        onClick={() => {
                          setDestNetworkIsOpen(!destNetworkisOpen);
                        }}
                        //onChange={setSelectedDestChain}
                      >
                        <ListboxButton
                          onClick={() => {
                            setDestNetworkIsOpen(!destNetworkisOpen);
                          }}
                          className="w-[254px] h-[46px] flex items-center gap-2 rounded-[40px] text-white text-left bg-gray-dark-3 border-gray-light-1  border px-5 outline-none "
                        >
                          {destNetworkisOpen ? (
                            <TiArrowSortedUp className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          ) : (
                            <TiArrowSortedDown className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          )}
                          {stakeTypeSourceChain?.source_network_icon && (
                            <img
                              src={stakeTypeSourceChain?.source_network_icon}
                              className="w-6 h-6 rounded-full"
                            />
                          )}
                          {stakeTypeSourceChain?.source_network
                            ? stakeTypeSourceChain?.source_network
                            : "-"}
                        </ListboxButton>
                        <ListboxOptions
                          className="w-[var(--button-width)] mt-1 flex flex-col gap-y-3 bg-black px-4 border rounded-2xl border-white py-3"
                          anchor="bottom end"
                        >
                          {stakeOnboardingAsset?.source.map(
                            (sourceChain, index) => (
                              <ListboxOption
                                key={index}
                                value={sourceChain}
                                onClick={() => {
                                  dispatch(
                                    updateStakeTypeSourceChain(sourceChain)
                                  );
                                }}
                                className="data-[focus]:text-primary-light flex items-center gap-x-2 px-1 py-1 cursor-pointer"
                              >
                                <img
                                  src={sourceChain.source_network_icon}
                                  className="w-6 h-6 rounded-full"
                                />
                                <p className="w-full text-white hover:text-primary-light">
                                  {sourceChain.source_network}
                                </p>
                              </ListboxOption>
                            )
                          )}
                        </ListboxOptions>
                      </Listbox>
                    </div>
                  </div>

                  <div className="flex flex-col gap-2">
                    <label className="text-white">Asset</label>
                    <div className="relative">
                      <Listbox
                        as="button"
                        onClick={() => {
                          setDestAssetIsOpen(!destAssetisOpen);
                        }}
                        //onChange={setSelectedDestAsset}
                      >
                        <ListboxButton
                          onClick={() => {
                            setDestAssetIsOpen(!destAssetisOpen);
                          }}
                          className="w-[254px] h-[46px] flex gap-2 items-center rounded-[40px] text-white text-left bg-gray-dark-3 border-gray-light-1  border px-5 outline-none "
                        >
                          {destAssetisOpen ? (
                            <TiArrowSortedUp className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          ) : (
                            <TiArrowSortedDown className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          )}
                          {stakeTypeSourceAssets?.source_icon && (
                            <img
                              src={stakeTypeSourceAssets?.source_icon}
                              className="w-6 h-6 rounded-full"
                            />
                          )}
                          {stakeTypeSourceAssets?.source_name
                            ? stakeTypeSourceAssets?.source_name
                            : "-"}
                        </ListboxButton>
                        <ListboxOptions
                          className="w-[var(--button-width)] mt-1 flex flex-col gap-y-3 bg-black px-4 border rounded-2xl border-white py-3"
                          anchor="bottom end"
                        >
                          {stakeTypeSourceChain?.source_asset.map(
                            (stakeAsset, index) => (
                              <ListboxOption
                                key={index}
                                value={stakeAsset}
                                onClick={() => {
                                  dispatch(
                                    updateStakeTypeSourceAssets(stakeAsset)
                                  );
                                }}
                                className="data-[focus]:text-primary-default flex items-center gap-x-2 px-1 py-1 cursor-pointer"
                              >
                                <img
                                  src={stakeAsset.source_icon}
                                  className="w-6 h-6 rounded-full"
                                />
                                <p className="w-full text-white hover:text-primary-light">
                                  {stakeAsset.source_name}
                                </p>
                              </ListboxOption>
                            )
                          )}
                        </ListboxOptions>
                      </Listbox>
                    </div>
                  </div>
                </div>
                {/* <div>
          <p className="text-base text-center">
            Asset you need is not supported yet?{" "}
            <a className="font-normal cursor-pointer text-primary-light">
              {" "}
              Add a feature
            </a>
          </p>
        </div> */}
              </div>
              <div className="w-[604px] border border-gray-light-3 rounded-xl flex flex-col px-6 shadow-lg justify-evenly h-[252px] bg-gray-light-3/20">
                {onBoardingType === OnBoardingType.STAKE && (
                  <div>
                    <p className="text-xl font-semibold text-white">
                      Receiving asset
                    </p>
                    <p className="text-base font-normal">
                      This is the asset that the user is receiving when he
                      stakes. It has to be the asset that your smart contract
                      accepts
                    </p>
                  </div>
                )}

                {/* Source Chain */}
                <div className="flex items-center justify-between">
                  <div className="flex flex-col gap-2">
                    <label className="text-white">Blockchain</label>
                    <div className="relative">
                      {/* <TiArrowSortedDown className="absolute text-white right-5 top-4" /> */}
                      <Listbox
                        as="button"
                        onClick={() => {
                          setDestNetworkIsOpen(!destNetworkisOpen);
                        }}
                        //onChange={setSelectedDestChain}
                      >
                        <ListboxButton
                          onClick={() => {
                            setDestNetworkIsOpen(!destNetworkisOpen);
                          }}
                          className="w-[254px] h-[46px] flex items-center gap-2 rounded-[40px] text-white text-left bg-gray-dark-3 border-gray-light-1  border px-5 outline-none "
                        >
                          {destNetworkisOpen ? (
                            <TiArrowSortedUp className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          ) : (
                            <TiArrowSortedDown className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          )}
                          {stakeTypeSourceAssets?.destinations
                            .dest_network_icon && (
                            <img
                              src={
                                stakeTypeSourceAssets?.destinations
                                  .dest_network_icon
                              }
                              className="w-6 h-6 rounded-full"
                            />
                          )}
                          {stakeTypeSourceAssets?.destinations.dest_network
                            ? stakeTypeSourceAssets?.destinations.dest_network
                            : "-"}
                        </ListboxButton>
                        {/* <ListboxOptions
                          className="w-[var(--button-width)] mt-1 flex flex-col gap-y-3 bg-black px-4 border rounded-2xl border-white py-3"
                          anchor="bottom end"
                        >
                          {stakeTypeSourceAssets?.destinations.map(
                            (destChain, index) => (
                              <ListboxOption
                                key={index}
                                value={destChain}
                                onClick={() => {
                                  // dispatch(updateSwapTypeDestChain(destChain));
                                  dispatch(updateStakeTypeDestChain(destChain))
                                }}
                                className="data-[focus]:text-primary-light flex items-center gap-x-2 px-1 py-1 cursor-pointer"
                              >
                                <img
                                  src={destChain.dest_network_icon}
                                  className="w-6 h-6"
                                />
                                <p className="w-full text-white hover:text-primary-light">
                                  {destChain.dest_network}
                                </p>
                              </ListboxOption>
                            )
                          )}
                        </ListboxOptions> */}
                      </Listbox>
                    </div>
                  </div>

                  <div className="flex flex-col gap-2">
                    <label className="text-white">Asset</label>
                    <div className="relative">
                      <Listbox
                        as="button"
                        onClick={() => {
                          setDestAssetIsOpen(!destAssetisOpen);
                        }}
                        //onChange={setSelectedDestAsset}
                      >
                        <ListboxButton
                          onClick={() => {
                            setDestAssetIsOpen(!destAssetisOpen);
                          }}
                          className="w-[254px] h-[46px] flex gap-2 items-center rounded-[40px] text-white text-left bg-gray-dark-3 border-gray-light-1  border px-5 outline-none "
                        >
                          {destAssetisOpen ? (
                            <TiArrowSortedUp className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          ) : (
                            <TiArrowSortedDown className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          )}
                          {stakeTypeSourceAssets?.destinations.dest_icon && (
                            <img
                              src={
                                stakeTypeSourceAssets?.destinations.dest_icon
                              }
                              className="w-6 h-6 rounded-full"
                            />
                          )}
                          {stakeTypeSourceAssets?.destinations.dest_name
                            ? stakeTypeSourceAssets?.destinations.dest_name
                            : "-"}
                        </ListboxButton>
                        {/* <ListboxOptions
                          className="w-[var(--button-width)] mt-1 flex flex-col gap-y-3 bg-black px-4 border rounded-2xl border-white py-3"
                          anchor="bottom end"
                        >
                          {stakeTypeSourceChain?.destinations.map(
                            (destAsset, index) => (
                              <ListboxOption
                                key={index}
                                value={destAsset}
                                onClick={() => {
                                  //dispatch(updateSwapTypeDestAsset(destAsset));
                                }}
                                className="data-[focus]:text-primary-default flex items-center gap-x-2 px-1 py-1 cursor-pointer"
                              >
                                <img
                                  src={destAsset.dest_icon}
                                  className="w-6 h-6"
                                />
                                <p className="w-full text-white hover:text-primary-light">
                                  {destAsset.dest_name}
                                </p>
                              </ListboxOption>
                            )
                          )}
                        </ListboxOptions> */}
                      </Listbox>
                    </div>
                  </div>
                </div>
                {/* <div>
          <p className="text-base text-center">
            Asset you need is not supported yet?{" "}
            <a className="font-normal cursor-pointer text-primary-light">
              {" "}
              Add a feature
            </a>
          </p>
        </div> */}
              </div>
            </div>
          )}
          {isLendResponse(assets.data?.data) && (
            <div className="flex flex-col items-center gap-5 mt-10">
              <div className="w-[604px] border border-gray-light-3 rounded-xl justify-evenly flex flex-col px-6 shadow-lg h-[231px] bg-gray-light-3/20">
                <div>
                  <p className="text-xl font-semibold text-white">
                    Select the onboarding asset
                  </p>
                  <p className="text-base font-normal text-gray-light-1">
                    Choose the asset that users will swap
                  </p>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex flex-col gap-2">
                    <label className="text-white">Blockchain</label>
                    <div className="relative">
                      <Listbox
                        as="button"
                        onClick={() => {
                          setSourceNetworkIsOpen(!sourceNetworkisOpen);
                        }}
                        // onChange={setSelectedSourceChain}
                      >
                        <ListboxButton
                          onClick={() => {
                            setSourceNetworkIsOpen(!sourceNetworkisOpen);
                          }}
                          className={`w-[254px] h-[46px] ${
                            lendOnboarding?.onboarding_network
                              ? "text-white"
                              : "text-gray-light-3"
                          } flex items-center gap-2 rounded-[40px] text-left bg-gray-dark-3 border-gray-light-1  border px-5 outline-none `}
                        >
                          {sourceNetworkisOpen ? (
                            <TiArrowSortedUp className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          ) : (
                            <TiArrowSortedDown className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          )}
                          {lendOnboarding?.onboarding_network_icon && (
                            <img
                              src={lendOnboarding?.onboarding_network_icon}
                              className="w-6 h-6 rounded-full"
                            />
                          )}
                          {lendOnboarding?.onboarding_network
                            ? lendOnboarding?.onboarding_network
                            : "Choose Network"}
                        </ListboxButton>
                        <ListboxOptions
                          className="w-[var(--button-width)] mt-1 flex flex-col gap-y-3 bg-black px-4 border rounded-2xl border-white py-3"
                          anchor="bottom end"
                        >
                          {assets.data.data.lend.map((onboarding, index) => (
                            <ListboxOption
                              key={index}
                              value={onboarding}
                              onClick={() => {
                                dispatch(
                                  updateLendOnboardingAssets(onboarding)
                                );
                              }}
                              className="data-[focus]:text-primary-light flex items-center gap-x-2 px-1 py-1 cursor-pointer"
                            >
                              <img
                                src={onboarding.onboarding_network_icon}
                                className="w-6 h-6 rounded-full"
                              />
                              <p className="w-full text-white hover:text-primary-light">
                                {onboarding.onboarding_network}
                              </p>
                            </ListboxOption>
                          ))}
                        </ListboxOptions>
                      </Listbox>
                    </div>
                  </div>

                  {/* Source ASSet */}
                  <div className="flex flex-col gap-2">
                    <label className="text-white">Asset</label>
                    <div className="relative">
                      <Listbox
                        as="button"
                        onClick={() => {
                          setSourceAssetIsOpen(!sourceAsestisOpen);
                        }}
                        // onChange={setSelectedSourceToken}
                      >
                        <ListboxButton
                          onClick={() => {
                            setSourceAssetIsOpen(!sourceAsestisOpen);
                          }}
                          className={`w-[254px] h-[46px] flex items-center gap-2 rounded-[40px] ${
                            lendTypeOnboardingSourceAssets?.source_name
                              ? "text-white"
                              : "text-gray-light-3"
                          }  text-left bg-gray-dark-3 border-gray-light-1  border px-5 outline-none `}
                        >
                          {sourceAsestisOpen ? (
                            <TiArrowSortedUp className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          ) : (
                            <TiArrowSortedDown className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          )}

                          {lendTypeOnboardingSourceAssets?.source_icon && (
                            <img
                              className="w-6 h-6 rounded-full"
                              src={lendTypeOnboardingSourceAssets.source_icon}
                            />
                          )}
                          {lendTypeOnboardingSourceAssets?.source_name
                            ? lendTypeOnboardingSourceAssets.source_name
                            : "Choose Asset"}
                        </ListboxButton>
                        <ListboxOptions
                          className="w-[var(--button-width)] mt-1 flex flex-col gap-y-3 bg-black px-4 border rounded-2xl border-white py-3"
                          anchor="bottom end"
                        >
                          {lendOnboarding?.assets &&
                            lendOnboarding.assets.map((asset, index) => (
                              <ListboxOption
                                key={index}
                                value={asset}
                                onClick={() => {
                                  //dispatch(updateBridgeTypeSourceAsset(token));
                                  dispatch(
                                    updateLendTypeOnboardingSourceAssets(asset)
                                  );
                                }}
                                className="data-[focus]:text-primary-light flex items-center gap-x-2 px-1 py-1 cursor-pointer"
                              >
                                <img
                                  src={asset.source_icon}
                                  className="w-6 h-6 rounded-full"
                                />
                                <p className="text-white hover:text-primary-light w-full data-[focus]:text-primary-default">
                                  {asset.source_name}
                                </p>
                              </ListboxOption>
                            ))}
                        </ListboxOptions>
                      </Listbox>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[604px] border border-gray-light-3 rounded-xl flex flex-col px-6 shadow-lg justify-evenly h-[252px] bg-gray-light-3/20">
                {onBoardingType === OnBoardingType.LEND && (
                  <div className="flex flex-col gap-[12px]">
                    <p className="text-xl font-semibold text-white">
                      Select the lending asset
                    </p>
                    <p className="text-base font-normal text-gray-light-1 leading-[21px]">
                      This is the asset that your protocol accepts as a lending
                      asset. It needs to be the asset that your smart contract
                      accepts:
                    </p>
                  </div>
                )}

                {/* Source Chain */}
                <div className="flex items-center justify-between">
                  <div className="flex flex-col gap-2">
                    <label className="text-white">Blockchain</label>
                    <div className="relative">
                      {/* <TiArrowSortedDown className="absolute text-white right-5 top-4" /> */}
                      <Listbox
                        as="button"
                        onClick={() => {
                          setDestNetworkIsOpen(!destNetworkisOpen);
                        }}
                        //onChange={setSelectedDestChain}
                      >
                        <ListboxButton
                          onClick={() => {
                            setDestNetworkIsOpen(!destNetworkisOpen);
                          }}
                          className="w-[254px] h-[46px] flex items-center gap-2 rounded-[40px] text-white text-left bg-gray-dark-3 border-gray-light-1  border px-5 outline-none "
                        >
                          {destNetworkisOpen ? (
                            <TiArrowSortedUp className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          ) : (
                            <TiArrowSortedDown className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          )}
                          {lendTypeDestChain?.dest_network_icon && (
                            <img
                              src={lendTypeDestChain?.dest_network_icon}
                              className="w-6 h-6 rounded-full"
                            />
                          )}
                          {lendTypeDestChain?.dest_network
                            ? lendTypeDestChain?.dest_network
                            : "-"}
                        </ListboxButton>
                        <ListboxOptions
                          className="w-[var(--button-width)] mt-1 flex flex-col gap-y-3 bg-black px-4 border rounded-2xl border-white py-3"
                          anchor="bottom end"
                        >
                          {lendTypeOnboardingSourceAssets?.destinations.map(
                            (destChain, index) => (
                              <ListboxOption
                                key={index}
                                value={destChain}
                                onClick={() => {
                                  // dispatch(
                                  //   updateStakeTypeSourceChain(sourceChain)
                                  // );
                                  dispatch(
                                    updateLendTypeDestinationChain(destChain)
                                  );
                                }}
                                className="data-[focus]:text-primary-light flex items-center gap-x-2 px-1 py-1 cursor-pointer"
                              >
                                <img
                                  src={destChain.dest_network_icon}
                                  className="w-6 h-6 rounded-full"
                                />
                                <p className="w-full text-white hover:text-primary-light">
                                  {destChain.dest_network}
                                </p>
                              </ListboxOption>
                            )
                          )}
                        </ListboxOptions>
                      </Listbox>
                    </div>
                  </div>

                  <div className="flex flex-col gap-2">
                    <label className="text-white">Asset</label>
                    <div className="relative">
                      <Listbox
                        as="button"
                        onClick={() => {
                          setDestAssetIsOpen(!destAssetisOpen);
                        }}
                        //onChange={setSelectedDestAsset}
                      >
                        <ListboxButton
                          onClick={() => {
                            setDestAssetIsOpen(!destAssetisOpen);
                          }}
                          className="w-[254px] h-[46px] flex gap-2 items-center rounded-[40px] text-white text-left bg-gray-dark-3 border-gray-light-1  border px-5 outline-none "
                        >
                          {destAssetisOpen ? (
                            <TiArrowSortedUp className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          ) : (
                            <TiArrowSortedDown className="absolute data-[open]:hidden text-white pointer-events-none right-5 top-4" />
                          )}
                          {lendTypeDestAsset?.dest_icon && (
                            <img
                              src={lendTypeDestAsset?.dest_icon}
                              className="w-6 h-6 rounded-full"
                            />
                          )}
                          {lendTypeDestAsset?.dest_name
                            ? lendTypeDestAsset?.dest_name
                            : "-"}
                        </ListboxButton>
                        <ListboxOptions
                          className="w-[var(--button-width)] mt-1 flex flex-col gap-y-3 bg-black px-4 border rounded-2xl border-white py-3"
                          anchor="bottom end"
                        >
                          {lendTypeDestChain?.dest_assets.map(
                            (lendAsset, index) => (
                              <ListboxOption
                                key={index}
                                value={lendAsset}
                                onClick={() => {
                                  dispatch(
                                    updateLendTypeDestinationAsset(lendAsset)
                                  );
                                }}
                                className="data-[focus]:text-primary-default flex items-center gap-x-2 px-1 py-1 cursor-pointer"
                              >
                                <img
                                  src={lendAsset.dest_icon}
                                  className="w-6 h-6 rounded-full"
                                />
                                <p className="w-full text-white hover:text-primary-light">
                                  {lendAsset.dest_name}
                                </p>
                              </ListboxOption>
                            )
                          )}
                        </ListboxOptions>
                      </Listbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default OnBoarding;
