import React, { useState, useRef, useMemo } from 'react';
import PreviewComponent from '../pages/CreateOnboardingApp/Previewtab';
import { ImCheckmark } from 'react-icons/im';
import { Switch } from '@headlessui/react';
import { THEME } from '../state/types';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import UploadIcon from '../assets/svg/UploadSimple.svg';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { updateCustomization } from '../state/onboarding/onboarding';

const CustomizedEdit = ({ appName }: { appName: string }) => {
  const {
    onBoardingRecord,
    onboardingId,
    isEditingOnboardingApp,
    onBoardingAppDetails,
  } = useAppSelector((state) => ({
    onBoardingRecord: state.onBoardingState.onBoardingRecord,
    onboardingId: state.onBoardingState.onBoardingId,
    isEditingOnboardingApp: state.onBoardingState.isEditingOnboardingApp,
    onBoardingAppDetails: state.appState.onBoardingAppDetails,
  }));
  const dispatch = useAppDispatch();

  const [theme, setTheme] = useState<THEME>(THEME.DARK);
  const [pageBgColor, setPageBgColor] = useState(
    onBoardingAppDetails?.data.page_color
  );
  const [accentColor, setAccentColor] = useState('#9264F8');
  const [title, setTitle] = useState('Stake');
  const [btnTextColor, setBtnTextColor] = useState('#FFF');
  const [subtitle, setSubtitle] = useState('Stake and get rewards');
  const [website, setWebsite] = useState('');
  const [logo, setLogo] = useState<string | null>(null);
  const [file, setFile] = useState<File | undefined>();
  const [isRndAccentColor, setIsRndAccentColor] = useState<boolean>(false);
  const [isRndPgColor, setIsRndPgColor] = useState<boolean>(false);
  const [titleEnabled, setTitleEnabled] = useState(true);
  const [subtitleEnabled, setSubtitleEnabled] = useState(false);
  const [websiteEnabled, setWebsiteEnabled] = useState(false);
  const navigate = useNavigate();
  const fileInput = useRef<HTMLInputElement>(null);
  const pageColorRef = useRef<HTMLInputElement>(null);
  const accentColorRef = useRef<HTMLInputElement>(null);

  const handleLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setFile(file);
    if (file) {
      const logoUrl = URL.createObjectURL(file);
      setLogo(logoUrl);
    }
  };

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setLogo(e.target?.result as string);
      setFile(file);
    };
    reader.readAsDataURL(file);
  };

  const handleSelectPageColor = () => {
    if (pageColorRef.current) {
      pageColorRef.current.click();
    }
  };

  const handleSelectAccentColor = () => {
    if (accentColorRef.current) {
      accentColorRef.current.click();
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    maxSize: 1048576, // 1 MB
  });
  const PAGE_BG = [
    {
      color: '#FFFFFF',
      style: 'bg-[#FFFFFF]', /// using tailwind color style
    },
    {
      color: '#666666',
      style: 'bg-[#666666]', /// using tailwind color style
    },
    {
      color: '#000000',
      style: 'bg-[#000000]', /// using tailwind color style
    },
  ];

  const ACCENT_COLOR = [
    {
      color: '#FFFFFF',
      style: 'bg-[#FFFFFF]', /// using tailwind color style
    },
    {
      color: '#000000',
      style: 'bg-[#000000]', /// using tailwind color style
    },
  ];

  const handleSetAccentColor = (color: string) => {
    setAccentColor(color);
  };

  useMemo(() => {
    setPageBgColor(onBoardingAppDetails?.data.page_color || '');
    setAccentColor(onBoardingAppDetails?.data.accent_color || '');
    setTheme(onBoardingAppDetails?.data.theme || THEME.DARK);
    setSubtitle(onBoardingAppDetails?.data.subtitle || '');
    setTitle(onBoardingAppDetails?.data.title || '');
    setLogo(onBoardingAppDetails?.data.logo || '');
    setWebsite(onBoardingAppDetails?.data.website || '');
    setTitleEnabled(Boolean(onBoardingAppDetails?.data.title));
    setSubtitleEnabled(Boolean(onBoardingAppDetails?.data.subtitle));
    setWebsiteEnabled(Boolean(onBoardingAppDetails?.data.website));
  }, [onBoardingAppDetails?.data]);

  useMemo(() => {
    dispatch(
      updateCustomization({
        file,
        title,
        theme,
        subtitle,
        onboardingId: String(onBoardingAppDetails?.data.id),
        website,
        pageColor: String(pageBgColor),
        accentColor,
        name: appName,
      })
    );
    const isExistAccent = ACCENT_COLOR.some(
      (color) => color.color == accentColor
    );
    const isExistPage = PAGE_BG.some((color) => color.color == pageBgColor);
    setIsRndPgColor(isExistPage);
    setIsRndAccentColor(isExistAccent);
  }, [
    pageBgColor,
    website,
    accentColor,
    pageBgColor,
    theme,
    title,
    file,
    appName,
  ]);

  return (
    <div className="flex gap-5 mt-[60px]">
      {/* Left Form Panel */}
      <div className="flex flex-col gap-3 ">
        <div className="flex bg-gray-light-3/30 flex-col h-[159px] w-[517px] border border-gray-light-3 rounded-xl justify-evenly">
          <p className="pl-8 text-[16px] font-semibold text-left text-white">
            Component theme color
          </p>
          <div className="flex justify-center gap-3">
            <button
              onClick={() => setTheme(THEME.DARK)}
              className={`w-[220px] text-[15px] h-[50px]  rounded-[40px] font-semibold flex justify-center items-center gap-4 text-white ${
                theme == 'dark' ? 'bg-primary-default' : 'bg-gray-light-3'
              }`}
            >
              {theme == 'dark' && <ImCheckmark className="text-white" />} Dark
            </button>
            <button
              onClick={() => setTheme(THEME.LIGHT)}
              className={`w-[220px] h-[50px] rounded-[40px] font-semibold flex text-[15px] justify-center text-white gap-4 items-center  ${
                theme == 'light' ? 'bg-primary-default' : 'bg-gray-light-3'
              }`}
            >
              {theme == 'light' && (
                <ImCheckmark className="w-4 h-4 text-white" />
              )}{' '}
              Light
            </button>
          </div>
        </div>

        <div className="flex gap-4">
          {/* Background and Accent Color Pickers */}
          <div className="w-[250px] h-[133px] bg-gray-light-3/30 border border-gray-light-3 rounded-xl pl-10 flex flex-col justify-evenly">
            <label className="text-base font-semibold text-white">
              Page Background
            </label>
            <div className="flex items-center gap-2">
              {PAGE_BG.map((pageBg) => (
                <button
                  onClick={() => setPageBgColor(pageBg.color)}
                  className={`${pageBg.style} rounded-full  w-[24px] h-[24px] ${
                    pageBg.color == pageBgColor && 'border-2'
                  } ${
                    pageBg.color == '#FFFFFF'
                      ? 'border-[#CCCCCC]'
                      : 'border-[#FFFFFF]'
                  }`}
                ></button>
              ))}
              <input
                ref={pageColorRef}
                className="w-[24px] h-[24px] rounded-full hidden"
                type="color"
                value={pageBgColor}
                onChange={(e) => setPageBgColor(e.target.value)}
              />
              <img
                onClick={handleSelectPageColor}
                src="/assets/color-scheme.svg"
                className={`w-[24px] h-[24px] border-white ${
                  !isRndPgColor && 'border-2'
                } rounded-full`}
              />
            </div>
          </div>

          <div className="w-[250px] h-[133px] bg-gray-light-3/30 pl-10 border border-gray-light-3 rounded-xl flex flex-col justify-evenly">
            <label className="text-base font-semibold text-white">
              Accent Color
            </label>
            <div className="flex items-center gap-1">
              {ACCENT_COLOR.map((accent_color) => (
                <button
                  onClick={() => {
                    handleSetAccentColor(accent_color.color);
                  }}
                  className={`${
                    accent_color.style
                  } rounded-full  w-[24px] h-[24px] ${
                    accent_color.color == accentColor && 'border-2'
                  } ${
                    accent_color.color == '#FFFFFF'
                      ? 'border-[#CCCCCC]'
                      : 'border-[#FFFFFF]'
                  }`}
                ></button>
              ))}
              <input
                ref={accentColorRef}
                className="h-[24px] w-[24px] rounded-full hidden"
                type="color"
                value={accentColor}
                onChange={(e) => setAccentColor(e.target.value)}
              />
              <img
                onClick={handleSelectAccentColor}
                src="/assets/color-scheme.svg"
                className={`w-[24px] h-[24px] border-white ${
                  !isRndAccentColor && 'border-2'
                } rounded-full`}
              />
            </div>
          </div>
        </div>

        {/* Logo Upload */}
        <div
          {...getRootProps()}
          className="flex flex-col justify-evenly h-[178px] border-gray-light-3 border rounded-xl px-7 bg-gray-light-3/30"
        >
          <label className="text-base font-semibold text-white">Logo</label>
          <div
            style={{
              backgroundImage: 'url(/assets/upload.svg)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
            className="flex cursor-pointer flex-col items-center h-[60px] w-[453px] justify-center py-2 bg-cover bg-no-repeat"
          >
            <input
              ref={fileInput}
              type="file"
              accept="image/*"
              onChange={handleLogoUpload}
              className="hidden"
            />
            {logo ? (
              <div className="flex items-center justify-between w-full px-5">
                <div className="flex items-center justify-center gap-2">
                  <img
                    src={logo}
                    className="object-contain w-[160px] h-[40px] "
                  />
                  <p className="text-[15px] text-[#CCC]">{file?.name}</p>
                </div>
                <button
                  onClick={() => {
                    setLogo('');
                    setFile(undefined);
                  }}
                  className="outline-none text-[#FF4471] text-[14px] font-semibold"
                >
                  Remove
                </button>
              </div>
            ) : (
              <div
                onClick={() => fileInput.current && fileInput.current.click()}
                className="flex flex-col items-center justify-center"
              >
                <div className="flex gap-2 text-white text-[14px]">
                  <img src={UploadIcon} alt="Upload Icon" /> Upload or drop
                  image here
                </div>
                <p className="text-[14px] text-[#CCC]">
                  Supported formats: png, jpg Max size: 1 mb
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Toggleable Title */}
        <div className="flex flex-col justify-evenly pl-10 h-[375px] border-gray-light-3 border bg-gray-light-3/30 rounded-xl">
          <p className="text-base font-semibold text-white"> Text</p>

          <div className="flex flex-row items-center gap-2">
            <div>
              <Switch
                checked={titleEnabled}
                onChange={setTitleEnabled}
                className="group relative flex h-[24px] mt-7 w-[44px] cursor-pointer items-center rounded-full bg-white/10 transition-colors duration-200 ease-in-out focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-primary-default"
              >
                <span
                  aria-hidden="true"
                  className="pointer-events-none inline-block size-3 translate-x-1 rounded-full bg-white ring-0 shadow-lg transition duration-200 ease-in-out group-data-[checked]:translate-x-6 group-data-[checked]:bg-white"
                />
              </Switch>
            </div>
            <div className="flex flex-col w-full gap-2">
              <label className="text-base font-normal text-white">Title</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter title"
                disabled={!titleEnabled}
                className="rounded-[40px] px-[16px] py-[12px] text-white outline-none placeholder:text-gray-light-3 h-[46px] w-[393px] bg-gray-dark-3 border border-gray-light-3 "
              />
            </div>
          </div>

          {/* Toggleable Subtitle */}

          <div className="flex flex-row items-center gap-2">
            <div>
              <Switch
                checked={subtitleEnabled}
                onChange={() => setSubtitleEnabled(!subtitleEnabled)}
                className="group relative flex h-[24px] mt-7 w-[44px] cursor-pointer items-center rounded-full bg-white/10 transition-colors duration-200 ease-in-out focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-primary-default"
              >
                <span
                  aria-hidden="true"
                  className="pointer-events-none inline-block size-3 translate-x-1 rounded-full bg-white ring-0 shadow-lg transition duration-200 ease-in-out group-data-[checked]:translate-x-6 group-data-[checked]:bg-white"
                />
              </Switch>
            </div>
            <div className="flex flex-col w-full gap-2">
              <label className="text-base font-normal text-white">
                Subtitle
              </label>
              <input
                type="text"
                value={subtitle}
                onChange={(e) => setSubtitle(e.target.value)}
                placeholder="Enter Subtitle"
                disabled={!subtitleEnabled}
                className="rounded-[40px] text-white outline-none h-[46px] placeholder:text-gray-light-3 w-[393px] bg-gray-dark-3 border border-gray-light-3 px-[16px] py-[12px]"
              />
            </div>
          </div>

          {/* Toggleable Website */}

          <div className="flex flex-row items-center gap-2">
            <div>
              <Switch
                checked={websiteEnabled}
                onChange={() => setWebsiteEnabled(!websiteEnabled)}
                className="group relative flex h-[24px] mt-7 w-[44px] cursor-pointer items-center rounded-full bg-white/10 transition-colors duration-200 ease-in-out focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-primary-default"
              >
                <span
                  aria-hidden="true"
                  className="pointer-events-none inline-block size-3 translate-x-1 rounded-full bg-white ring-0 shadow-lg transition duration-200 ease-in-out group-data-[checked]:translate-x-6 group-data-[checked]:bg-white"
                />
              </Switch>
            </div>
            <div className="flex flex-col w-full gap-2">
              <label className="text-base font-normal text-white">
                Website
              </label>
              <input
                type="text"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                placeholder="Enter platform website"
                disabled={!websiteEnabled}
                className="rounded-[40px] outline-none placeholder:text-gray-light-3 text-white h-[46px] w-[393px] bg-gray-dark-3 border border-gray-light-3 px-[16px] py-[12px]"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Preview Panel */}
      <div className="">
        {/* Preview Panel */}
        <PreviewComponent
          pageBgColor={pageBgColor || ''}
          accentColor={accentColor}
          logo={logo || ''}
          title={titleEnabled ? title : ''}
          subtitle={subtitleEnabled ? subtitle : ''}
          website={websiteEnabled ? website : ''}
          theme={theme}
          btnTextColor={btnTextColor}
        />
      </div>
    </div>
  );
};

export default CustomizedEdit;
