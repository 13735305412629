import axios from "axios";
import { BACKEND_URL } from "../env";
import {
  ApiResponse,
  OnboardingApp,
  Protocol,
  ProtocolActionByProtocolIdResponse,
} from "../types";

export const getAllProtocol = async (): Promise<Protocol[]> => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.get<ApiResponse<Protocol[]>>(
      `${BACKEND_URL}api/projects`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.data.statusCode === 200 && res.data.data) {
      return res.data.data;
    } else return [];
  } catch (error) {
    return [];
  }
};

export const getProtocolActionsByProtocolId = async (
  id: string
): Promise<ProtocolActionByProtocolIdResponse | null> => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.get(
      `${BACKEND_URL}api/projects/project-action/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    return null;
  }
};

export const getOnboardingAppsForProject = async (project_id: string) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.get<ApiResponse<OnboardingApp[]>>(
      `${BACKEND_URL}api/onboardings?project_id=${project_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.data.statusCode === 200 && res.data.data) {
      return res.data.data;
    } else return [];
  } catch (error) {
    return [];
  }
};
