import { useQuery } from "@tanstack/react-query";
import { getAllProjects, getUserDetails } from "../api/users/routes";
import {
  fetchOnboardingAppDetails,
  fetchOnboardingAppFromProjectId,
  fetchSingleOnboardingApp,
  generateOnboardingLink,
  getAllChains,
  getAllMembersList,
  getAssets,
  getProjects,
} from "../api/project/route";
import {
  getAllProtocol,
  getOnboardingAppsForProject,
  getProtocolActionsByProtocolId,
} from "../api/protocols/route";
import { getSession } from "../utils/helper";

export const useGetAllChains = () => {
  const getAllChain = async () => {
    return await getAllChains();
  };

  return useQuery({
    queryKey: ["getAllChains"],
    queryFn: () => getAllChain(),
    staleTime: 3600000,
  });
};
export const useFetchAssets = ({
  protocolId,
  onboardingType,
}: {
  protocolId: number;
  onboardingType: string;
}) => {
  const fetchAssets = async () => {
    return await getAssets(protocolId, onboardingType);
  };

  return useQuery({
    queryKey: ["getAssets", protocolId, onboardingType],
    queryFn: () => fetchAssets(),
  });
};

export const useGetOnBoardingLink = (onBoardingId?: string) => {
  const getOnBoardingLink = async () => {
    if (!onBoardingId) {
      return [];
    }
    return await generateOnboardingLink(onBoardingId);
  };

  return useQuery({
    queryKey: ["getOnBoardingLink", onBoardingId],
    queryFn: () => getOnBoardingLink(),
  });
};

export const useFetchAllProject = () => {
  const getAllProject = async () => {
    const response = await getProjects();
    return response?.data;
  };

  return useQuery({
    queryKey: ["getAllProject"],
    queryFn: () => getAllProject(),
  });
};

export const useFetchAllOnboardingAppByProtocolId = (projectId: number) => {
  const getAllOnboardingFromProjectId = async () => {
    if (!projectId) {
      return [];
    }
    const response = await fetchOnboardingAppFromProjectId(projectId);
    return response?.data;
  };

  return useQuery({
    queryKey: ["getAllOnboardingFromProjectId", projectId],
    queryFn: () => getAllOnboardingFromProjectId(),
  });
};

export const useFetchSingleOnboardingApp = (onboardingId?: string) => {
  const getSingleOnboardingApp = async () => {
    if (!onboardingId) {
      return;
    }
    const response = await fetchSingleOnboardingApp(onboardingId);
    return response?.data;
  };

  return useQuery({
    queryKey: ["fetchSingleOnboardingApp", onboardingId],
    queryFn: () => getSingleOnboardingApp(),
  });
};

export const useGetAllProtocols = () => {
  const getProtocols = async () => {
    const response = await getAllProtocol();
    return response;
  };

  return useQuery({
    queryKey: ["getAllProtocols"],
    queryFn: () => getProtocols(),
  });
};

export const useGetAllOnboardingApps = (projectId: string) => {
  const getOnboardingApps = async () => {
    const response = await getOnboardingAppsForProject(projectId);
    return response;
  };
  return useQuery({
    queryKey: ["getOnboardingApps", projectId],
    queryFn: () => getOnboardingApps(),
  });
};

export const useGetAllMembersList = ({
  isSuperAdmin,
  projectId,
  userAddress,
}: {
  isSuperAdmin: boolean;
  projectId: number;
  userAddress?: string;
}) => {
  const { protocolId } = getSession();
  const getAllMembers = async () => {
    const response = await getAllMembersList(isSuperAdmin, protocolId);
    return response;
  };
  return useQuery({
    queryKey: ["getAllMembersList", protocolId, userAddress],
    queryFn: () => getAllMembers(),
    staleTime: 100,
  });
};

export const useFetchUserDetails = ({
  userAddress,
}: {
  userAddress?: string;
}) => {
  const fetchUserDetails = async () => {
    if (!userAddress) {
      return;
    }
    const response = await getUserDetails();
    return response?.data;
  };
  return useQuery({
    queryKey: ["fetchUserDetails", userAddress],
    queryFn: () => fetchUserDetails(),
  });
};

export const useFetchOnboardingAppInfo = ({
  onboardingId,
}: {
  onboardingId: number;
}) => {
  const getOnboardingAppdetail = async () => {
    const response = await fetchOnboardingAppDetails(onboardingId);
    return response?.data;
  };

  return useQuery({
    queryKey: ["getOnboardingAppdetails", onboardingId],
    queryFn: () => getOnboardingAppdetail(),
  });
};

export const useFetchProtocolActionsById = ({
  protocolId,
}: {
  protocolId: string;
}) => {
  const getProtocolActions = async () => {
    const response = await getProtocolActionsByProtocolId(protocolId);
    return response?.data;
  };

  return useQuery({
    queryKey: ["protocolActionsById", protocolId],
    queryFn: () => getProtocolActions(),
  });
};
