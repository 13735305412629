import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface dialogState {
  loginOrSignupDialog: boolean;
  onBoardingActionDialog:boolean
  selectProtocolDialog:boolean
}

const initialState: dialogState = {
  loginOrSignupDialog: false,
  onBoardingActionDialog: false,
  selectProtocolDialog:false
};

export const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    toggleLoginOrSignupDialog: (state, action: PayloadAction<boolean>) => {
      state.loginOrSignupDialog = action.payload;
    },
    toggleOnBoardingAction: (state, action: PayloadAction<boolean>) => {
      state.onBoardingActionDialog = action.payload;
    },
     toggleSelectProtocolDialog: (state, action: PayloadAction<boolean>) => {
      state.selectProtocolDialog = action.payload;
    },
  },
});

export const { toggleLoginOrSignupDialog, toggleOnBoardingAction, toggleSelectProtocolDialog } = dialogSlice.actions;
export default dialogSlice.reducer;
