import { walletType } from "../api/types";
import { Roles } from "../api/types";
export enum OnBoardingType {
    STAKE = "staking",
    SWAP = "swapping",
    BRIDGE = "bridging",
    LEND = "lending"
}

export enum onboardingAction {
    STAKE = "Stake",
    SWAP = "Swap",
    BRIDGE = "Bridge",
    LEND = "Lend",
    UNSTAKE = "Unstake"
}


export enum THEME  {
    DARK = "dark",
    LIGHT = "light"
}

export interface ModularFeatureItem {
    id: number;
    project_id: number;
    category: string;
    chain: string;
    from_asset: string;
    from_asset_address: string;
    from_asset_logo: string;
    from_network: string;
    to_asset: string;
    to_asset_address: string;
    to_asset_logo: string;
    to_network: string;
    contract: string;
    status: boolean;
}

export type UserDetails = {
    id: string;
    company_id: string;
    email: null;
    auth_provider: walletType;
    provider_id: string;
    created_at: string;
    updated_at: string;
    __company__: {
      id: string;
      title: null;
      admin_id: string;
      created_at: string;
      updated_at: string;
      __projects__: {
        id: number;
        title: string;
        is_claimed: string;
        description: string;
        logo: "";
        created_at: string;
        updated_at: string;
      }[];
    };
    __roles__: Roles[]
  };